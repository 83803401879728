@use '@thrivetrm/ui/stylesheets/variables';

// Dashboard sections
.search-sections {
  .panel-collapsable {
    background-color: transparent;
    box-shadow: none;

    > .panel-heading {
      background-color: variables.$brand-dark-blue;
      border: 1px solid variables.$color-border;
      border-radius: variables.$border-radius-base;
      color: variables.$white;
      padding: variables.$space-12 variables.$space-16;

      .panel-title {
        color: inherit;
        font-size: variables.$font-size-small;
        font-weight: variables.$font-weight-normal;
        line-height: 1.4em;
        margin: 0;
      }

      a {
        color: inherit;
        display: block;
        position: relative;
        .fa {
          font-size: 1.2em;
          position: absolute;
          right: 0;
          top: 0.1em;
        }
      }

      &.collapsed {
        background-color: variables.$white;
        color: variables.$brand-primary;

        .fa:before {
          content: 'm';
        }
      }
    }

    > .panel-collapse > .panel-body {
      padding: 0;
    }
  }
}

// Dashboard items
.panel-dashboard-search {
  border: 1px solid variables.$color-border;
  margin: variables.$space-24 0 0 0;

  .panel-heading {
    align-items: center;
    border-bottom: 1px solid variables.$gray-20;
    display: flex;
  }

  .resource-name {
    flex-grow: 2;
    font-size: 0.95em;
  }

  .avatar img {
    max-width: 100px;
  }

  .resource-meta {
    line-height: 1.5em;
    margin: 0;
  }

  .panel-body {
    align-items: stretch;
    display: flex;
    padding: 0;

    > * {
      padding: variables.$space-12;
    }
  }

  .recent-activity {
    flex-grow: 2;

    h3 {
      margin-top: 0;
    }
    li {
      font-size: 0.8em;
      word-break: break-word;
    }
  }

  .search-details {
    border-bottom: none;
    margin-bottom: 0;
    min-width: 174px;
  }

  .activity-owner {
    color: variables.$gray-50;
    font-size: 0.8em;
  }
}

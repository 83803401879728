@use '@thrivetrm/ui/stylesheets/variables';

.StatusAndAllocation {
  &__Container {
    max-width: 700px;
  }

  &__AutocompleteField {
    margin-bottom: variables.$space-24;

    .Autocomplete {
      min-width: 100%;
    }
  }

  &__Field {
    font-weight: variables.$font-weight-bold;
    height: 60px;
    margin-bottom: variables.$space-24;
  }

  &__Footer {
    align-items: flex-end;
    display: flex;
    flex-grow: 1;
  }

  &__SubmitButtons {
    margin-left: auto;
  }

  &__TextArea {
    .TextArea__input {
      max-width: 100%;
    }
  }
}

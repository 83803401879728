@use '@thrivetrm/ui/stylesheets/variables';

.EducationView {
  .EducationView__degree-years {
    color: variables.$gray-50;
    font-style: italic;
  }

  .EducationView__school {
    font-weight: variables.$font-weight-bold;
  }
}

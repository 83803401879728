@use '@thrivetrm/ui/stylesheets/variables';

.CommaSeparatedLinkList {
  display: inline-block;

  &__additionalText {
    color: variables.$link-color;
    cursor: pointer;
  }
}

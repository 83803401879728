@use '@thrivetrm/ui/stylesheets/variables';

.QuickViewNavItem {
  background-color: transparent;
  border-radius: 0px;
  width: 100%;

  &:active {
    box-shadow: none;
  }

  &__label {
    font-size: 20px;
  }
  &__count {
    border-radius: variables.$space-12;
    color: variables.$brand-dark-purple;
    padding: variables.$space-2 variables.$space-8;
  }
}

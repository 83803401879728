.contact-select {
  &.is-creatable {
    display: flex;
    max-width: 100%;

    .Select {
      flex: 1;
      min-width: 0;
    }

    .contact-select-create .btn {
      border-radius: 5px;
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
      font-size: 12.5px;
      margin-left: -1px;
    }
  }
}

@use '@thrivetrm/ui/stylesheets/variables';

.search-detail-header {
  .btn {
    width: 100%;
  }
  .btn + .btn {
    margin: 12px 0 0 0;
  }

  .job-title {
    font-size: em(15px);
    font-weight: variables.$font-weight-bold;
  }

  .location {
    color: variables.$gray-40;
    font-size: em(13px);
  }

  .opened {
    color: variables.$gray-50;
    font-size: em(11px);
    font-weight: variables.$font-weight-bold;
    margin-top: 10px;

    .ago {
      color: variables.$gray-40;
      font-weight: variables.$font-weight-normal;
    }
  }
}

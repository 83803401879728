.TagTableRow > td {
  overflow: hidden;
  padding-bottom: 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.TagTableRow__TagColorSquare {
  border: 1px solid #aaaaaa;
  margin: 0.25em 1em 0.25em 0.25em;
  vertical-align: middle;
}

.TagTableRow__checkboxColumn > input {
  margin-left: 0.2em;
}

.TagTableRow__colorColumn {
  padding-right: 1em;
  width: 3em;
}

.TagTableRow__labelColumn,
.TagTableRow__creatorColumn {
  max-width: 3em;
  padding-right: 1em;
}

.TagTableRow__labelColumn {
  // Split the space between the labelColumn and creatorColumn
  width: 65%;
}

.TagTableRow__creatorColumn {
  // Split the space between the labelColumn and creatorColumn
  width: 35%;
}

.TagTableRow__actionsColumn {
  text-align: right;
  white-space: nowrap;
  width: 2em;
}

.TagTableRow__CancelButton {
  margin-bottom: 0;
  margin-top: 0;
}

.outreaches--outreach-title .HubspotIndicator {
  float: right;
}

.outreaches--outreach-title .outreaches--outreach-date {
  time {
    font-size: 10px;
    font-style: normal;
  }
}

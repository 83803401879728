$env: production;
@use '@thrivetrm/ui/stylesheets/variables';

/**
 * Whacky font shit goes here
 */
.CompanyPage .ProfilePage__header,
.CompanyPage .ProfilePageSection {
  font-size: 0.9em;
}

.CompanyPage .ProfilePage__header {
  align-items: center !important;
}

.CompanyPage__sidebar {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  // Allows the comment section to stretch to fill the sidebar.
  height: 100%;
}

.CompanyPage__comments {
  flex: 1;
}

.CompanyPage__overview {
  align-items: center;
  background-color: variables.$white;
  display: flex;
  margin-bottom: 1em;
  margin-top: 0.5em;
  padding-bottom: 0.5em;
  padding-right: 0.5em;

  & > .CompanyLogoEditButton {
    margin-left: 0.5em;
    // Add spacing between this and the .CompanyBasicsEditableSection
    margin-right: 0.5em;

    // Limit the size of the logo displayed.
    .CompanyLogo__image {
      max-width: 100px;
    }
  }

  & > .CompanyBasicsEditableSection {
    flex: 1;
  }

  & > .CompanyBasicsEditableSection > .EditableSection__header {
    // Increase the size of the title of the "basics" section -- this is the company name,
    // and acts more as a page header than a section title.
    font-size: 1.1em;
    margin-bottom: 0.5em; // This is so ridiculous. We need to just style headers correctly.
    margin-top: 1em;

    .ExternalLinkIndicator {
      // When the title is a link (the company has a website defined) we show an external link
      // indicator icon. The edit icon then looks more like part of the title. This adds a little more
      // spacing to distance the two and make the edit icon a little more distinct from the title.
      margin-right: 0.5em;
    }
  }
}

.CompanyPage__introductions {
  .panel-body {
    background-color: variables.$gray-5;
  }

  .IntroductionActivityList__item {
    border: none;
    box-shadow: 1px 1px 3px 1px rgb(211, 211, 216);
    margin-bottom: 1em;
    margin-top: 1em;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .IntroductionActivityList > .list-group {
    margin-bottom: 0;
  }
}

.CompanyPage__addresses {
  th:first-of-type,
  td:first-of-type {
    min-width: unset;
  }

  td {
    max-width: 10em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  i.fa-check {
    color: green;
    margin-left: 1.5em;
  }

  .AddressDeleteButton {
    margin-left: 0.7em;
  }

  td .CompanyAddressModalButton {
    display: inline;
  }

  .DatasetView {
    margin-top: 2.5em;
  }
}

.company-activity-body {
  .notification-list-item {
    border: none;
    box-shadow: 1px 1px 3px 1px rgb(211, 211, 216);
    margin-bottom: 1em;

    &:first-child {
      border-top: none;
    }

    &:last-child {
      border-bottom: none;
    }
  }
}

.CompanyPage__searches,
.CompanyPage__funding-rounds,
.CompanyPage__recruitment-activity {
  th:first-of-type,
  td:first-of-type {
    min-width: unset;
  }
}

@use '@thrivetrm/ui/stylesheets/variables';

#contact-form-wrapper {
  border: 2px solid variables.$gray-5;
  display: none;
  margin-bottom: 10px;
  padding: 15px;

  #pdf-upload-wrapper {
    margin-top: -10px;
  }
}

.added-by {
  @include small-text;
  margin: 10px 0px;
}

.candidacies-edit #contact-groups-wrapper {
  margin-top: 10px;
}

.reference_description textarea {
  height: 200px;
}

#candidate-search-wrapper {
  border-bottom: 1px solid variables.$gray-20;
  height: 75px;
}

#candidate_search {
  input[type='text'] {
    margin-top: 20px;
    size: 0.9em;
  }

  .actions input {
    float: right;
    margin: 4px;
    margin-bottom: 20px;
    margin-left: 4px;
  }
}

.assessment .fa-info-circle {
  color: variables.$brand-secondary;
  padding-left: 5px;
  &:hover {
    color: variables.$brand-secondary-light;
  }
}

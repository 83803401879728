.toolbar {
  margin: 0 0 1em;
  padding: 1em 0;
  position: relative;

  .form-search {
    width: 300px;
  }
}

.toolbar-right {
  position: absolute;
  right: 0;

  &.checkbox {
    top: 20px; // Vertical centering
  }
}

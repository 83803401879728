@use '@thrivetrm/ui/stylesheets/variables';

.ConnectionsCard {
  &__headerText {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    min-width: 0;
    padding-left: variables.$space-2;
    padding-right: variables.$space-2;
  }
  &__headerTag {
    align-self: flex-start;
  }
  &__strengthIndicatorTooltip {
    align-self: flex-start;
  }
}

@use '@thrivetrm/ui/stylesheets/variables';

.CandidacyCreateGuestAssessmentInviteButton {
  &__note {
    color: variables.$gray-60;
    font-size: variables.$space-16;
    line-height: variables.$space-24;
  }
  &__textInput input {
    // used important to override opacity when input is disabled
    opacity: 1 !important;
  }
}

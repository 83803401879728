@use '@thrivetrm/ui/stylesheets/variables';

.login-page-header {
  margin-bottom: 20px;
  padding: 20px;

  img {
    margin: 0 auto;
  }
}

.login-terms-and-conditions {
  font-size: 80%;
  margin: 1.5em auto;
  width: 60%;

  a {
    color: variables.$gray-40;
    display: inline-block;
    text-align: center;
    width: 50%;

    & + a {
      border-left: 1px solid variables.$gray-20;
    }
  }
}

#login {
  border: 0;
  box-shadow: none;

  #forgot-password {
    color: variables.$gray-50;
    display: block;
    font-size: 0.85em;
    margin-bottom: 15px;
  }

  #password-wrapper {
    margin-bottom: 3px;
  }

  .form-control {
    font-size: 1.2em;
    height: 45px;
  }

  .form-actions {
    display: block;
    margin-top: 2.5em;
  }

  .login-divide-text {
    display: inline-block;
    text-align: center;
    width: 6%;
  }

  .login-divide-line {
    background-color: variables.$gray-30;
    display: inline-block;
    height: 1px;
    margin-bottom: 4px;
    width: 47%;
  }
}

#screenshot {
  height: auto;
  width: 100%;
}

.large {
  font-size: 2em;
  line-height: 1.25em;
}

.small {
  font-size: 0.85em;
}

.conjunction {
  margin-top: 4px;
}

#toc-error {
  display: none;
}

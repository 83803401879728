@use '@thrivetrm/ui/stylesheets/variables';

.contact-title {
  h3,
  h4,
  h5 {
    line-height: 1.45;
    margin-bottom: 0.15em;
    margin-top: 0;
  }

  h3 {
    font-size: 1.1em;
  }

  h3,
  h4 {
    font-weight: variables.$font-weight-bold;
  }

  h4,
  h5 {
    color: variables.$gray-50;
    font-size: 1em;
  }

  h5 {
    font-weight: variables.$font-weight-normal;
  }
}

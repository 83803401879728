@use '@thrivetrm/ui/stylesheets/variables';

.ContactSelect--is-creatable {
  display: flex;
  max-width: 100%;

  .Select {
    flex: 1;
    min-width: 0;
  }
  .Select-control {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }
  .ContactCreateModalButton {
    border-radius: 5px;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    font-size: 12.5px;
    margin-left: -1px;
    padding: 6px variables.$space-12;
  }
}

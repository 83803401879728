@use '@thrivetrm/ui/stylesheets/variables';

.SelectAllCheckbox {
  display: inline-block;
  padding-left: 0.5em;

  > label {
    font-size: variables.$font-size-base;
    padding-left: 0.5em;
  }
}

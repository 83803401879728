@use '@thrivetrm/ui/stylesheets/variables';

.OutreachEmailFormOverlay {
  position: relative;
}

.OutreachEmailFormOverlay .OutreachEmailFormOverlay__overlay {
  align-items: center;
  background-color: rgba(variables.$gray-30, 0.75);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  // Show the loading indicator overtop of the form itself.
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}

.OutreachEmailFormOverlay__overlay .OutreachEmailFormOverlay__message {
  background-color: variables.$white;
  box-shadow: 1px 1px 3px 1px rgb(211, 211, 216);
  margin: 1em;
  padding: 1em;
  text-align: center;
}

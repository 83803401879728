@use '@thrivetrm/ui/stylesheets/variables';

.StrengthIndicator {
  align-items: flex-end;
  display: inline-flex;
  height: variables.$space-24;
  justify-content: space-between;
  width: variables.$space-24;

  .StrengthIndicator__bar {
    background-color: variables.$gray-20;
    border-radius: variables.$space-2;
    width: 6px;

    &:nth-child(1) {
      height: variables.$space-12;
    }
    &:nth-child(2) {
      height: variables.$space-16;
    }
    &:nth-child(3) {
      height: variables.$space-24;
    }
  }

  &--low .StrengthIndicator__bar:nth-child(1) {
    background-color: variables.$brand-green;
  }

  &--medium {
    .StrengthIndicator__bar:nth-child(1),
    .StrengthIndicator__bar:nth-child(2) {
      background-color: variables.$brand-green;
    }
  }

  &--high .StrengthIndicator__bar {
    background-color: variables.$brand-green;
  }
}

@use '@thrivetrm/ui/stylesheets/variables';

.GuestAssessmentLinkExpired {
  &__primary {
    height: 200px;
  }
  &__cardView {
    position: absolute;
  }
  &__tag {
    background-color: variables.$brand-red;
    border-radius: variables.$space-12;
  }
  &__footer {
    bottom: 0px;
    position: fixed;
  }
  &__tenantImage {
    height: variables.$space-32;
  }
  &__poweredByThriveImage {
    height: variables.$space-32;
  }
}

@use '@thrivetrm/ui/stylesheets/variables';

.btn-add,
.btn-remove {
  line-height: 1.1;
  padding: 0.7em 0.8em;
}

.btn-add {
  background-color: variables.$white;
  border: 1px solid variables.$brand-secondary;
  color: variables.$brand-secondary;
  // Overrides btn + btn margin
  // Both add and remove buttons are in the dom and
  // display:none is toggled to show/hide. This results
  // in the add button always recieving a left margin
  margin-left: 0 !important;

  &:hover {
    background-color: variables.$white;
    color: variables.$link-hover-color;
  }
}

.btn-remove {
  background-color: variables.$brand-secondary;
  border: 1px solid variables.$brand-secondary;
  color: variables.$white;
  &:hover {
    background-color: variables.$link-hover-color;
    border: 1px solid variables.$link-hover-color;
    color: variables.$white;
  }
}

.CandidacySearchTagModal {
  &__tableScrollContainer {
    max-height: 45vh;
    min-height: 200px;
  }

  &__tableHead {
    position: sticky;
    top: 0px;
    z-index: 2;
  }
}

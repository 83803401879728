@use '@thrivetrm/ui/stylesheets/variables';

.ReorderableList {
  // This height provides a good balance of information density, clickable
  // hot spot, and allows enough space for the item text to wrap within bounds.
  $item-height: 35px;

  list-style: none;
  margin: 0;
  padding: 0;

  .ReorderableList__item {
    align-items: center;
    background-color: variables.$gray-5;
    background-image: url('~assets/images/grip.svg');
    background-position: variables.$space-12 center;
    background-repeat: no-repeat;
    border: 1px solid variables.$gray-20;
    border-radius: variables.$border-radius-base;
    cursor: grab;
    display: flex;
    height: $item-height;
    justify-content: space-between;
    line-height: 1;
    margin: 0 0 variables.$space-12 0;
    padding-left: variables.$space-24;
    user-select: none;
  }

  .ReorderableList__item--isPinned {
    background-image: none;
    cursor: default;
  }

  .ReorderableList__label {
    margin-left: variables.$space-12;
  }

  .ReorderableList__remove {
    background-color: transparent;
    border: none;
    color: variables.$gray-50;
    cursor: pointer;
    flex-shrink: 0;
    font-size: variables.$font-size-large;
    height: 100%;
    opacity: 0.5;
    text-align: center;
    transition: color 200ms ease-out;
    width: $item-height;

    &:hover {
      background-color: variables.$gray-20;
      color: variables.$gray-60;
    }
  }
}

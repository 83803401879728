@use '@thrivetrm/ui/stylesheets/variables';

.c3 svg {
  -webkit-tap-highlight-color: transparent;
  width: 100%;
}

.c3 path,
.c3 line {
  fill: none;
}

.c3 text {
  fill: variables.$gray-40;
  font-size: 0.9em;
  font-weight: variables.$font-weight-bold;
  user-select: none;
}

.c3-legend-item-tile,
.c3-xgrid-focus,
.c3-ygrid,
.c3-event-rect,
.c3-bars path {
  shape-rendering: crispEdges;
}

.c3-chart-arc path {
  stroke: transparent;
}

.c3-chart-arc text {
  fill: variables.$white;
  font-size: 13px;
}

// Axis
.tick line {
  stroke: variables.$gray-20;
}

// Grid
.c3-grid line {
  stroke: variables.$gray-20;
}

// Text on Chart
.c3-text.c3-empty {
  fill: #808080;
  font-size: 2em;
}

// Bar
.c3-bar {
  fill: variables.$brand-secondary !important;
  stroke-width: 0;
}

.c3-bar._expanded_ {
  fill: variables.$brand-primary !important;
}

// Tooltip
.c3 .c3-tooltip-container {
  background: white;
  border-radius: 3px;
  box-shadow: 2px 2px 8px rgba(variables.$black-shadow, 0.2);
  color: variables.$gray-60;
  padding: 10px;
  transform: translate(-20%, 0);
  transition: all 0.1s ease;
  white-space: nowrap;
  z-index: $z-index-c3-tooltip-container;
}

.c3.commissions-bar .c3-tooltip-container {
  min-width: 14em;
}

.TagCheckboxList {
  display: flex;
  flex-direction: column;
}

.TagCheckboxList__list {
  list-style-type: none;
  max-height: 30vh;
  overflow-y: auto;
  padding: 0;
}

.TagCheckboxList__placeholder {
  padding-left: 20px;
}

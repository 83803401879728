.CompanyField .LoadingIndicator {
  position: absolute;
  right: 5px;
  top: -5px;

  img {
    height: 24px;
    width: 24px;
  }
}

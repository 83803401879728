@use '@thrivetrm/ui/stylesheets/variables';

.AttachmentsField {
  padding: 0.5em;
}

.AttachmentsField__attachments-button-section {
  text-align: left; // This is set explicitly because it may be changed.
}

/**
* Active styles for the dropzone
*/
.AttachmentsField--attachments-dropzone-active {
  background: variables.$light-blue;
  cursor: grabbing;
}

.AttachmentDeleteToggle {
  background-color: variables.$white;
  border-radius: 50%;
  box-shadow: 1px 1px 2px 1px variables.$gray-20;
  display: inline-block;
  height: 1.2em;
  left: 13.6em;
  padding: 0;
  position: relative;
  top: -2.3em;
  width: 1.2em;
}

.OutreachForm .form-actions {
  margin-top: 0;
}

.OutreachEmailForm .form-actions {
  margin-top: 0;
}

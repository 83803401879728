$env: production;
.AliasInput--deleted {
  opacity: 0.5;
}

.AliasInput__actionButton {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.AliasInput--deleted .form-control {
  text-decoration: line-through;
}

.talent-pools--talent-pools-table-view {
  th {
    cursor: pointer; // Headers are clickable/sortable.
    white-space: nowrap;
  }

  .column-candidacies_count {
    text-align: center;
  }
}

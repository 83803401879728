$env: production;
@use '@thrivetrm/ui/stylesheets/variables';

.directReportsRevealTrigger {
  align-items: center;
  background-color: variables.$gray-20;
  border-radius: 12px;
  color: variables.$gray-60;
  display: flex;
  justify-content: center;
  padding: variables.$space-4 variables.$space-12;
}
.ContactReporting__addConnectionButton {
  color: variables.$brand-dark-purple;
}

@use '@thrivetrm/ui/stylesheets/variables';

$recordIndex-sidebar-width: 334px;
$global-navbar-height: 48px;

.RecordIndexPage__v5Header {
  position: sticky;
  top: $global-navbar-height;
  // To ensure that the sidebar content is not on top of the header
  // the z-index below must be greater than that of RecordIndexPage__sidebar below
  z-index: 3;
}
.RecordIndexPage {
  // below 1200px content stacks in odd ways
  // and the buttons in the v5 header do not have space to render
  min-width: 1200px;
}
.RecordIndexPage__content {
  display: flex;
  padding: variables.$space-24;
}
.RecordIndexPage__sidebar {
  flex-basis: $recordIndex-sidebar-width;
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: variables.$space-24;
  max-width: $recordIndex-sidebar-width;
  padding-top: variables.$space-2;
  // Some items such as date pickers or menu options overflow onto the main content page section.
  // To make the overflowing content visible the z-index here needs to be greater than that found in TableScrollContainer.scss
  z-index: 2;
  &--collapsed {
    flex-basis: initial;
  }
}
.RecordIndexPage__sidebarScrollContent {
  position: relative;
}
.RecordIndexTable {
  flex-grow: 1;
  // This padding and negative margin combo allows the table drop-shadow to
  // display correctly while maintaining proper alignment
  margin: -1 * variables.$space-4;
  overflow: auto;
  padding: variables.$space-4;
}

.CandidacyListItemSectionDetailsPrimary {
  overflow: hidden;

  // Make sure the underlying fields do not wrap and include ellipsis' on overflow
  .ContactPosition,
  .ContactPosition__PositionCompanyName,
  .LocationName {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.CandidacyListItemSmallSectionDetails__primary {
  line-height: 1.2em;
}

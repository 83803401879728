@use '@thrivetrm/ui/stylesheets/variables';

.RatingLegacy {
  align-items: center;
  display: inline-flex;
  flex-wrap: wrap;

  .fa {
    color: variables.$brand-yellow;
    padding: 0.12em;
  }

  .fa:first-of-type {
    padding-left: 0;
  }
  .RatingLegacy__stars {
    font-size: variables.$space-16;
    line-height: variables.$space-16;
    margin-right: variables.$space-8;
    white-space: nowrap;
  }
  .Rating__children {
    font-size: variables.$font-size-small;
    vertical-align: middle;
    white-space: nowrap;
  }
}

@use '@thrivetrm/ui/stylesheets/variables';

.dashboards .page-container {
  margin-top: 1em;
}

.search-container:nth-child(odd) {
  clear: left;
}

.acronym {
  color: variables.$brand-primary;
  font-weight: variables.$font-weight-bold;
}

.prospect-stage {
  color: variables.$gray-50;
  font-weight: variables.$font-weight-bold;
  margin-top: 1em;
}

#dashboard-companies .btn + .btn {
  margin: 0;
}

th.has-icon i {
  color: variables.$brand-primary;
}

.table-dashboard {
  th:not(.job-title),
  td:not(.job-title) {
    text-align: center;
  }
}

#iframe-holder {
  background-image: url('~assets/images/thriveiq_loading_chart.gif');
  background-position: center;
  background-repeat: no-repeat;
}

.thrive-iq-dashboard__chart-select + .select2.select2-container,
.research-library-dashboard__chart-select + .select2.select2-container {
  margin-left: variables.$space-16;
  margin-top: variables.$space-16;
}

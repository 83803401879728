@use '@thrivetrm/ui/stylesheets/variables';

.BulkEditTagModal {
  // Override with custom modal width.
  // The default small modal width is too tight and the large width is too wide.
  // This modal has a variable-length "sentence" for bulk adding / removing tags
  // that we don't want to wrap, or cut off dropdown values.
  // This value is arbitrary, but accommodates the current longest length.
  width: 580px;

  .TagSelect {
    ::placeholder {
      color: variables.$link-color;
    }
  }
}

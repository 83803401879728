@use '@thrivetrm/ui/stylesheets/variables';

.recent-activity h2 small,
.recent-activity h3 small {
  color: variables.$brand-primary;
}

.activities {
  list-style: none;
  padding-bottom: $panel-body-padding;
  padding-left: 0;

  li {
    color: variables.$gray-50;
    font-size: 0.9em;
    padding-left: 1em;
    position: relative;

    &:before {
      color: variables.$gray-30;
      content: '• ';
      font-size: 1.5em;
      left: 0;
      position: absolute;
      top: -4px;
    }

    + li {
      margin-top: 8px;
    }

    &.new:before {
      color: variables.$red;
    }
  }
}

@use '@thrivetrm/ui/stylesheets/variables';

$notification-read-background-color: variables.$gray-5;
$notification-read-opacity: 0.75;
$notification-unread-indicator-color: variables.$red;
$notification-unread-indicator-width: 3px;
$notification-padding-vertical: 10px;
$notification-padding-horizontal: 15px;
$notification-border-width: 1px;
$notification-icon-size: 24px;
$notification-icon-padding-vertical: 14px;
$notification-icon-padding-horizontal: 5px;

$notifications-popover-height: 600px;
$notifications-popover-width: 500px;
$notifications-popover-max-height: 90vh;
$notifications-popover-header-height: 2em;
$notifications-popover-footer-height: 2em;
$notifications-popover-border-width: 1px;
$notifications-popover-border-color: variables.$gray-30;
$notifications-popover-border: $notifications-popover-border-width solid
  $notifications-popover-border-color;
$notifications-popover-arrow-height: 10px;
$notifications-popover-arrow-width: 10px;
$notifications-popover-arrow-offset: 6px;

.nav-tabs.nav-main > li a.indicator.navbar-link > .badge.badge-count {
  background-color: $notification-unread-indicator-color;
}

.notification-item {
  position: relative;

  .notification-title {
    .HubspotIndicator {
      float: right;
      margin-top: -8px;
    }
  }

  .notification-icon {
    color: variables.$gray-40;
    left: $notification-icon-padding-horizontal;
    position: absolute;
    top: $notification-icon-padding-vertical;

    .Icon {
      // Mirror the width of the companion Font Awesome icons. FA does not provide a variable for
      // this width.
      width: calc(18em / 14);
    }
  }

  .notification-read-toggle-button {
    color: variables.$gray-40;
  }

  .notification-toggle-read {
    position: absolute;
    right: $notification-icon-padding-horizontal;
    top: $notification-icon-padding-vertical;
  }

  .NotificationIntegrationStatusIcon i {
    left: 7px;
    position: absolute;
    top: 51px;
  }

  .task-status-button {
    color: variables.$brand-primary;
    left: 7px;
    position: absolute;
    top: 40px;
  }
}

.task-status-button {
  color: variables.$brand-primary;
  font-size: 2em;
  &.hovered {
    .fa {
      opacity: 0.3;
    }
  }
}

.NotificationIntegrationStatusIcon i {
  color: variables.$red;
}

.NotificationIntegrationStatusIcon--connected i {
  color: variables.$green;
}

.notification-icon {
  color: variables.$brand-secondary;

  i,
  .Icon {
    font-size: $notification-icon-size;
  }
}

.notification-actions .btn,
.notification-toggle-read .btn {
  padding: 10px;
}

.notification-heading h5 {
  margin-bottom: 0;
}

.notification-heading h6 {
  color: variables.$text-color;
  margin-bottom: 0;
  margin-top: 0.5em;

  a {
    cursor: pointer;
  }
}

.notification-content {
  /**
   * For some reason this forces the positioning of the notification-icon to
   * match the layout when in flexbox mode (when in a popover, see styles below)
   * Otherwise, the paddings are totally different.
   */
  border: 1px solid transparent;
  // To allow the notification-icon to display left.
  margin-left: ($notification-icon-padding-horizontal * 2) +
    $notification-icon-size + 10px;
  margin-right: ($notification-icon-padding-horizontal * 2) +
    $notification-icon-size + 10px;
}

.notification-actions {
  padding-top: 1em;
}

.notification-timestamp {
  color: variables.$gray-40;
}

.notification-organizer-name {
  font-size: 10px;
}

.list-group-item.notification-list-item {
  border: $notification-border-width solid $default-border;
  border-radius: 0;
  padding-bottom: $notification-padding-vertical;
  padding-left: $notification-padding-horizontal;
  padding-right: $notification-padding-horizontal + 10px;
  padding-top: $notification-padding-vertical;
  transition-duration: 0.2s;
  transition-property: opacity, background, border-color;
  transition-timing-function: ease-in-out;
}

.list-group-item.notification-list-item--read {
  background-color: variables.$gray-5;
  opacity: $notification-read-opacity;
  padding-left: $notification-padding-horizontal +
    $notification-unread-indicator-width - $notification-border-width;
}

.list-group-item.notification-list-item--unread {
  border-left: $notification-unread-indicator-width solid
    $notification-unread-indicator-color;
}

.notifications-empty {
  box-shadow: none;
  padding-bottom: 5em;
  padding-top: 5em;
}

/** Notifications popover */

.notifications-popover:before {
  border-bottom: $notifications-popover-arrow-height solid
    variables.$brand-dark-blue;
  border-left: $notifications-popover-arrow-width solid transparent;
  /**
   * Creates the popover arrow pointing to the notification icon
   */
  border-right: $notifications-popover-arrow-width solid transparent;
  content: ' ';
  position: absolute;
  right: $notifications-popover-arrow-offset;
  top: -$notifications-popover-arrow-height;
}

.notifications-popover {
  background-color: variables.$white;
  border: $notifications-popover-border;
  box-shadow: -4px 4px 6px 0px rgba(variables.$gray-30, 0.75);
  height: $notifications-popover-height;
  max-height: $notifications-popover-max-height;
  position: absolute;
  text-align: left;
  width: $notifications-popover-width;
  z-index: $z-index-notifications-popover;
}

.notifications-popover .notification-list-item {
  // border-left: none;
  border-right: none;
}

.notifications-popover__header,
.notifications-popover .notifications-list,
.notifications-popover-footer {
  left: 0;
  position: absolute;
  right: 0;
}

.notifications-popover__header {
  background-color: variables.$brand-dark-blue;
  color: variables.$white;
  font-size: 1em;
  height: $notifications-popover-header-height;
  margin: 0;
  padding: 0.5em;
  padding-right: 14px; /* Aligns the close icon under the arrow */
  top: 0;
  z-index: $z-index-notifications-popover-header;
}

.notifications-popover__header span,
.notifications-popover__header a {
  color: variables.$white;
  padding-left: 1em;
}

.notifications-popover__header a {
  cursor: pointer;
}

.notifications-popover-footer {
  background-color: variables.$gray-20;
  bottom: 0;
  height: $notifications-popover-footer-height;
  left: 0;
  margin-top: 0.2em;
  position: absolute;
  right: 0;
  z-index: $z-index-notifications-popover-footer;
}

.notifications-popover-footer .btn {
  border-radius: 0;
  height: $notifications-popover-footer-height;
  margin: 0;
  padding: 0;
}

.notifications-popover-footer .ButtonLink {
  border-right: 1px solid $notifications-popover-border-color;
  text-decoration: none;
}

.notifications-popover-footer .notifications-popover-footer .btn.disabled,
.notifications-popover-footer button.ButtonLink--isDisabled {
  color: variables.$gray-50;
}

.notifications-popover .notifications-list {
  bottom: $notifications-popover-footer-height;
  overflow: auto;
  top: $notifications-popover-header-height;
}

.notifications-popover .ErrorAlert {
  left: 1em;
  position: absolute;
  right: 1em;
  top: $notifications-popover-header-height + 1em;
  z-index: $z-index-notifications-popover-error-alert;
}

.notifications-popover .notifications-list .list-group {
  margin-bottom: 0;
}

.notifications-popover .notifications-empty {
  background-color: inherit;
  border: none;
  margin-top: calc($notifications-popover-height / 2) - 200px;
}

.notifications-loader,
.notifications-pager {
  background-color: inherit;
  border: none;
  box-shadow: none;
  margin-bottom: 0;
}

.notifications-loader .label,
.notifications-pager .label {
  display: block;
}

.notifications-updating {
  background-color: variables.$gray-20;
  // margin: 0 auto;
  // width: 100%;
  text-align: center;
}

.notification-related-to-searches {
  line-height: 0.5em;
  margin-bottom: 0.1em;
  margin-top: 0.4em;
  h6 {
    display: inline;
  }
  .notification-search-title {
    display: inline;
    &:after {
      content: ', ';
    }
  }
  .notification-search-title:last-child {
    &:after {
      content: '';
    }
  }
}

@use '@thrivetrm/ui/stylesheets/variables';

.contact-icon-link-list,
.ContactIconLinkList {
  font-size: 1.2em;
  padding-left: 0;

  > li {
    display: inline-block;
    margin-left: 0.2em;
    margin-right: 0.2em;

    a {
      color: variables.$gray-50;
    }
  }
}

@use '@thrivetrm/ui/stylesheets/variables';
@import 'searches/search_form';

#searches {
  td {
    vertical-align: middle;
  }
}

.btn-company-new {
  margin-top: 23px;
  max-width: 100%;
  white-space: initial;
  word-break: break-word;
}

.search-details-form-industry h3 {
  color: variables.$gray-50;
}

.sidebar .nav a {
  &.btn-xs {
    padding: 1px 5px;
  }
}

.no-suggestion-notice {
  &.search {
    width: 100%;
  }
}

#search-status .panel-body {
  border: 1px solid variables.$gray-20;
  margin-top: 2rem;
}

.delete-link {
  color: variables.$gray-40;
}

.column-search-team > .popover.fade.bottom.in {
  z-index: auto;
}

#target-companies .btn + .btn {
  margin: 0;
}

.offer-form .well {
  padding-top: 0;

  h2 {
    margin: 0;
  }

  .candidacy-name {
    padding-top: 10px;

    .avatar {
      display: inline-block;
      height: auto;
      padding: 0 10px 0 0;
      vertical-align: middle;
      width: auto;
      img {
        height: 44px;
        width: 44px;
      }
    }
  }
}

.document {
  margin-bottom: variables.$space-16;
}

.search-code-name {
  margin-top: 12px;
}

.search_display_critical {
  color: #ee4215;
}

.search-popover,
.search-documents-popover {
  cursor: pointer;
}

.column-documents .popover {
  font-size: 12px;
  max-width: 300px;
}

// Align the bullet points in the search index document popover
.document-container__list {
  padding-left: 20px;
}

.invalid-date-error {
  color: #a94442;
}

#submit-note {
  float: right;
  font-weight: variables.$font-weight-bold;
  width: 65px;
}

.note-show {
  font-size: 12px;
  margin-bottom: 1em;
  margin-left: 1em;
  margin-top: 1em;
  .created-by {
    float: left;
    font-size: 14px;
    margin-bottom: 0.5em;
    margin-right: 1em;
  }
  .created-at {
    clear: both;
    float: left;
    font-weight: variables.$font-weight-bold;
  }

  .edit-delete {
    .edit a {
      margin: 0px 8px;
    }

    .delete,
    .edit {
      font-style: normal;
      opacity: 0.2;
      transition: opacity 300ms;
    }

    &:hover .delete,
    &:hover .edit {
      opacity: 0.9;
    }

    @include small-text;
    clear: right;
    margin-bottom: 0.5em;
    padding-top: 5px;
  }
  .content {
    clear: both;
  }
}

.note-edit {
  clear: both;
  display: none;
  padding-top: 1px;
  width: 44em;
  .btn {
    font-size: 12px;
  }
}

#change-search-dates {
  cursor: pointer;
  font-size: 14px;
}

.checkbox-tag-label {
  padding-left: 5px;
}

.remove-invoice,
.remove-commission-detail {
  padding-top: 17px;
}

.cancel-save-buttons {
  padding-bottom: 10px;
}

.search_invoices_document_file,
.document-label {
  margin-top: 23px;
}

.pdf-summary-modal {
  .modal-dialog .modal-content {
    width: 700px;
    .checkbox {
      margin-top: 0px;
    }
  }
  h2,
  h3 {
    display: inline-block;
    margin-top: 10px;
  }
  .candidate-information,
  .stages {
    .help-block {
      bottom: 10px;
      margin-bottom: 0px;
      margin-top: 0px;
      position: relative;
    }
  }
  .report-options {
    h2 {
      display: block;
    }
    h3 {
      display: block;
      padding-top: 3px;
    }
    input {
      margin-right: 5px;
    }
  }
}

.candidate-summary-modal {
  h2 {
    display: inline-block;
  }
}

.check-all-toggle {
  bottom: 2px;
  display: inline-block;
  left: 10px;
  position: relative;
}

.select-user-commissions {
  padding-top: 15px;
}

.search-page-nav {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.search-page-nav-pages {
  margin-bottom: 5px;
  margin-top: 5px;
  padding: 5px;

  .editSearch {
    color: variables.$brand-secondary-light;
    cursor: pointer;
  }
}

.document-container {
  padding-bottom: 10px;
}

.website-url-warning .fa-exclamation-triangle,
.website-url-warning .help-block,
.company-name-warning .fa-exclamation-triangle,
.company-name-warning .help-block {
  color: variables.$gray-60;
  display: inline;
}

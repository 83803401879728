@use '@thrivetrm/ui/stylesheets/variables';

// Alignment
.DuplicateMergeAttributeRow__radio {
  background-color: variables.$gray-10;
  border-radius: variables.$border-radius-base;
  position: absolute;
  right: -32px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}
.DuplicateMergeAttributeRow__radio::after {
  background-color: variables.$gray-20;
  content: '';
  height: variables.$space-32;
  position: absolute;
  right: 31px;
  top: 50%;
  transform: translateY(-50%);
  width: 1px;
}
.DuplicateMergeAttributeRow__cell {
  border-bottom: 1px solid variables.$gray-20;
  border-right: 1px solid variables.$gray-20;
  height: 65px;
  padding: variables.$space-12 variables.$space-12 variables.$space-12
    variables.$space-24;
  position: relative;
  width: 25%;
  word-break: break-all;
}

.DuplicateMergeAttributeRow__cell--attribute {
  background-color: transparent;
  border-bottom: none;
  border-right: 0;
  color: variables.$gray-60;
  margin-right: variables.$space-8;
  text-align: right;
  width: auto;
  word-break: normal;
}

.DuplicateMergeAttributeRow__cell--a {
  padding-right: 50px;
}
.DuplicateMergeAttributeRow__cell--b {
  padding-left: 50px;
}
.DuplicateMergeAttributeRow__cell--finalResult {
  border-right: 0;
}

@use '@thrivetrm/ui/stylesheets/variables';

.TargetCompaniesSearchPage__filter {
  display: flex;
  flex-wrap: nowrap;
}

.TargetCompaniesSearchPage__filter .target-companies--sort-and-filter-toolbar {
  flex: 1;
}

.TargetCompaniesSearchPage__filter .btn {
  height: variables.$input-height-base;
  padding: variables.$space-12 variables.$space-16;
}

.TargetCompaniesSearchPage__filter .TargetCompaniesViewModeSelect {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-left: 1em;
}

@use '@thrivetrm/ui/stylesheets/variables';

.PositionTitle > .PositionName {
  display: block;
}

.PositionTitle.PositionTitle--name-first {
  > .PositionName,
  > .PositionTitle__company {
    // When rendering "{title} at {company}" make sure {title} is
    // rendered inline so it doesn't become:
    //   {title}
    //   at {company}
    display: inline;
  }
}

.PositionTitle > .PositionTitle__company > .companies--company-name {
  font-weight: variables.$font-weight-bold;
}

// Gets the label for the horizontal radio buttons (which do not usually
// have a label) closer to the buttons, to look more like other labels in
// the form.
.appointment-form {
  .interviews--interview-type-field .radio {
    margin-top: 0;
  }

  .btn-social {
    font-size: 0.9em;
    margin-top: 5px;
  }

  .btn-social-google_calendar i,
  .btn-social-outlook_calendar i {
    background-size: 48%;
  }
}

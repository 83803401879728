@use '@thrivetrm/ui/stylesheets/variables';

// Adds a border between top level comments.
.CommentList {
  > .CommentListItem {
    border-bottom: 1px solid variables.$gray-5;
  }

  > .CommentListItem:last-child {
    border-bottom: none;
  }

  .child-comments {
    padding-left: 0;
  }

  .child-comments > .CommentListItem {
    list-style: none;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

@use '@thrivetrm/ui/stylesheets/variables';

.CandidacyListItem {
  // HACK: We gotta stop doing this crap!!!
  font-size: 0.9em;
  // Prevent list items from "bleeding" into siblings
  overflow: hidden;
  padding-bottom: 5px;

  // Add space for scroll bars
  padding-right: 10px;

  // IMPORTANT! The sum of these must match
  // ITEM_PADDING constant in `CandidacyListItem.js`!
  padding-top: 5px;

  &__componentContainer {
    &--hasFocus {
      border: 1px solid variables.$gray-40;
    }
  }
  .ContactName {
    font-size: 1.1em;
    font-weight: variables.$font-weight-bold;
  }

  .PositionName {
    color: variables.$gray-50;
    font-weight: variables.$font-weight-bold;
  }

  .LocationName {
    display: block;

    // Add a little padding to give us some space from the .LocationIcon
    padding-right: 1em;

    // This, along with the absolute position of the .LocationIcon,
    // keeps the map marker icon the right even when we're overflowing
    // text
    position: relative;
    width: fit-content;
  }

  .LocationIcon {
    position: absolute;
    right: 0px;
    top: 2px;
  }
}

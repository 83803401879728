@use '@thrivetrm/ui/stylesheets/variables';

.CommentsPanel {
  background: variables.$white;
  display: flex;
  flex-direction: column;

  > form {
    padding-right: em(10px);
  }

  // Slightly reduce the bottom margin of the alert so it matches the more compact
  // spacing in the comment panel
  .alert {
    margin-bottom: 1em;
  }

  // Float the 'Add Comment' button to the top right.
  .form-toggle-button {
    float: right;
  }

  .CommentsPanel__comments-container {
    flex: 1;
    min-height: 20em;
    position: relative;

    .CommentsPanel__scroll-container {
      bottom: 0;
      overflow: auto;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }
}

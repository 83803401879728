$env: production;
@use '@thrivetrm/ui/stylesheets/variables';

.ContactConnectionListField__list-item {
  border: 2px solid variables.$gray-20;
  border-radius: variables.$border-radius-base;
  margin-bottom: variables.$space-32;
}

.ContactConnectionListField__list-item:last-child {
  margin-bottom: 0;
}

.has-error {
  .SelectMenu__button {
    border-color: variables.$brand-red;
  }
}

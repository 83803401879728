@use '@thrivetrm/ui/stylesheets/variables';

//--------------------------------------------------------------------------------------------------
// Most of these are overrides for bootstrap variables.
// For a list of available boootstrap variables, see:
// https://github.com/twbs/bootstrap-sass/blob/v3.4.1/assets/stylesheets/bootstrap/_variables.scss
// Colors are overriden in /app/assets/stylesheets/_colors.scss
// Please try to keep these grouped in the style of the variables.less reference above for easier
// reference.
//--------------------------------------------------------------------------------------------------

$blockquote-font-size: inherit;

//
// Links
// --------------------------------------------------
// NOTE: this variable is defined in @thrivetrm/ui with a different value. When the variable from ui
// is used, it changes how the UI renders (adds hover underline on a number of visual elements that
// are not intended to have underlines), so this will be used for the time being. Solving this would
// entail collab with UX and more fine-tuned Sass updates.
$link-hover-decoration: none;

//
// Bootstrap Dropdowns
// --------------------------------------------------
$dropdown-link-hover-bg: variables.$gray-5;
$dropdown-header-color: variables.$gray-60;
$dropdown-link-active-color: variables.$brand-primary;
$dropdown-link-active-bg: variables.$gray-5;
$dropdown-bg: variables.$white;
$dropdown-border: variables.$gray-20;

$default-border: variables.$gray-20;

//
// Modals
// --------------------------------------------------
$modal-backdrop-opacity: 0.7; // Increase the default background opacity (normally 0.5)

//
// Navs
// --------------------------------------------------
$navbar-height: 48px;
$footer-height: 55px;
$footer-border-top-width: 1px;
$footer-margin-top: variables.$space-16;
$footer-total-height: $footer-height + $footer-border-top-width +
  $footer-margin-top;

//
// Pagination
// --------------------------------------------------
$pager-border-radius: 20px;
$pagination-border: transparent;
$pagination-disabled-border: transparent;
$pagination-hover-border: transparent;
$pagination-active-bg: variables.$brand-secondary;
$pagination-active-border: variables.$brand-secondary;

//
// Panels
// --------------------------------------------------
$panel-border-radius: 2px;

//
// Panels
// --------------------------------------------------
$popover-title-bg: transparent;

//
// Tables
// --------------------------------------------------
$table-border-color: transparent;
$table-bg-accent: variables.$gray-5;

// Alternate background color(s) for highlighting table
// cells. NOT bootstrap defined variables.
$table-bg-highlight: #ffffe0;
$table-bg-highlight-accent: #fffacd;

//
// Wells
// --------------------------------------------------
$well-bg: variables.$gray-5;

//
// Avatars
// --------------------------------------------------
$contact-avatar-size: 70px;

.CandidacyRankingPopover .popover-content {
  // For some reason .popover-content was given a global padding. Annoying.
  // So remove that. At some point we should remove the global padding.
  padding: 0;

  position: relative;

  & > .LoadingIndicator {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  & > .ErrorAlert {
    margin: 1em;
  }
}

.CandidacyRankButton--disabled {
  cursor: not-allowed;
  opacity: 0.5;

  button {
    pointer-events: none;
  }
}

@use '@thrivetrm/ui/stylesheets/variables';

.EntityTagSelect--no-suggestions > .react-tags > .react-tags__search {
  // When there are no "tags" left to select from, don't show the input.
  display: none;
}

.EntityTagSelect {
  .react-tags__suggestions {
    max-width: 600px;
    min-width: 300px;
  }

  .react-tags__suggestions .is-disabled,
  .react-tags__suggestions .is-disabled:hover {
    background: variables.$white;
    border-bottom: 1px solid variables.$gray-20;
    border-top: 1px solid variables.$gray-20;
    color: variables.$gray-60;
    font-weight: variables.$font-weight-bold;
    margin-left: 6px;
    padding-left: 4px;

    & + .is-disabled {
      border-top: none;
    }
  }
}

.EntityTagSelect--isNotFiltering {
  .react-tags__suggestions .is-disabled,
  .react-tags__suggestions .is-disabled:hover {
    position: sticky;
    top: 0;
  }
}

@use '@thrivetrm/ui/stylesheets/variables';

$multi-select-focus-background-color: $dropdown-link-hover-bg;
$multi-select-focus-color: $dropdown-link-hover-color;
$multi-select-hover-background-color: $dropdown-link-hover-bg;
$multi-select-hover-color: variables.$gray-30;

.multi-select-tether-element {
  // Tether renders the element in the document body directly, so make sure this
  // is the top-most component.
  z-index: $z-index-multi-select-tether-element;
}

// The button should look like a text input. This is mostly handled by applying the
// `form-control` class
.multi-select-button {
  border: 1px solid variables.$input-border-color;
  box-shadow: variables.$input-box-shadow;
  padding-left: variables.$space-12;
  padding-right: variables.$space-12;
  white-space: nowrap;

  &:active,
  &:focus,
  &:hover {
    background-color: variables.$white;
    box-shadow: variables.$input-box-shadow;
  }
}

.multi-select-button-content {
  color: variables.$input-color;
  display: flex;
  justify-content: space-between;
}

.multi-select-placeholder {
  color: variables.$input-color-placeholder;
  font-weight: variables.$font-weight-normal;
}

.multi-select-button-text {
  color: variables.$input-color;
  flex: 1;
  font-weight: variables.$font-weight-normal;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
}

// Hard-code the dropdown caret for multi-select to render like the 4.0 select
// menu caret. So that we can avoid having to override every instance.
.multi-select-button-caret {
  background-image: url('~assets/images/chevron-down-4.0.svg');
  background-position: 0 center;
  background-repeat: no-repeat;
  padding-left: variables.$space-12;
  width: variables.$space-16;
  &::before {
    content: '';
  }
}

.multi-select-dropdown {
  background-color: variables.$white;
  border: 1px solid variables.$input-border-color;
  border-top-width: 0;
  box-shadow: 0 4px 7px 0 rgba(variables.$black-shadow, 0.12);
  // moves the dropdown menu up so that it when it's open, it
  // appears to be connected to the button
  margin-top: -3px;
  padding: 0;
  position: relative;
}

.multi-select-dropdown-header {
  padding: 0.25em;
  position: relative;

  .LoadingIndicator {
    margin: 0.25em;
    padding: 4px;
    position: absolute;
    right: 0;
    top: 0;
  }

  .LoadingIndicator img {
    height: variables.$input-height-base - 10px;
    opacity: 0.5;
    width: variables.$input-height-base - 10px;
  }
}

.multi-select-option-list {
  height: 250px;
  max-height: 250px;
  overflow: auto;
  // IMPORTANT: This MUST be set so that scroll calculations are correct when determining if an
  // option is currently visible based on the scroll position.
  position: relative;
}

.multi-select-option-list--virtualized .ReactVirtualized__List {
  max-height: 250px;
  outline: 0;
}

.multi-select-empty-text {
  padding: 0.25em;
}

.multi-select-option {
  margin: 0;
  padding: 0.5em 0.25em;

  &.is-focused {
    background-color: $multi-select-focus-background-color;
    color: $multi-select-focus-color;
  }

  &:hover {
    background-color: $multi-select-hover-background-color;
  }
}

@use '@thrivetrm/ui/stylesheets/variables';

.JobApplicationListItem,
.JobApplicationListItem__Header,
.JobApplicationListItem__Content,
.JobApplicationListItem__Footer {
  display: flex;
  justify-content: space-between;
}

.JobApplicationListItem {
  border-top: 1px solid variables.$gray-20;
  flex-direction: column;

  // Prevent list items from "bleeding" into siblings
  overflow: hidden;

  padding: 0.5em;

  // Add space for scroll bars
  padding-right: 10px;
}

.JobApplicationListItem--row-0 {
  border-top: none;
}

.JobApplicationListItem--rejected {
  opacity: 0.5;
}

.JobApplicationListItem__Content {
  flex: 1;
  padding-bottom: 0.5em;
  padding-top: 0.5em;

  .JobApplicationListItem__DocumentContainer {
    min-width: 10em;
  }

  .JobApplicationIconLinkList {
    flex: 2;
  }

  .JobApplicationListItem__Action {
    flex: 1;
    text-align: right;
  }
}

.JobApplicationListItem__ApplicantName {
  color: variables.$gray-50;
  font-weight: variables.$font-weight-bold;
}

.JobApplicationListItem__AppliedDate {
  color: variables.$gray-40;
  font-size: 0.8em;
}

@use '@thrivetrm/ui/stylesheets/variables';

.contact-avatar {
  font-size: 2.5em;
  height: 32px;
  width: 32px;

  img {
    height: 32px;
    vertical-align: initial;
    width: 32px;
  }
}

.contact-avatar-remove-link {
  color: variables.$brand-danger;
  padding: 0;
}

.contact-avatar-text-span {
  height: 20px;
}

.contact-avatar-uploader-placeholder,
.contact-avatar-uploader-button {
  .contact-avatar .icon-nav-overview {
    font-size: $contact-avatar-size;
  }
}

.contact-avatar-uploader-button {
  color: variables.$gray-40;

  &:hover,
  &:focus {
    color: variables.$gray-40;
  }

  .contact-avatar:hover {
    transition: all ease-in 100ms;
  }
}

.contact-avatar-uploader-error {
  border-radius: 5px;
  display: inline-block;
  margin-left: 15px;
  padding: 2px;
}

.contact-header .contact-avatar {
  align-items: center;
  display: flex;
  justify-content: center;
}

.contact-header .contact-avatar .img-circle:hover {
  filter: brightness(50%);
}

.contact-avatar-uploader-button-modal {
  .contact-file-uploader-dropzone {
    align-items: center;
    display: flex;
    justify-content: center;

    .contact-avatar-uploader-placeholder {
      .contact-avatar {
        align-items: center;
        display: flex;
        height: $contact-avatar-size;
        justify-content: center;
        margin: 10px 0;
        width: auto;

        .img-circle {
          height: $contact-avatar-size;
          vertical-align: middle;
          width: $contact-avatar-size;
        }
      }
    }
  }
}

.contact-photo-uploader {
  .contact-avatar {
    align-items: center;
    display: flex;
    font-size: $contact-avatar-size;
    height: $contact-avatar-size;
    justify-content: center;
    margin: 10px 0;
    width: auto;

    .img-circle {
      height: $contact-avatar-size;
      vertical-align: middle;
      width: $contact-avatar-size;
    }
  }
}

.icon-nav-overview {
  display: flex;
}

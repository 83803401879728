@use '@thrivetrm/ui/stylesheets/variables';

.interviews--interview-title .datetime {
  font-weight: variables.$font-weight-normal;
}

.InterviewView .InterviewDate {
  color: variables.$text-color;
  padding-bottom: 0;
}

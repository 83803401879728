@use '@thrivetrm/ui/stylesheets/variables';

.ContactPositionsPanel {
  .form-toggle {
    float: right;
    font-size: 0.9em;
  }

  .PositionList {
    @include clearfix();
    margin-bottom: 0;
  }

  .PositionListItem {
    border-bottom: 1px solid variables.$gray-30;
    margin-bottom: 0;
    margin-top: variables.$space-16;
    padding-bottom: variables.$space-16;
    width: 100%;
  }

  // Do not add margin or padding to the last item in the list
  .PositionListItem:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }

  .PositionView > .PositionTitle {
    display: none;
  }

  .PositionView__description {
    clear: both;
  }
}

.contacts--candidacy-positions {
  .PositionList {
    .PositionListItem {
      border-bottom: 1px solid variables.$gray-30;
      margin-bottom: 0;
      margin-top: variables.$space-16;
      padding-bottom: variables.$space-16;
      width: 100%;
    }

    .PositionListItem:first-child {
      margin-top: 0;
    }

    .PositionListItem:last-child {
      border-bottom: none;
      padding-bottom: 0;
    }
  }
}

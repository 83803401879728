@use '@thrivetrm/ui/stylesheets/variables';

.tasks-title {
  min-width: 160px;
  .task-title-text {
    .task-title-subject.inline,
    .task-assigned-to {
      display: inherit;
    }

    .task-title-subject {
      font-weight: variables.$font-weight-bold;
    }
  }
  .fa {
    font-size: 2em;
    padding-right: em(10px);
    &.fa-sticky-note {
      color: variables.$brand-secondary;
      font-size: 1em;
      padding-left: em(5px);
      &:hover {
        color: variables.$brand-secondary-light;
      }
    }
  }
}

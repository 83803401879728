@use '@thrivetrm/ui/stylesheets/variables';

.CollapsiblePanel {
  border: none;

  > .panel-heading {
    border: 1px solid $default-border;
    padding: 0;
    transition: background-color 0.5s ease, border-color 0.5s ease;
  }

  > .panel-collapse > .panel-body {
    border: 1px solid $default-border;
    border-top: none;
  }
}

.CollapsiblePanel__placeholder {
  // We render a placeholder when the panel is collapsed, and by giving that placeholder a height
  // we can still get the expanding animation affect.
  min-height: 5em;
}

.CollapsiblePanel--expanded {
  @include panel-variant(
    $default-border,
    variables.$white,
    variables.$brand-dark-blue,
    variables.$brand-dark-blue
  );
  @include stacked-icon(variables.$white, rgba(variables.$white, 0.3));

  .panel-heading a {
    color: variables.$white;
  }
}

.CollapsiblePanel--collapsed {
  @include panel-variant(
    $default-border,
    variables.$brand-secondary,
    variables.$white,
    $default-border
  );
  @include stacked-icon(
    variables.$brand-secondary,
    rgba(variables.$white, 0.3)
  );

  .panel-heading a {
    color: variables.$brand-secondary;
  }
}

.CollapsiblePanel__header {
  font-size: variables.$font-size-base;
}

.CollapsiblePanel__headerIcon {
  float: right;
  line-height: 32px;
  margin-right: variables.$space-16;
  padding: variables.$space-8 0;
}

.CollapsiblePanel__title {
  display: block;
  padding: variables.$space-12 variables.$space-16;
}

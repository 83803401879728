@use '@thrivetrm/ui/stylesheets/variables';

.contact-off-limits-status {
  display: inline-block;

  .contact-off-limits-status-label {
    font-weight: variables.$font-weight-bold;
  }

  .contact-off-limits-status-name {
    color: variables.$gray-40;
    margin-left: 5px;
  }
}
.contact-off-limits-status-tooltip {
  .title {
    font-weight: variables.$font-weight-bold;
    padding-left: 0.5em;
    padding-right: 0.5em;
    text-transform: uppercase;
  }
}

.contact-off-limits-status-icon {
  padding-left: 0.5em;
  padding-right: 0.5em;

  &.off-limits-category-info {
    color: variables.$gray-50;
  }

  &.off-limits-category-warning {
    color: variables.$brand-warning;
  }

  &.off-limits-category-danger {
    color: variables.$brand-danger;
  }
}

.CandidacyList {
  bottom: 0;
  left: 0;
  overflow: hidden;
  // This positioning is necessary for the virtual scrolling to work.
  position: absolute;
  right: 0;
  top: 0;
}

.CandidacyList .ReactVirtualized__Grid {
  // Prevents the blue outline from highlighting the entire grid when an item
  // is "focused".
  outline: none;
}

.target-company-count {
  .target-company-indicator {
    padding-left: 0.25em;
  }
}

.target-company-count-tooltip {
  margin: 0.5em;
  text-align: left;
}

@use '@thrivetrm/ui/stylesheets/variables';

@function em($target, $context: variables.$font-size-base) {
  @return calc($target / $context) * 1em;
}

@mixin text-body-medium {
  font-size: em(14px);
  line-height: 18px;
}

@mixin trim-text($white-space: nowrap) {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: bottom;
  white-space: $white-space;
}

@mixin small-text {
  color: variables.$gray-50;
  font-size: 0.8em;
  font-style: italic;
}

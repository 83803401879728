@use '@thrivetrm/ui/stylesheets/variables';

$rocket-popover-max-height: calc(100vh - 103px);
$rocket-popover-width: 345px;

.rocketreach-button .rocketreach-button-icon {
  font-size: 22px;
}

.rocketreach-popover {
  background-color: variables.$white;
  border: 1px;
  border-color: variables.$gray-30;
  border-style: solid;
  box-shadow: 4px 4px 6px 0 variables.$gray-30;
  color: variables.$text-color;
  left: 5px;
  position: fixed;
  text-align: left;
  top: 53px;
  word-wrap: break-word;
  z-index: $z-index-rocketreach-popover;

  .rocketreach-popover-header {
    background-color: variables.$gray-50;
    color: variables.$white;
    margin: 0;
    padding: 7px 14px 7px 14px;

    .fa.fa-rocket {
      padding-right: 0.5em;
    }

    .rocketreach-close-button {
      color: variables.$white;
      padding: 0;
    }
  }

  .rocketreach-popover-content {
    font-size: 12px;
    max-height: $rocket-popover-max-height;
    overflow-x: hidden;
    overflow-y: scroll;
    width: $rocket-popover-width;

    .LoadingIndicator {
      padding: 0;

      .message {
        font-size: 16px;
        margin-top: 6px;
      }
    }

    .rocketreach-title,
    i.fa.fa-clipboard,
    i.link.fa {
      color: variables.$brand-secondary-light;
      position: static;
    }

    .rocketreach-contact-name {
      color: variables.$gray-60;
    }

    .message,
    .rocketreach-searching {
      color: variables.$brand-info;
    }

    .rocketreach-warning {
      color: variables.$brand-warning;
      text-align: center;
    }

    .rocketreach-contact-attributes-ul + .rocketreach-contact-attributes-ul {
      margin-top: 1.75em;
    }

    .rocketreach-ul {
      margin-bottom: 0;
      padding-left: 0px;

      .rocketreach-li {
        list-style-type: none;
        padding: 4px 4px;

        &:nth-child(odd) {
          background-color: variables.$gray-5;
        }

        i.fa.fa-clipboard {
          visibility: hidden;
        }

        &:hover {
          cursor: pointer;
        }

        &:hover > div i.fa.fa-clipboard {
          visibility: visible;
        }

        i.link.fa {
          width: 1.5em;
        }
      }
    }
  }
}

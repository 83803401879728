.toggle-control {
  &.active {
    .view-state {
      display: none;
    }
  }

  h2 {
    .view-state {
      margin-left: 0.5em;
    }
  }
}

@use '@thrivetrm/ui/stylesheets/variables';

.TabbedHeaderTabsCollapsed.btn {
  border: none;
  padding: 0;
}

.TabbedHeaderTabsCollapsed + .dropdown-menu {
  top: 1em;
}

.dropdown .TabbedHeaderTabsCollapsed.dropdown-toggle.btn,
.dropdown .TabbedHeaderTabsCollapsed.dropdown-toggle.btn:focus,
.dropdown .TabbedHeaderTabsCollapsed.dropdown-toggle.btn:active,
.dropdown .TabbedHeaderTabsCollapsed.dropdown-toggle.btn:hover {
  color: variables.$link-color;
}

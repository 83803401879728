.SearchPageSidebarHeader {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: space-between;

  .CompanyLogo {
    padding: 0 10px 0 5px;
  }

  .SearchPageSidebarHeader__text {
    flex: 1;
  }
}

@use '@thrivetrm/ui/stylesheets/variables';

// Overrides and Custom Thrive Button Types
// -------------------------------------

// Gray outline
.btn-default {
  background-color: variables.$white;
  border: 1px solid variables.$color-border;
  color: variables.$text-color;

  &:hover {
    background-color: variables.$gray-5;
  }

  &:focus {
    background-color: variables.$white;
    outline: 0;
  }
}

// Red fill
.btn-primary {
  background-color: variables.$brand-primary;
  border: 1px solid variables.$brand-primary;
  color: variables.$white;
  &:hover,
  &:focus {
    background-color: darken(variables.$brand-primary, 10%);
    border: 1px solid darken(variables.$brand-primary, 10%);
    color: variables.$white;
  }
  a {
    color: variables.$white;
  }
}

// Red outline
.btn-primary-outline {
  background-color: transparent;
  border: 1px solid variables.$brand-primary;
  color: variables.$brand-primary;
  &:hover,
  &:focus {
    background-color: variables.$brand-primary;
    border: 1px solid variables.$brand-primary;
    color: variables.$white;
  }
  a {
    color: variables.$brand-primary;
  }
}

// Redder outline
.btn-danger-outline {
  background-color: variables.$white;
  border: 1px solid variables.$red;
  color: variables.$red;
  &:hover,
  &:focus {
    background-color: darken(variables.$red, 10%);
    border: 1px solid darken(variables.$red, 10%);
    color: variables.$white;
  }
  a {
    color: variables.$red;
  }
}

// Blue fill
.btn-secondary {
  background-color: variables.$brand-secondary;
  border: 1px solid variables.$brand-secondary;
  box-shadow: 0 1px 2px 0 rgba(variables.$black-shadow, 0.1);
  color: variables.$white;
  &:not([disabled]) {
    &:hover,
    &:focus {
      background-color: variables.$brand-secondary-light;
      border: 1px solid variables.$white;
      color: variables.$white;
    }
  }
  &:hover,
  &:focus,
  a {
    color: variables.$white;
  }
}

// Blue outline
.btn-secondary-outline {
  background-color: variables.$white;
  border: 1px solid variables.$brand-secondary;
  box-shadow: 0 1px 2px 0 rgba(variables.$black-shadow, 0.1);
  color: variables.$brand-secondary;
  &:hover,
  &:focus {
    background-color: variables.$brand-secondary-light;
    border: 1px solid variables.$white;
    color: variables.$white;
  }
}

.btn-secondary-outline.disabled {
  background-color: variables.$gray-20;
  border: 1px solid variables.$color-border;
  color: variables.$text-color;
}

.btn-small {
  font-size: variables.$font-size-small;
  padding: variables.$space-8 variables.$space-16;
}

// Form Buttons
// -----------------------------------------------
.form-group .btn:not(.btn-link) {
  border: 1px solid variables.$input-border-color;
}

// Bootstrap sets the input-group-btn font-size to 0, which
// overrides the .btn font-size
.input-group-btn > .btn,
.input-group-btn > .btn-group {
  border-radius: variables.$input-border-radius;
  font-size: variables.$font-size-small;
  height: variables.$input-height-base;

  .fa {
    font-size: variables.$font-size-small;
  }
}

//   Button Edit
// -----------------------------------------------
.btn-edit {
  margin-bottom: 5px;

  .fa {
    margin-right: 3px;
  }

  &.btn-sm {
    background: variables.$white;
    color: variables.$gray-40;
    padding: variables.$space-8 0px;

    &:hover {
      color: variables.$brand-primary;
    }
  }
}

// Sidebar Buttons
// -----------------------------------------------

.sidebar .resume.btn {
  font-size: 0.9em;
  margin-bottom: variables.$space-12;
  width: 100%;
}

// Social Buttons
// -----------------------------------------------

@mixin button-social-image($url) {
  i {
    background: url($url) center center no-repeat;
    background-size: 65%;
    display: inline-block;
    height: 32px;
    top: 5px;
  }
}

.mobile .btn-social i:not(.fa) {
  height: 38px;
}

.btn-social {
  border-radius: variables.$border-radius-base;
  padding-left: 38px;
  padding-right: 10px;
  position: relative;
  @include button-variant(
    variables.$text-color,
    variables.$white,
    variables.$gray-30
  );
}

.btn-social.btn-social-icon-only--small {
  align-items: center;
  display: flex;
  justify-content: center;
  max-height: 30px;
  padding: 0;
  i {
    display: block;
    line-height: 30px;
    position: static;
    width: 30px;
  }
}

// No text within the button, just the logo
.btn-social-empty {
  min-height: 34px;
  padding-left: 28px;
}

.btn-social i {
  font-size: 1.6em;
  left: 0;
  line-height: 32px;
  position: absolute;
  top: 4px;
  width: 36px;
}

.btn-social-google_oauth2,
.btn-social-google_calendar {
  @include button-social-image('~assets/images/logos/google-logo.png');
}

.btn-social-outlook_calendar,
.btn-social-outlook_email {
  @include button-social-image('~assets/images/logos/outlook-logo.png');
}

.btn-social-office365 {
  @include button-social-image('~assets/images/logos/microsoft-logo.png');
}

.btn-social-slack {
  @include button-social-image('~assets/images/logos/slack-logo.png');
}

.btn-social-chrome {
  @include button-social-image('~assets/images/logos/chrome-logo.png');
}

.btn-social-hubspot {
  @include button-social-image('~assets/images/logos/hubspot-icon.png');
}

.btn-social-gmail {
  @include button-social-image('~assets/images/logos/gmail-logo.svg');
}

// Popover Button
// -----------------------------------------------
.popover .btn-group-vertical .btn {
  @include transition;
  &.active {
    background-color: variables.$gray-5;
  }

  &:focus {
    color: variables.$text-color;
  }

  &:hover {
    background-color: variables.$gray-5;
  }
}

.btn-sm {
  @extend .btn-small;
}

.btn-link {
  background-color: transparent;
  border-color: transparent;
  border-radius: 0;
  padding: 6px 0px;
}

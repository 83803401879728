.AddressView {
  & > .LocationIcon {
    float: left;
    padding: 2px;
  }

  & > .LocationIcon + .AddressView__location {
    margin-left: 1em;
  }
}

@use '@thrivetrm/ui/stylesheets/variables';

.PrimaryPositionModal .PrimaryPositionField {
  // Don't allow the primary position select list to get larger than 50% of the
  // viewport, scroll if it's larger.
  max-height: 50vh;
  overflow-y: auto;
}

.PrimaryPositionModal__modal.hideModal {
  display: none;
}

.PrimaryPositionModal__nextButton {
  // Centers the button displayed at the bottom of the modal
  display: block;
  margin: auto;
}

.PrimaryPositionModal__footer {
  border-bottom: 1px solid variables.$gray-20;
  line-height: 0.1em;
  margin: 20px 0 35px;
  text-align: center;
}

.PrimaryPositionModal__footer-text {
  background: variables.$white;
  font-size: 1em;
  padding: 0 0.5em;
}

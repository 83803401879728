@use '@thrivetrm/ui/stylesheets/variables';

.BulkImportModal__modalBody {
  min-height: 500px;
}

.BulkImportModal__uploader {
  padding: 1em 2em;
}

.BulkImportModal__instructionList {
  font-size: 16px;
  font-weight: variables.$font-weight-bold;
  padding: 0em 3em;
}

.BulkImportModal__descriptionListItem,
.BulkImportModal__instructionListItem {
  margin-top: 1em;
}

.BulkImportModal__descriptionList {
  margin-left: 2em;
}

.BulkImportCard {
  flex-direction: column;
  min-height: 10em;
  padding-top: 32px;
  width: 100%;
}

.BulkImportCard--isComingSoon {
  border-style: dashed;
  opacity: 0.5;
}

.BulkImportCard__icon {
  color: variables.$gray-40;
  font-size: 3em;
}

.BulkImportCard__icon--isComingSoon {
  color: variables.$gray-30;
}

@use '@thrivetrm/ui/stylesheets/variables';

.CandidateAssessment {
  .CandidateAssessmentFormContent {
    visibility: visible;
    width: 100%;

    &--rawNotesOnly {
      visibility: hidden;
      width: 0;
    }

    &__drawer {
      .Drawer__buttons {
        align-items: center;
        display: flex;
        justify-content: space-between;
      }
      &--isSplitView .Drawer__header {
        display: block;
      }
    }

    .Avatar {
      font-size: variables.$font-size-base;
      height: variables.$input-height-small;
      width: variables.$input-height-small;
    }
  }

  .RawNotesContainer {
    display: flex;
    flex-direction: column;
    padding-bottom: variables.$space-24;
    padding-left: 0;
    padding-right: variables.$space-16;
    width: 100%;

    &--rawNotesOnly {
      padding-left: variables.$space-16;
    }
  }

  // Using this flaky selector here to apply padding specifically to the
  // content element of ScrollableContainer because the overflow properties
  // on it cause tooltips near the edges of the container to be cut off.
  // Adding padding gives those tooltips enough room to breathe
  .ScrollableContainer__content {
    padding: 0 variables.$space-16;
  }
}

.talent-pools-empty-state {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 1em 0;

  .talent-pools-empty-state-search-term {
    display: block;
    padding-top: 0.5em;
  }
}

@use '@thrivetrm/ui/stylesheets/variables';

.commission-summary {
  background-color: variables.$white;
  border: 1px solid variables.$color-border;
  border-radius: 5px;
  color: variables.$gray-60;
  display: flex;

  .totals {
    background-color: variables.$gray-5;
    border-radius: 5px;
    flex: 1 1 auto;
    padding: 1em;
  }

  .monthly {
    flex: 1 1 auto;
    padding: 1em 1em 1em 3em;

    .scope-select {
      position: relative;

      .popover {
        left: 40%;
        top: variables.$space-32;
      }

      .options {
        margin: 0;
      }

      .options .popover-content {
        margin: 0;
        padding: 0;
      }

      .options a {
        border-bottom: 1px solid variables.$gray-20;
        color: variables.$gray-60;
        display: block;
        font-size: 1em;
        padding: 0.5em 2em 0.5em 1em;
        text-align: left;
      }

      .options a:last-child {
        border-bottom: none;
      }

      .options a.active {
        color: variables.$brand-primary;
        font-weight: variables.$font-weight-bold;
      }
    }
  }

  .date {
    color: variables.$gray-50;
    font-size: 0.8em;
  }

  .sum {
    margin: 1em 0;

    .number {
      font-size: 1.1em;
      font-weight: variables.$font-weight-bold;
    }
  }
}

.commission-container,
.invoice-container {
  border-bottom-style: solid;
  border-color: variables.$color-border;
  border-width: 2px;
  padding-bottom: 10px;
  padding-top: 10px;
}

.commission-toggle-link-container {
  margin-top: 20px;

  a {
    padding-left: 5px;
    padding-right: 5px;
  }

  a.active {
    font-weight: variables.$font-weight-bold;
  }
}

.commission-index-buttons {
  font-size: variables.$space-24;
  margin-right: 1px;
  margin-top: variables.$space-16;

  & > .btn {
    border: 1px solid variables.$gray-30;
    color: variables.$gray-50;
    // non-standard values used to align non-standard layouts
    font-size: 22px;
    padding: 5px variables.$space-8;

    &.active {
      background-color: variables.$white;
      color: variables.$brand-primary;
    }
  }
}

.search-popover,
.comission-search-popover,
.search-documents-popover {
  padding-left: 3px;
}

.column-invoices {
  .invoice-row,
  .commission-detail-fields {
    margin-bottom: 20px;
    margin-top: 10px;
  }
}

.commission-detail-fields {
  .commission-amount {
    padding-left: 0px;
    padding-right: 0px;
  }

  .commission-percentage {
    padding-left: 0px;
    padding-right: 0px;
  }

  .commission-currency {
    display: inline-block;
  }

  .conversion-rate {
    padding-left: 0px;
    padding-right: 0px;
  }

  // Override the default background color for disabled select fields to match style of other disabled inputs on the page.
  .select2-container--disabled .select2-selection--single {
    background-color: variables.$gray-5;
  }
}

.invoice-fields {
  .invoice-currency {
    display: inline-block;
    padding-right: 0px;
  }

  .invoice-amount {
    padding-left: 0px;
  }
}

.commission_management #invoices,
.commission_management #commission_details {
  .commission_management__message {
    color: variables.$gray-50;
    font-style: italic;
    padding: 1em 0 1em 0;
  }
}

@use '@thrivetrm/ui/stylesheets/variables';

.AssessmentCriteriaCard {
  &__criteriaName {
    align-self: center;
    font-size: variables.$font-size-large;
    font-weight: variables.$font-weight-bold;
    margin: 0;
  }

  &__pillAndRemoveContainer {
    align-items: center;
    align-self: start;
    display: flex;
  }

  &__removeButton {
    height: auto;
    padding-bottom: 0 !important;
    padding-top: 0 !important;
  }

  &__pill {
    background-color: variables.$gray-20;
    border-radius: variables.$space-24;
    margin-right: variables.$space-16;
    padding: variables.$space-4 variables.$space-8;
  }

  .AssessmentCriteriaCard__criteriaMenu {
    .MenuPanel {
      overflow: visible;
    }
  }
}

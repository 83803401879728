@use '@thrivetrm/ui/stylesheets/variables';

// See: https://github.com/i-like-robots/react-tags/blob/master/example/styles.css
.tag-select .tag-select-confirm-delete {
  a + a,
  span + a {
    margin-left: 5px;
  }
}

.react-tags {
  // There is a special case where we are rendering the EntityTagSelect component in the style of
  // a standard input. This allows enough height for the tags to display within the input box.
  height: auto;

  .react-tags__selected {
    display: inline-block;
  }

  .react-tags__search {
    /* clicking anywhere will focus the input */
    cursor: text;
    display: inline-block;

    /* match tag layout */
    margin-bottom: 6px;

    /* prevent autoresize overflowing the container */
    max-width: 100%;

    @media screen and (min-width: 30em) {
      /* this will become the offsetParent for suggestions */
      position: relative;
    }
  }

  .react-tags__search-input {
    background: variables.$white;
    border: 1px dashed transparent;
    border-radius: 2px;
    color: variables.$text-color;
    padding: 1px 4px;
  }

  &.is-focused .react-tags__search-input {
    border-color: variables.$gray-30;
  }

  .react-tags__search input {
    border: 0;

    /* match the font styles */
    font-size: inherit;
    line-height: inherit;

    /* remove styles and layout from this element */
    margin: 0;

    // Since we use a custom font, the tag-select can sometimes measure the width needed before
    // our font gets loaded -- when our font gets loaded, it's got a slightly larger em width,
    // causing the text width to expand as a whole, but since the width was already set by the
    // component, some can get slightly cut off. So this is a fairly arbitrary margin, but we're
    // really only seeing a very slight cutoff, so this should be enough without making the
    // component look lopsided.
    margin-right: 1em;
    /* prevent autoresize overflowing the container */
    max-width: 100%;
    outline: none;
    padding: 0;

    &::placeholder {
      color: variables.$text-color;
    }
  }

  .react-tags__suggestions {
    left: 0;
    position: absolute;
    top: 100%;
    white-space: nowrap;
    width: auto !important;
    z-index: $z-index-react-tags-suggestions;

    @media screen and (min-width: 30em) {
      width: 240px;
    }

    // Suggestions / options menu
    ul {
      background: white;
      border: 1px solid variables.$input-border-color;
      border-radius: variables.$input-border-radius;
      box-shadow: 0 2px 6px rgba(variables.$black-shadow, 0.2);
      list-style: none;
      margin: 4px -1px;
      max-height: 300px;
      overflow-y: scroll;
      padding: 0;

      &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 7px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: rgba(variables.$black-shadow, 0.5);
        border-radius: 4px;
        -webkit-box-shadow: 0 0 1px rgba(variables.$white, 0.5);
      }
    }

    li {
      cursor: pointer;
      font-size: variables.$font-size-small;
      padding: 6px 8px;

      &:hover,
      &.is-active {
        background: variables.$gray-5;
      }

      &.is-disabled {
        cursor: auto;
      }

      mark {
        background: none;
        font-weight: variables.$font-weight-bold;
        padding: 0;
      }
    }
  }
}

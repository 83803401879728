$CandidacyTagSelectDropdownMaxWidth: 15em;

.CandidacyTagSelectDropdown {
  .CandidacyTagSelectDropdown__toggle {
    font-size: 12px;
    height: 30px;
  }

  .CandidacyTagSelectDropdown__menu {
    left: -1 * (calc($CandidacyTagSelectDropdownMaxWidth / 2) - 1em);
    width: $CandidacyTagSelectDropdownMaxWidth;

    .CandidacyTagSelectDropdown_list {
      // Match Bootstrap's .dropdown-header padding
      padding: 3px 20px;
    }

    .TagCheckboxList__filter {
      margin-bottom: 0.25em;
      margin-left: 0.5em;
      margin-right: 0.5em;
      padding: 0.25em;
    }

    .TagCheckboxList {
      .TagCheckbox {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}

@use '@thrivetrm/ui/stylesheets/variables';

.CandidacyListItemSmallV2 {
  align-items: stretch;
  border: 1px solid variables.$gray-20;
  display: flex;
  font-size: 0.9em;
  line-height: 1.2em;
  min-height: 56px;
}

.CandidacyListItemSmallV2__core {
  // The "core" data of the "card". We want this to take up all the space unless the
  // assessment is displayed, in which case it can shrink ("flex-shrink=1")
  flex: 0 1 100%;
  overflow: hidden;
}

.CandidacyListItemSmallV2__action_buttons {
  // This controls the size of the action buttons add-on.
  flex: 0 0 3em;
  overflow: hidden;
}

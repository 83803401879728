.CandidacyActionButtons {
  white-space: nowrap;
}

.CandidacyActionButtons > .btn {
  min-width: 3.25em;
  padding-left: 0.5em;
  padding-right: 0.5em;
  width: auto;
}

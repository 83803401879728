@use '@thrivetrm/ui/stylesheets/variables';

.ResidencyListItem--deleted,
.ResidencyListItem--deleting {
  text-decoration: line-through;
}

.ResidencyListItem--deleted {
  color: rgba(variables.$gray-60, 0.3);
}

.ResidencyListItem__deleteButton {
  float: right;
  margin-top: 4px;
}

@use '@thrivetrm/ui/stylesheets/variables';

.contact-reviews {
  .contact-review {
    margin: variables.$space-16 0;

    > .contact-review-contact-info {
      border: 1px solid variables.$gray-30;
    }

    .contact-review-contact-info {
      align-items: center;
      background-color: variables.$white;
      display: flex;
      padding: variables.$space-16;

      .contact-avatar {
        color: variables.$gray-40;
        font-size: 3.2em;

        &,
        img {
          height: 50px;
          width: 50px;
        }
      }

      .contact-review-content {
        margin-left: variables.$space-16;
        width: 25%;
      }

      .contact-review-associated {
        margin-left: auto;
        margin-right: auto;

        .alert {
          margin: 0;
        }
      }

      .contact-review-actions {
        margin-left: auto; // stick to the right side

        .contact-review-action {
          margin-left: 7px;
        }

        .contact-review-action .fa {
          color: variables.$gray-50;
          font-size: 22px;
        }
      }

      .review-delete-btn {
        margin-left: 19px;
      }
    }

    .contact-review-matches {
      background-color: variables.$white;
      border: 1px solid variables.$gray-30;
      border-top: 0;
      box-shadow: inset 0 8px 9px -7px rgba(variables.$black-shadow, 0.3);
      margin-left: variables.$space-16;
      margin-right: variables.$space-16;

      padding: variables.$space-16;

      .contact-review-match-message {
        color: variables.$brand-danger;
        padding-top: 8px;
      }

      .contact-review-contact-info {
        padding-bottom: 0;
        padding-left: 0;
        padding-top: 0;
      }
    }
  }
}

@use '@thrivetrm/ui/stylesheets/variables';

.DuplicateMergeRecordCard {
  display: inline-flex;
  min-height: 112px;
  min-width: 344px;
  padding: variables.$space-16 variables.$space-24;
  position: relative;
}

.DuplicateMergeRecordCard__infoItem {
  font-size: variables.$space-16;
  line-height: 22px;
}

.DuplicateMergeRecordCard__closeIcon {
  position: absolute;
  right: variables.$space-8;
  top: variables.$space-8;
  &:hover {
    color: variables.$brand-blue;
  }
}

.DuplicateMergeRecordCard__contactInfo {
  align-self: center;
}

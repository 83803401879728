.CandidacyActionButtonsV2.btn-group {
  display: flex;
  flex-direction: column;
  margin: -1px;
}

.CandidacyRankButton {
  margin-bottom: -1px;
}

.CandidacyActionButtonsV2 > .btn,
.CandidacyActionButtonsV2 > .CandidacyActionButtonsV2__vote_btn_group > .btn,
.CandidacyRankButton > .CandidacyRankButton__buttonWrapper > .btn {
  flex: 1;
  padding: 0.3em 0;
}

.CandidacyActionButtonsV2.btn-group.btn-group-vertical > .btn,
.CandidacyRankButton > .CandidacyRankButton__buttonWrapper > .btn {
  // btn-group adds a radius to first and last buttons -- we don't want them, and need to be
  // specific enough to override those rules.
  border-radius: 0;
}

.CandidacyActionButtonsV2 i.icon {
  // Adjust our "custom" icons so that they match in size and positioning to the Font Awesome
  // icons.
  font-size: 0.75em;
  margin-right: 2px;
  position: relative;
  top: 2px;
}

.CandidacyActionButtonsV2 .CommentCount,
.CandidacyActionButtonsV2__OutreachCount,
.CandidacyActionButtonsV2__AssessmentCount {
  position: relative;
  top: 2px;
}

.CandidacyActionButtonsV2 i {
  // Adjust spacing between icon and anything that follows icon
  margin-right: 2px;
}

.OutreachEmailField__To {
  display: flex;
}

.OutreachEmailField__To > .form-group {
  // Let the input take up as much room as it can while leaving room for the
  // CC/BCC toggles (which
  flex: 1;
}

.OutreachEmailField__To__CcToggle {
  margin-left: 0.5em;

  // Top margin pushes the buttons down to align with the input field itself due to the label.
  margin-top: 1.5em;
  // Add a bit of space between the toggle links and the
  // email input field
  padding-left: 1em;

  text-align: right;

  // Prevent the BCC/CC buttons from wrapping onto a new line
  white-space: nowrap;
}

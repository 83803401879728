@use '@thrivetrm/ui/stylesheets/variables';

.PositionListItem {
  .PositionListItem__title {
    margin-bottom: 0;
  }
  .PositionCompanyName {
    font-weight: variables.$font-weight-bold;
  }

  .PositionTitle,
  .PositionTitle__company,
  .PositionListItem__actions {
    display: inline;
  }

  .PositionListItem__actions .Button {
    opacity: 0;
  }

  &:hover .PositionListItem__actions .Button {
    opacity: 1;
  }

  .PositionListItem__actions .ConfirmationPopoverButton__delete_btn {
    opacity: 1;
  }

  .PositionListItem__actions .btn {
    margin-left: 0.5em;
  }
}

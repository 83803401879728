@use '@thrivetrm/ui/stylesheets/variables';

.ToastNotifications {
  bottom: variables.$space-64;
  left: variables.$space-32;
  position: fixed;
  z-index: variables.$z-index-toast;

  // "appear" is the first mount of the container, which equates to "on page load".
  // This transition is intentionally more drastic than "enter" (when a toast is
  // added to the container) because there is more to visually compete with as the
  // entire page renders (vs. a toast as the result of a user action on the page).
  &__transition-appear {
    opacity: 0;
    transform: translateX(-400px);
  }

  &__transition-enter {
    opacity: 0;
    transform: translateX(-1 * variables.$space-32);
  }

  &__transition-appear-active,
  &__transition-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: 200ms all variables.$ease-out-quad;
  }

  &__transition-exit {
    opacity: 1;
  }

  &__transition-exit-active {
    opacity: 0;
    transition: 200ms opacity variables.$ease-out-quad;
  }
}

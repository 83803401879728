@use '@thrivetrm/ui/stylesheets/variables';

.ContactRatingView {
  display: flex;
  flex-direction: row;
}

.ContactRatingView__Avatar {
  // Add a bit of padding between the avatar and the rating details to the right of it.
  padding-right: 1em;
}

.ContactRatingView__Timestamp_AddBy_Wrapper {
  height: 1.5em;
  overflow: hidden;
}

.ContactRatingView__AddedBy_Avatar {
  float: left;
  margin-left: 0.5em;
}

.ContactRatingView__AddedBy {
  font-size: 0.8em;
  font-weight: variables.$font-weight-bold;
}

.ContactRatingView__JobFunction {
  font-size: 0.8em;
}

.ContactRatingView__Timestamp time {
  color: variables.$gray-40;
  float: left;
  font-size: 0.8em;
}

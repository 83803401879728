@use '@thrivetrm/ui/stylesheets/variables';

.popover {
  &.is-visible {
    display: block;
  }
}

.popover-title {
  border-color: variables.$color-border;
  color: inherit;
  margin: 0.5em 0;
}

@use '@thrivetrm/ui/stylesheets/variables';

.CompanyEmployeeCountDeleteToggle__wrapper {
  align-items: center;
  display: flex;
  justify-content: flex-start;

  // compensation for the labels to center trash icon with input
  > .btn {
    margin-top: 6px;
  }
}

.CompanyEmployeeCountListField__item--deleted {
  opacity: 0.5;
}

.CompanyEmployeeCountListField__item--deleted .Select-value,
.CompanyEmployeeCountListField__item--deleted .form-control {
  // need the !important to overwrite reactSelects Select-value color
  color: variables.$brand-danger !important;
  text-decoration: line-through;
}

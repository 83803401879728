@use '@thrivetrm/ui/stylesheets/variables';

.SearchRequestProfilePage {
  &__Container {
    background-color: variables.$gray-5;
    border-right: 1px solid variables.$gray-30;
  }

  &__Field {
    font-weight: variables.$font-weight-bold;
  }

  &__Link {
    font-weight: variables.$font-weight-normal;

    .LabeledContent__value {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__Section {
    border-bottom: 1px solid variables.$gray-30;
    padding-bottom: variables.$space-16;

    .LabeledContent {
      margin-bottom: variables.$space-12;
    }
  }

  &__Notes {
    word-break: break-word;
  }
}

@use '@thrivetrm/ui/stylesheets/variables';

.EducationListField__list {
  @include clearfix();
}

.EducationListField__list-item {
  border: 2px solid variables.$gray-20;
  border-radius: variables.$border-radius-base;
  margin-bottom: variables.$space-32;
}

.EducationListField__list-item:last-child {
  margin-bottom: 0;
}

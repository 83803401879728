@use '@thrivetrm/ui/stylesheets/variables';
// The SketchPicker component has it's own border/drop-shadow,
// which causes the arrow to render just outside that border
// making it look wrong. We basically end up with something like:
//
//     /\
//    / \
// +---------+
// |         |
// +---------+
//
// When we really want something like:
//
//     /\
//    / \
// +--   ----+
// |         |
// +---------+
//
// So these rules pushes the arrow over a little to cover up that border,
// depending on the popover placement.

.ColorPickerPopover.bottom .arrow {
  margin-top: 1px;
}

.ColorPickerPopover__popover {
  z-index: variables.$z-index-modal + 1;
}

.ColorPickerPopover.top .arrow {
  margin-bottom: 1px;
}

.ColorPickerPopover.right .arrow {
  margin-left: 1px;
}

.ColorPickerPopover.left .arrow {
  margin-right: 1px;
}

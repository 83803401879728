.CandidaciesCreateModalForm .modal-body {
  /* Currently the react-select popover is rendered inside
   * the modal (at the DOM level, that is), so if the dropdown
   * needs to be larger to show a decent amount of items, they
   * get hidden. This is hacky for now, but just ensures the modal
   * itself is large enough to show the dropdown when it's full */
  min-height: 24em;

  .CandidaciesCreateModalForm__Message {
    border-bottom: 1px solid $default-border;
    line-height: 1.8;
    margin-bottom: 1em;
    padding-bottom: 1em;
  }

  // Add slightly more space between the fields in the modal,
  // and reduce the space between the radio buttons and the label.
  .form-group {
    margin-bottom: 1em;

    .radio {
      margin-top: 0;
    }
  }
}

.CandidacyTagModal .modal-body {
  // Don't allow the candidacy tag modal to get larger than 50% of the
  // viewport, scroll if it's larger.
  max-height: 50vh;

  // Hack fix for the ongoing issue we have with showing popovers in our
  // modals. The modal needs to be big enough to display the color picker.
  min-height: 400px;
  overflow-y: auto;
}

@use '@thrivetrm/ui/stylesheets/variables';

.CandidacyTagColorSquareList__colorSquare {
  height: 1em;
  width: 1em;
}

.CandidacyTagColorSquareList__moreTag {
  font-size: variables.$font-size-xxsmall;
}

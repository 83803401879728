@use '@thrivetrm/ui/stylesheets/variables';

.avatar,
.resource-logo {
  align-items: center;
  color: variables.$gray-50;
  display: flex;
  justify-content: center;

  &.is-icon {
    padding: 10px;
  }
}

$avatar-size: 80px;

.avatar-contact {
  border-radius: calc($avatar-size / 2);
  height: $avatar-size;
  width: $avatar-size;

  &.is-icon {
    font-size: 44px;
  }

  img {
    border-radius: calc($avatar-size / 2);
    height: $avatar-size;
    width: $avatar-size;
  }
}

.avatar-contact.large {
  height: $avatar-size * 2;
  width: $avatar-size * 2;

  img {
    border-radius: $avatar-size;
    height: $avatar-size * 2;
    width: $avatar-size * 2;
  }
}

.card-candidacy .avatar-contact {
  border-radius: calc($avatar-size / 2);
  color: variables.$gray-40;
  font-size: 32px;
  height: 32px;
  width: 32px;

  &.is-icon {
    padding: 0;
  }

  img {
    border-radius: calc($avatar-size / 2);
    height: 100%;
    width: 100%;
  }
}

.panel-header .avatar-contact {
  border-radius: calc($avatar-size / 2);
  color: variables.$gray-40;
  float: left;
  font-size: 48px;
  height: 48px;
  margin: -20px 5px 0 0;
  width: 48px;

  &.is-icon {
    line-height: 48px;
    padding: 0;

    i {
      display: block;
      height: 48px;
    }
  }

  img {
    border-radius: calc($avatar-size / 2);
    height: 100%;
    width: 100%;
  }
}

.avatar.avatar-company {
  height: 50px;
  padding: 0 10px 0 0;

  img {
    max-height: 50px;
    max-width: 150px;
  }
}

.fa-contact:before {
  content: $fa-var-user;
}
.fa-company:before {
  content: $fa-var-building;
}

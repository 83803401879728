@use '@thrivetrm/ui/stylesheets/variables';

.ContactDuplicates__query-indicator {
  float: right;
}

.ContactDuplicates.alert {
  border-color: transparent;
  border-radius: 0;
  color: variables.$gray-50;
}

.ContactDuplicates.alert-info {
  background-color: variables.$gray-5;
}

.ContactDuplicates.alert-danger {
  background-color: #f2dede;
}

.ContactDuplicates__list-item {
  margin-top: 1em;
}

.GuestAssessmentPage {
  .alert {
    padding-top: 1em;
  }

  .AssessmentRating__stars {
    width: 8.2em;
  }

  &.GuestAssessmentPage--is-previewing {
    .assessment-form > h4,
    .assessment-field {
      display: none;
    }
  }
}

@use '@thrivetrm/ui/stylesheets/variables';

.CompanyLogoEditButton {
  // We show a dotted border when hovering to indicate clicking this will edit the logo, so when
  // we're not hovering we add a transparent border to prevent the image from shifting when
  // the border is added on ohovering.
  border: 1px dotted transparent;
  color: variables.$gray-50;

  // Prevent the border from appearing directly against logo
  padding: 5px;
  position: relative;
}

.CompanyLogoEditButton__indicator {
  display: none;
  font-size: 1.1em;
  left: 50%;

  // Center the indicator (pencil) icon both horizonally and vertically over the logo.
  position: absolute;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.CompanyLogoEditButton:hover,
.CompanyLogoEditButton:focus {
  // Change the border color from transparent to grey when hovering to indicate this is editable.
  border-color: variables.$gray-30;
  // Prevent the btn-link class from making things blue.
  color: variables.$gray-50;

  .CompanyLogo {
    opacity: 0.5;
  }

  .CompanyLogoEditButton__indicator {
    color: variables.$gray-60;
    // Only show the edit indicator on hover.
    display: inline;
  }
}

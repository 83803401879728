@use '@thrivetrm/ui/stylesheets/variables';

.FilterSummary,
.FilterInputAsString {
  font-weight: variables.$font-weight-normal;
}

.FilterInputAsString__label + .FilterInputAsString__value::before {
  color: variables.$gray-40;
  content: ' (';
}

.FilterInputAsString__valueItem.FilterInputAsString__valueItem--AND
  + .FilterInputAsString__valueItem.FilterInputAsString__valueItem--AND::before {
  color: variables.$gray-40;
  content: ' AND ';
}

.FilterInputAsString__valueItem.FilterInputAsString__valueItem--OR
  + .FilterInputAsString__valueItem.FilterInputAsString__valueItem--OR::before {
  color: variables.$gray-40;
  content: ' OR ';
}

.FilterInputAsString__value + .FilterSetAsString__setLabel::before,
.FilterInputAsString__value:last-of-type::after,
.FilterInputAsString__value + .FilterInputAsString__label::before {
  color: variables.$gray-40;
  content: ') ';
}

.FilterInputAsString__valueSeparator,
.FilteSetAsString__setLabel,
.FilterInputAsString__label {
  color: variables.$gray-40;
  font-size: variables.$font-size-base;
}

.FilterInputAsString__value {
  color: variables.$gray-60;
  font-size: variables.$font-size-base;
}

.FilterInputAsString__value--isRangeMin
  + .FilterInputAsString__value--isRangeMax::before {
  color: variables.$gray-40;
  content: ' to ';
}

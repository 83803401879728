@use '@thrivetrm/ui/stylesheets/variables';

.rich-text-area {
  .public-DraftEditorPlaceholder-inner {
    color: variables.$gray-40;
  }

  .DraftEditor-root {
    background-color: $input-bg;
    border: 1px solid variables.$input-border-color;
    border-radius: variables.$input-border-radius;
    box-shadow: inset variables.$input-box-shadow;
    color: variables.$input-color;
    font-size: variables.$font-size-base;
    line-height: 1.5;
    margin-bottom: 1em;
    overflow-x: auto;
    padding: variables.$space-8 variables.$space-12;

    &:focus {
      border-color: variables.$input-border-focus;
    }
  }

  .public-DraftEditor-content {
    min-height: 6em;
  }

  .public-DraftStyleDefault-block,
  .public-DraftStyleDefault-ol,
  .public-DraftStyleDefault-ul,
  .public-DraftStyleDefault-orderedListItem,
  .public-DraftStyleDefault-unorderedListItem {
    margin-bottom: 0;
    margin-top: 0;
  }
}

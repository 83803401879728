@use '@thrivetrm/ui/stylesheets/variables';

.ProfilePage {
  .ProfilePage__header {
    align-items: baseline;
    display: flex;
    justify-content: space-between;

    @media only screen and (max-width: $screen-md) {
      flex-direction: column;
      padding: 10px 0;
      text-align: center;
      width: 100%;
    }
  }

  .ProfilePage__header .btn {
    border-radius: 0;
  }

  .ProfilePage__header .OffLimitsStatus {
    display: block;
  }
  .ProfilePage__header,
  .ProfilePageSection {
    background-color: variables.$white;
    margin-bottom: 1em;
    margin-top: 1em;
  }

  .ProfilePage__header,
  .ProfilePageSection__sidebar,
  .ProfilePageSection__content {
    padding: 1em;
  }

  .ProfilePageSection__sidebar,
  .ProfilePageSection__content {
    padding-top: 0.25em;
  }

  .ProfilePageSection--with-sidebar {
    // When a section has an "sidebar", render it to the left using 40% of the width, with the
    // rest of the content to the right.
    display: flex;

    .ProfilePageSection__sidebar {
      width: 40%;
    }
    .ProfilePageSection__content {
      width: 60%;
    }
  }

  .ProfilePageSection__sidebar {
    border-right: 1px solid variables.$gray-10;
  }
}

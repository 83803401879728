@use '@thrivetrm/ui/stylesheets/variables';

.SearchPageSidebarPopover.DetailsPopover {
  .DetailsPopover__documents {
    float: right;
    margin-top: 0.5em;
    max-width: 50%;
    text-align: right;
  }

  .DetailsPopover__location {
    color: variables.$gray-40;
    margin-bottom: 0.25em;
    margin-top: 0.5em;
  }

  .DetailsPopover__summary {
    margin-bottom: 2em;
    margin-top: 2em;
  }

  .SearchStatusTime__relativetime {
    color: variables.$gray-40;
    margin-left: 0.25em;
  }

  .DetailsPopover__reports {
    border-top: 1px solid variables.$gray-30;
    margin-top: 2em;
    padding-top: 1em;
    text-align: right;
  }
}

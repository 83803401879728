.single-input-form {
  align-items: flex-end;
  display: flex;

  .single-input-form-input {
    flex: 1;
  }

  .single-input-form-actions {
    margin: 0.75em;
  }
}

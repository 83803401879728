@use '@thrivetrm/ui/stylesheets/variables';

.OffLimitsList {
  &__addCustomReasonButton,
  &__viewHistoricalButton {
    color: variables.$brand-dark-purple;
  }
  &__activeReasonCount {
    background-color: variables.$brand-dark-purple;
    border-radius: variables.$space-12;
    color: variables.$white;
    font-size: variables.$font-size-small;
    padding: variables.$space-4 variables.$space-12;
  }
}

@use '@thrivetrm/ui/stylesheets/variables';

.EditableSection {
  margin-bottom: variables.$space-16;

  .EditableSection__title {
    color: variables.$text-color;
  }

  & > .EditableSection__header {
    align-items: center;
    display: inline-flex;
    margin: 0px;
  }

  & > .EditableSection__header > .EditableSection__toggle {
    // Add some space between the section title and the button.
    margin-left: 0.5em;

    // Remove default button padding so it aligns with the text and doesn't increase the header size
    padding: 0;
  }
}

.EditableSection--is-editing {
  // Don't show the edit button while we are in edit mode.
  & .EditableSection__header .EditableSection__toggle {
    color: variables.$gray-40;
  }
}

.EditableSection--has-content.EditableSection--is-viewing,
.EditableSection--has-content {
  // Show the editable content with a low opacity unless hovering over that section.
  & > .EditableSection__header > .EditableSection__toggle {
    opacity: 0.5;
  }

  &:hover > .EditableSection__header > .EditableSection__toggle {
    opacity: 1;
  }
}

@use '@thrivetrm/ui/stylesheets/variables';

.AssessmentRating {
  margin-bottom: variables.$space-2;
  white-space: nowrap;

  label {
    display: inline-block;
    font-size: variables.$font-size-small;
    font-weight: variables.$font-weight-normal;
    padding: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .AssessmentRating__stars {
    display: inline-block;
    min-width: 92px;
  }

  .AssessmentRating__not-rated {
    font-size: 0.9em;
  }

  .fa-info-circle {
    color: variables.$gray-40;
    padding-left: 5px;
    &:hover {
      color: variables.$gray-50;
    }
  }
}

@use '@thrivetrm/ui/stylesheets/variables';

.LabeledContent {
  margin-bottom: 5px;
  margin-top: 5px;

  & > .LabeledContent__label {
    color: variables.$gray-50;
    font-size: 0.9em;
  }
}

.LabeledContent.LabeledContent--inline {
  & > .LabeledContent__label {
    color: variables.$gray-50;
    font-size: 1em;
    font-weight: variables.$font-weight-bold;
    padding-right: 0.5em;
  }

  & > .LabeledContent__label:after {
    content: ': ';
  }

  & > .LabeledContent__label,
  & > .LabeledContent__value {
    display: inline;
  }
}
.LabeledContent--indent {
  padding-left: 1em;
  // This gives us a hanging indent -- subsequent lines of text will be indented slightly, i.e.:
  //
  // My Label: some long text goes here and
  //    wraps around and additional lines will
  //    continue to be indented
  text-indent: -1em;
}

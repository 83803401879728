@use '@thrivetrm/ui/stylesheets/variables';

.CandidacyListModeSelect {
  .CandidacyListModeSelect__button.btn {
    font-size: 12px;
    height: variables.$input-height-base;
    padding: 6px 1em;
  }

  .CandidacyListModeSelect__item {
    padding-left: 1em;
  }
}

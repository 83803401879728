@use '@thrivetrm/ui/stylesheets/variables';

.CandidacyListItemSectionAssessmentLegacy {
  background-color: variables.$gray-5;

  // The whole thing is clickable, so show an appropriate cursor
  cursor: pointer;
  font-size: 0.8em;
  padding: 0 0.5em 0;

  > a {
    color: variables.$text-color;
    display: block;
    height: 100%;
    width: 100%;
  }

  label {
    cursor: pointer;
    font-size: inherit;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .AssessmentRating {
    align-items: center;
    display: flex;
    white-space: nowrap;
  }

  .more-text {
    color: variables.$brand-secondary;
    font-size: 0.8em;
  }

  &.is-missing {
    height: 100%;
    > a {
      align-items: center;
      color: variables.$link-color;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;
    }
  }

  .add-assessment {
    text-align: center;

    i {
      display: block;
    }
  }
}

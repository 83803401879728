@use '@thrivetrm/ui/stylesheets/variables';

.SidebarSearchContentSectionTitle {
  border-bottom: 1px dotted #cccccc;
  font-size: 1.2em;
  padding-bottom: 0.2em;

  &__label {
    color: variables.$brand-dark-blue;
  }

  &:hover &__label {
    color: lighten(variables.$brand-dark-blue, 10%);
  }
}

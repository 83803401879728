@use '@thrivetrm/ui/stylesheets/variables';

.Tag {
  background-color: variables.$gray-20;
  border-radius: 2px;
  box-sizing: border-box;
  color: variables.$gray-50;
  cursor: default;
  display: inline-flex;
  font-size: variables.$font-size-small;
  margin: variables.$space-4 variables.$space-8 variables.$space-4 0;
  padding: variables.$space-2 variables.$space-8;
}

.Tag__deleteButton {
  background: none;
  border: none;
  color: variables.$gray-50;
  cursor: pointer;
  line-height: variables.$font-size-small;
  // Use negative margin to allow a larger "click zone" for the delete button
  // without adding extra space on the right side of the tag when no delete
  // button exists.
  margin-right: -1 * variables.$space-8;
  padding: 0 6px;

  &:hover,
  &:focus {
    color: variables.$gray-60;
  }
}

@use '@thrivetrm/ui/stylesheets/variables';

.new-contact-modal-line {
  border-bottom: 1px solid variables.$gray-20;
  line-height: 0.1em;
  margin: 20px 0 35px;
  text-align: center;
}
.new-contact-modal-line > .new-contact-modal-text {
  background: variables.$white;
  font-size: 1em;
  padding: 0 0.5em;
}

.contact-resume-uploader-select {
  padding-top: 1px;
}

.InlineDefinitionList {
  // Since we're floating everything, clear the entire list.
  @include clearfix;
}

.InlineDefinitionList > dt,
.InlineDefinitionList > dd {
  float: left;
}

.InlineDefinitionList > dt {
  clear: left;
}

.InlineDefinitionList > dt:after {
  // Show a colon after the term.
  content: ':';
}

.InlineDefinitionList > dd {
  // Prevent terms and definitions from running directly into each other.
  padding-left: 0.5em;
}

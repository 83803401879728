@use '@thrivetrm/ui/stylesheets/variables';

.CandidacyListItemStageGroupHeader {
  align-items: center;
  border: none;
  border-radius: 0;
  border-top: 1px solid variables.$gray-20;
  color: variables.$brand-secondary;
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
  text-align: left;

  &:active {
    box-shadow: none;
  }

  .CandidacyListItemStageGroupHeader__Name,
  .CandidacyListItemStageGroupHeader__ItemCount,
  .CandidacyListItemStageGroupHeader__ExpandedIndicator {
    line-height: 1.1;
  }

  .CandidacyListItemStageGroupHeader__Name {
    color: variables.$gray-60;
    flex: 1;
    font-size: 1.2em;
    font-weight: variables.$font-weight-bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .CandidacyListItemStageGroupHeader__ItemCount,
  .CandidacyListItemStageGroupHeader__ExpandedIndicator {
    margin-left: 1em;
  }

  .CandidacyListItemStageGroupHeader__ExpandedIndicator {
    color: variables.$brand-secondary;
    font-size: 1.2em;
  }

  .CandidacyListItemStageGroupHeader__ItemCount {
    background-color: variables.$brand-dark-blue;
    border-radius: 1em;
    color: variables.$white;
    font-size: 1em;
    margin-left: 0.25em;
    min-width: 2em;
    padding: 0.5em;
    text-align: center;
  }
}

.CandidacyListItem-0 .CandidacyListItemStageGroupHeader {
  // Don't render the top border on the first group.
  border-top: none;
}

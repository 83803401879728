@use '@thrivetrm/ui/stylesheets/variables';

.AttachmentsListItem {
  box-shadow: 1px 1px 2px 1px variables.$gray-20;
  display: inline-block;
  height: 4em;
  list-style-type: none;
  margin-right: 1em;
  margin-top: 1em;
  padding: 0.5em;
  vertical-align: top;
  width: 16em;
}

.AttachmentsListItem__name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

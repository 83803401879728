.ContactRatingsModal .ContactRatingListItem {
  // The .modal-body has padding applied. This "undoes" adds a negative margin to each list item
  // so that the background color stretches to the edge of the modal, then re-applies that padding
  // to the list item itself instead, so that it still appears to have modal padding.
  margin-left: -$modal-inner-padding;
  margin-right: -$modal-inner-padding;
  padding-left: $modal-inner-padding;
  padding-right: $modal-inner-padding;
}

.ContactRatingsModal .modal-footer {
  text-align: center;
}

// While loading the contact's assessments, we show a loading indicator and message in the footer.
// The flex layout puts the loader to the left of the message, and we reduce the padding on the
// icon to prevent it from increasing the hieght of the footer needlessly.
.ContactRatingsModal__loadingAssessments {
  align-items: baseline;
  display: flex;
  justify-content: center;

  & .LoadingIndicator {
    padding: 0 1em;
  }
}

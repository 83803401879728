@use '@thrivetrm/ui/stylesheets/variables';

.TargetCompanyTableColumn--CandidateCount {
  text-align: center;
}

.TargetCompanyTableColumn--CompanyNameCandidateCount {
  .TargetCompanyCandidateCount {
    // Render the target company name on it's own line, with the count+indicator below it.
    display: block;
  }

  // added below css for comments icon on the list view of target company
  .company-card-comments .btn {
    font-size: variables.$font-size-small;
    padding: 0px;

    .btn-link {
      padding: 0px;
    }

    &:hover,
    &:focus {
      color: variables.$brand-primary;

      .icon-comment-bubble,
      a {
        color: variables.$brand-primary;
      }
    }

    .icon-comment-bubble {
      color: variables.$gray-40;
      position: relative;
      top: variables.$space-2;
    }
    .btn-icon {
      margin-right: variables.$space-4;
    }
  }
}

.TargetCompanyTable__row--inactive > td {
  // Set the foreground opacity for inactive rows
  opacity: 0.5;
}

.TargetCompanyTable__scrollContainer {
  overflow: visible;
}

.CandidacyAssessmentsPanelLegacy,
.CandidacyAssessmentsPanel {
  .CandidacyCreateGuestAssessmentInviteButton {
    @include clearfix();

    .btn-link {
      float: right;
      font-size: 25px;
      padding: 0px;
    }
  }
}

.TagField {
  align-items: baseline;
  // Render the field horizontally
  display: flex;
}

.TagField__label {
  white-space: nowrap;
}

.TagField__name {
  flex: 1;
}

.TagField__label,
.TagField__name,
.TagField__color {
  margin-left: 0.25em;
  margin-right: 0.25em;
}

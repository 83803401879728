@use '@thrivetrm/ui/stylesheets/variables';

.DuplicateMergeAttributes {
  background-color: variables.$gray-10;
  position: relative;
  width: 100%;
}

.DuplicateMergeAttributes__tooltipContainer {
  bottom: -4px;
  position: relative;
  right: -13px;
  transform: translateX(50%);
}

.DuplicateMergeAttributes__tooltipText {
  background-color: rgba(variables.$black-shadow, 0.7);
  border-radius: 3px;
  bottom: 125%;
  color: variables.$white;
  margin-left: -52.5px;
  padding: 5px 0;
  position: absolute;
  text-align: center;
  width: 105px;
  z-index: 1;
}

.DuplicateMergeAttributes__tooltipText:before {
  border-color: rgba(variables.$black-shadow, 0.7) transparent transparent
    transparent;
  border-style: solid;
  border-width: 10px;
  content: '';
  left: 50%;
  margin-left: -10px;
  opacity: 1;
  position: absolute;
  top: 100%;
  visibility: visible;
}

.DuplicateMergeAttributes__table {
  border: none;
  box-shadow: none;
  min-width: 850px;
}

.DuplicateMergeAttributes__row {
  height: 65px;
}

.DuplicateMergeAttributes__header {
  background-color: transparent;
  border: none;
  border-right: 1px solid variables.$gray-10;
  color: variables.$gray-60;
  font-size: variables.$space-16;
  justify-content: space-between;
  padding: variables.$space-12 variables.$space-12 variables.$space-12
    variables.$space-24;
  text-align: center;
  width: 25%;
}

.DuplicateMergeAttributeRow {
  border: none;
}

.DuplicateMergeAttributes__row--category {
  // using element selector due because this is overridding style set on base
  td {
    height: initial;
  }
}

.DuplicateMergeAttributeRow__text--isDisabled {
  color: variables.$gray-30;
}
.DuplicateMergeAttributeRow__text--isDiscarded {
  text-decoration: line-through;
}
.DuplicateMergeAttributes__categoryCell {
  background-color: transparent;
  border: none;
  padding: variables.$space-12 0 variables.$space-12 variables.$space-8;
  text-transform: uppercase;
  vertical-align: bottom;
}
.DuplicateMergeAttributeRow__noSelectionNeeded {
  background-color: variables.$gray-10;
  border-radius: variables.$border-radius-base;
  display: inline-flex;
  height: variables.$space-32;
  justify-content: center;
  position: absolute;
  right: -32px;
  top: 50%;
  transform: translateY(-50%);
  width: variables.$space-64;
  z-index: 1;
}
.DuplicateMergeAttributes__errorCard {
  bottom: 85px;
  margin-top: variables.$space-24;
  position: sticky;
  z-index: $z-index-message-container;
}

.DuplicateMergeAttributes__autoMergeAttributes {
  flex-grow: 1;
  // On large monitors, the column spacing starts to impact readability without
  // adding any value, so clamp the width
  max-width: 850px;
}

.DuplicateMergeAttributes__autoMergeAttributesList {
  column-count: 4;
}
.DuplicateMergeAttributes__loading {
  min-height: 85vh;
  position: 'sticky';
}
.DuplicateMergeAttributes__loadingMessage {
  position: absolute;
  text-align: center;
  top: 65%;
  width: 100%;
}
.DuplicateMergeAttributes__loadingIcon {
  height: 300px;
  position: absolute;
  top: calc(40% - 150px);
  width: 300px;

  svg {
    fill: none;
    fill-rule: evenodd;
    height: 100%;
    stroke: variables.$gray-60;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1.5;
  }
}
.DuplicateMergeAttributes__loadingIcon--left {
  animation: DuplicateMergeAttributes__loading--slideRight 3s 0s
    variables.$ease-in-out-quad 1;
  animation-fill-mode: forwards;
}

@keyframes DuplicateMergeAttributes__loading--slideRight {
  0% {
    left: 0;
  }
  100% {
    left: calc(50% - 150px);
  }
}

.DuplicateMergeAttributes__loadingIcon--right {
  animation: DuplicateMergeAttributes__loading--slideLeft 3s 0s
    variables.$ease-in-out-quad 1;
  animation-fill-mode: forwards;
  right: 0;
}
@keyframes DuplicateMergeAttributes__loading--slideLeft {
  0% {
    right: 0;
  }
  100% {
    right: calc(50% - 150px);
  }
}

@use '@thrivetrm/ui/stylesheets/variables';

.ContactEducations {
  .form-toggle {
    float: right;
    font-size: 0.9em;
  }

  .EducationList {
    @include clearfix();
    margin-bottom: 0;
  }

  .EducationListItem {
    border-bottom: 1px solid variables.$gray-30;
    margin-bottom: 0;
    margin-top: variables.$space-16;
    padding-bottom: variables.$space-16;
    width: 100%;
  }

  .EducationListItem:first-child {
    margin-top: 0;
  }

  .EducationListItem:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }

  .EducationView__description {
    margin-top: variables.$space-4;
  }
}

.DatasetView--overlayLoadingIndicator {
  // When the loader is set to "overlay" mode, then we position the LoadingIndicator absolutely
  // relative to the the DatasetView.
  position: relative;

  & > .LoadingIndicator {
    // Add a slightly opaque background to make this feel like an overlay blocking the
    // underlying dataset
    background-color: rgba(200, 200, 200, 0.2);
    bottom: 0;
    left: 0;

    // Add a bit of padding so the spinner isn't right at the top of the DatasetView
    padding: 4em;
    position: absolute;
    right: 0;
    top: 0;
    // position loader on top of any fixed columns
    z-index: 2;
  }

  & > .LoadingIndicator > img {
    // Increase the size of the actual spinner
    height: 50px;
    width: 50px;
  }
}

.documents--document-table-row {
  &.fade-exit {
    opacity: 1;
  }

  &.fade-exit-active {
    opacity: 0;
    transition: 1500ms ease-out all;
  }

  .form-action-wrapper {
    display: inline-block;
  }

  .form-actions {
    margin-bottom: 0;
    margin-left: 1em;
    margin-top: 0;
  }

  .documents__documentTableRow--edit {
    min-width: 175px;
  }
}

.documents--document-table > tbody > tr.documents--document-table-row > td {
  // So that when we enter edit mode, the row height doesn't suddenly increase
  // to fit the select control.
  height: 45px;
  // Freakin' BOOTSTRAP!!! We need to be super-specific here so we win out
  // over bootstrap on our style rule.
  vertical-align: middle;
}

.documents--document-table-row.is-deleted {
  text-decoration: line-through;
}

.documents--document-table-row-label {
  .form-group {
    width: 50%;
  }
}

.documents--document-table-row-progress {
  width: 2em;
}

.documents--document-table-row-progress > i {
  visibility: hidden;
}

.documents--document-table-row.is-deleting {
  .documents--document-table-row-progress > i {
    visibility: visible;
  }
}

@use '@thrivetrm/ui/stylesheets/variables';

.SkyminyrCompanySelect__option {
  align-items: center;
  display: flex;
  white-space: normal;
}

.SkyminyrCompanySelect__avatar {
  background-position: center;
  background-size: contain;
}
.SkyminyrCompanySelect__logo {
  height: inherit;
  object-fit: contain;
  width: inherit;
}

.SkyminyrCompanySelect__learnMore {
  color: variables.$blue;
  text-decoration: underline;
  &:hover {
    color: variables.$blue;
  }
}
.SkyminyrLegacyCompanySelect__detailsSection {
  max-width: 228px;
}
.SkyminyrCompanySelect__detailsSection {
  width: calc(100% - 108px);
}
.SkyminyrCompanySelect__companyCreateButton,
.SkyminyrCompanySelect__companyRemoveButton {
  flex-shrink: 0;
}

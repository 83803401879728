.SidebarSearchContentHeader {
  margin-top: 1em;
}

.SidebarSearchContentHeader__detailToggle {
  margin-bottom: 1.5em;
  padding-left: 0.5em;
  padding-right: 0.5em;
}

.SidebarSearchContentHeader .CandidaciesPerStageDonutChart {
  padding: 1em;
}

.SidebarSearchContentHeader .SearchCompensationAveragesList h4 {
  font-size: 0.95em;
}

@use '@thrivetrm/ui/stylesheets/variables';

.clipboard-modal {
  .modal-title {
    display: inline;
  }

  .modal-footer {
    text-align: left;
    button {
      float: right;
    }
  }

  .clipboard-modal-copy-link {
    font-size: em(12px);
    padding-top: em(8px);

    .fa-link {
      font-size: 14px;
      padding-right: 3px;
    }
  }

  .clipboard-modal-success-message {
    color: variables.$green;
    display: inline;
    line-height: 30px;
  }
}

@use '@thrivetrm/ui/stylesheets/variables';

.RecommendationListItem {
  align-items: stretch;
  border: 1px solid variables.$gray-20;
  display: flex;
  font-size: 13px;
  margin-right: 20px;
  margin-top: 20px;
  &__contactName,
  &__companyName {
    color: variables.$gray-50;
    font-weight: variables.$font-weight-bold;
  }
  &__positionName,
  &__locationName {
    color: variables.$gray-40;
    margin-right: variables.$space-8;
    padding-right: 1em;
    position: relative;

    .LocationIcon {
      position: absolute;
      right: 0px;
      top: 2px;
    }
  }
  &__willingToRelocate {
    color: variables.$gray-40;
    font-size: 0.9em;
  }
  &__searchStageName,
  &__companyName,
  &__locationName {
    color: variables.$gray-40;
    font-size: 0.9em;
    font-weight: variables.$font-weight-normal;
  }
  &__contactIconLinkList {
    color: variables.$brand-secondary;
    display: inherit;
    list-style: none;
    margin-bottom: 10px;
    margin-top: 0;
    padding: 0px;
    text-align: left;
  }

  &__contactIconLinkList > li {
    display: inline-block;
    margin-left: 0.2em;
    margin-right: 0.2em;
  }

  &__contactProfileLink {
    .fa-lg {
      font-size: 1.25em;
      padding-left: 0.1em;
    }
  }

  .RecommendationListItem__shareBtn {
    font-size: 12px;
  }
}

.RecommendationListItem__core {
  flex: 0 1 100%;
  overflow: hidden;
}

.RecommendationListItem__coreContent {
  align-items: stretch;
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
}

.RecommendationListItem__status {
  border-top: 1px solid #dee0e8;
  font-size: 12px;
  overflow: hidden;
  padding-left: 4.5em;
  white-space: nowrap;
}

.RecommendationListItem__pointer {
  cursor: pointer;
  padding-left: 4px;
}

.RecommendationListItem__primaryDetails,
.RecommendationListItem__secondaryDetails {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.RecommendationListItem__primaryDetails {
  justify-content: flex-start;
  text-align: left;
}

.RecommendationListItem__secondaryDetails {
  justify-content: flex-end;
  padding-left: 3em;
  padding-right: 10px;
  text-align: right;
}

.RecommendationListItem__coreIconStrip {
  align-items: center;
  display: flex;
  flex: 0 0 45px;
  flex-direction: column;
  overflow: hidden;
  padding: 0.5em;
  text-align: center;
}

.RecommendationListItemSectionCore {
  align-items: stretch;
  display: flex;
}

.RecommendationListItem__coreDetails {
  display: flex;
  flex: 1;
  overflow: hidden;
  padding: 0.5em;
}

.RecommendationListItem__shareBtn {
  border-radius: 5px;
  margin-top: 10px;
}

.RecommendationListItem__score {
  float: right;
  line-height: 30px;
  padding-right: 10px;

  .RecommendationListItem__scoreLabel {
    font-weight: variables.$font-weight-bold;
  }
}

.RecommendationListItem__recencyInfo {
  line-height: 3;
}

.RecommendationListItem__contactCreated {
  margin-right: 50px;
}

.RecommendationListItem__fitQuality {
  line-height: 3;
}

.RecommendationListItem__fitContent {
  margin-left: 1em;
}

.RecommendationListItem__desiredCompensation,
.RecommendationListItem__currentCompensation,
.RecommendationListItem__compensation {
  line-height: 2;
  padding-top: 6px;
  div {
    line-height: 1.5;
  }
}

.RecommendationListItem__relIndustries,
.RecommendationListItem__relSectors {
  white-space: normal;
}

.RecommendationSearchPage__container .popover-content {
  padding: 9px 4px;
}

.SearchStageListMenuItem__Button {
  min-width: 0px;
}

.RecommendationListItem__dataRecency,
.RecommendationListItem__fitQuality {
  padding-top: 10px;
}

.RecommendationListItem--expanded {
  padding-bottom: 1em;
}

.EducationListItem {
  .EducationTitle__actions .Button {
    opacity: 0;
  }

  &:hover .EducationTitle__actions .Button {
    opacity: 1;
  }

  .EducationTitle__actions .ConfirmationPopoverButton__delete_btn {
    opacity: 1;
  }
}

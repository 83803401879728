.CompanyBasicsView__external_url {
  display: flex;

  &.LabeledContent.LabeledContent--inline {
    padding-left: 0;
    text-indent: 0;
  }

  .LabeledContent__label {
    white-space: nowrap;
  }
  .LabeledContent__value {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

@use '@thrivetrm/ui/stylesheets/variables';

/**
 * A button group that act as radio buttons.
 */
.btn-toggle-group {
  .btn:hover,
  .btn:focus {
    border-color: variables.$color-border;
  }

  .btn.btn-primary,
  .btn.btn-primary-outline {
    &:hover,
    &:focus {
      border-color: variables.$brand-primary;
    }
  }

  .btn.btn-secondary,
  .btn.btn-secondary-outline {
    &:hover,
    &:focus {
      border-color: variables.$brand-secondary;
    }
  }

  .btn-inactive {
    opacity: 0.75;
  }
}

.btn-toggle {
  &:focus {
    outline: 0;
  }

  &.active {
    background-color: variables.$gray-5;
  }
}

.ContactEmailSelectValue__name {
  // Add some padding between the name and email address
  padding-right: 0.5em;
}

// Put email address in parenthesis, but only if it has a name associated with it.
.ContactEmailSelectValue--has-name > .ContactEmailSelectValue__email {
  &:before {
    content: '(';
  }
  &:after {
    content: ')';
  }
}

@use '@thrivetrm/ui/stylesheets/variables';

// Limit styles to only apply to SelectFilter instance
.SelectFilter {
  position: relative;
  .MultiValueInput {
    position: relative;
    &__control {
      border: 1px solid variables.$input-border-color;
      box-shadow: inset variables.$input-box-shadow;
      min-height: variables.$input-height-small;
      padding: variables.$space-2 variables.$space-8;
    }
    &__indicators {
      align-items: center;
      display: flex;
    }
    &__multi-value {
      align-items: center;
      background-color: variables.$gray-20;
      border-radius: 2px;
      color: variables.$gray-50;
      display: inline-flex;
      font-size: variables.$font-size-small;
      justify-content: space-between;
      line-height: 16px;
      margin: variables.$space-4 variables.$space-8 variables.$space-4 0;
      overflow: hidden;
      padding: variables.$space-2 variables.$space-8;
      &__remove {
        align-items: center;
        display: flex;
      }
      &__label {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    &__option {
      color: variables.$text-color;
      display: block;
      font-size: variables.$font-size-base;
      padding: variables.$space-4 variables.$space-8;
    }
    &__placeholder {
      color: variables.$gray-30;
    }
  }
}

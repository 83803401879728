@use '@thrivetrm/ui/stylesheets/variables';

.tasks-filters {
  font-weight: variables.$font-weight-bold;
  .tasks-filter-row {
    width: 90%;
    button {
      width: 100%;
    }
  }
}

.tasks-filter-row.selected button {
  color: variables.$link-hover-color;
}

@use '@thrivetrm/ui/stylesheets/variables';

.CandidacyCommentsPanel.CommentsPanel {
  background: inherit;
  padding: 0;

  .form-toggle-button {
    float: none;
  }

  // Override the overflow: auto property from .CommentsPanel so we don't end up
  // with nested scrolling sections in the CandidacySearchSidebar.
  .CommentsPanel__comments-container {
    .CommentsPanel__scroll-container {
      overflow: visible;
    }
  }

  .CommentList .CommentListItem {
    border-bottom: none;
    margin-top: variables.$space-16;

    // Override the default 25% opacity.
    .CommentListItem__edit,
    .CommentListItem__delete,
    .CommentListItem__reply {
      opacity: 1;
    }
  }
}

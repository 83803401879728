.ContactMultiSelect--is-creatable {
  display: flex;
  max-width: 100%;

  .MultiSelect {
    flex: 1;
    min-width: 0;
  }

  .multi-select-button {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }

  .ContactCreateModalButton {
    border-radius: 5px;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    font-size: 12.5px;
    margin-left: -1px;
    padding-bottom: 0;

    // For some reason the padding on this button causes the
    // field to take up extra height when it's wrapped in a bootstrap `col-*`
    padding-top: 0;
  }
}

@use '@thrivetrm/ui/stylesheets/variables';

.resource-name {
  color: variables.$gray-50;
  font-size: variables.$font-size-base;
  line-height: 1.3;
  margin-bottom: 0.5em;
  margin-top: 0;
  text-transform: none;

  p {
    margin-bottom: 0;
  }

  strong {
    color: variables.$gray-60;
    display: block;
  }

  a {
    color: variables.$text-color;
    display: block;
  }
}

.resource-name-heading {
  margin-bottom: 0;
}

.resource-meta {
  color: lighten(variables.$gray-50, 20%);
  font-size: 0.8em;
}

.contacts .page-title,
.outreaches .page-title,
.references .page-title {
  .avatar.is-icon {
    height: 44px;
    padding: 0;
    padding-top: 13px;
    width: 44px;
  }
}

.contact-meta {
  margin: 0 0 5px 0;
  text-align: right;
  .icon {
    font-size: 18px;
  }
}

.company-meta {
  text-align: right;
}

.created_by {
  @include text-body-medium;
  color: variables.$brand-primary;
  .meta-label {
    color: variables.$gray-50;
  }
}

.updated,
.off-limits {
  @include text-body-medium;
  color: variables.$gray-40;
  .meta-label {
    color: variables.$gray-50;
  }
}

.contact-meta-list {
  list-style: none;
  > li {
    display: inline-block;
    padding-left: 10px;
    &:first-child {
      padding-left: 0;
    }
    &.btn-resume-container {
      .btn {
        margin: -12px 0 0 0;
      }
    }
  }
  .popover {
    font-size: 12px;
    width: 200px;
  }

  .popover-content {
    overflow: hidden;
    text-overflow: ellipsis;

    .contact-label {
      display: block;
    }
  }
}

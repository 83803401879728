.network-form {
  .contact_network_id {
    width: 86%;

    label {
      margin-top: 8px;
      width: 100%;
    }
  }
}

.CommaDelimitedList {
  /* Render horizontally and remove standard list padding */
  display: inline;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.CommaDelimitedList > li {
  display: inline;
}

.CommaDelimitedList > li:after {
  /* Separates each item by trailling comma...(1) */
  content: ', ';
}

.CommaDelimitedList > li:last-child:after {
  /* (1)... But no trailing comma after the last item */
  content: '';
}

.ExternalLink > .ExternalLinkIndicator {
  // Ensure there is padding between the external link indicator icon and it's children.
  // It could be rendered before or after the children so the padding location is dependent
  // on whether it's the first or last child.,

  &:first-child {
    padding-left: 0.5em;
  }

  &:last-child {
    padding-left: 0.5em;
  }
}

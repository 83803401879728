@use '@thrivetrm/ui/stylesheets/variables';

.page-header {
  align-items: center;
  background-color: variables.$brand-dark-blue;
  border-bottom: 1px solid variables.$gray-20;
  display: flex;
  justify-content: space-between;
  margin: 0;
  min-height: 88px;
  padding: 0 variables.$space-24;
  position: relative;

  h1 {
    align-items: center;
    color: variables.$white;
    display: flex;
    margin: 0;
  }

  .actions {
    .social-media-icons {
      display: inline-block;
      padding: 0;
      vertical-align: bottom;
    }
  }

  .resource-name-heading {
    color: variables.$gray-30;
    a {
      color: variables.$gray-30;
    }
    strong {
      color: variables.$white;
    }
  }
}

.page-title {
  align-items: center;
  display: flex;

  .contact-name {
    margin-left: variables.$space-16;
  }

  .icon-candidate {
    color: variables.$brand-primary;
  }

  .icon-primary {
    color: variables.$brand-secondary;
  }

  .icon-person-filled {
    color: variables.$gray-40;
  }
}

.page-actions {
  // Buttons have a default style to render a margin between sibling buttons. But in some page
  // headers we render a button as a React app next to a Rails button, which results in a DOM of:
  //
  // <div data-component="NewContactButton">
  //   <button class="btn btn-primary" type="button">New Candidate</button>
  // </div>
  // <a class="btn" href="#">Finish Editing</a>
  //
  // Since the buttons are not siblings in this scenario, the margin is not applied. So here
  // we are detecting this and wiring it back up.
  div[data-component] {
    display: inline-block;

    & + .btn {
      margin-left: variables.$space-12;
    }
  }
}

.company-page,
.search-page:not(.candidates-new) {
  .page-header .actions {
    bottom: 10px;
  }
}

.page-header-icon {
  margin-right: 12px;

  .fa {
    color: variables.$gray-30;
  }

  .modifier {
    font-size: 0.5em;
    left: -0.2em;
    position: relative;
    top: -1em;
  }

  .type {
    font-size: 35px;
    position: relative;
    top: -2px;
  }
}

.status-header {
  .company-icons,
  .contact-icons {
    margin-left: 0px;

    .linkedin a i {
      color: variables.$linkedin-blue;
    }

    .in-search i {
      color: variables.$gray-50;
    }

    .active-in-search i,
    .business-development i,
    .past-client i {
      color: variables.$brand-warning;
    }

    .current-client i,
    .placed i,
    .pipeline-company i,
    [class*=' portfolio-company'] i,
    [class^='portfolio-company'] i,
    [class*='month-zone '] i,
    [class$='month-zone'] i {
      color: variables.$brand-danger;
    }

    .status-icon {
      padding-left: 5px;
    }
  }

  .status-bar-container {
    background-color: variables.$gray-5;
    font-size: em(12px);
    margin-bottom: -1px;
    padding: 5px 0;

    .status-bar {
      display: table;
      min-height: 33px;
      width: 100%;
    }

    .status-metadata {
      display: table-cell;
      vertical-align: middle;
    }

    .status-label {
      font-weight: variables.$font-weight-bold;
      text-transform: uppercase;
    }

    .status-warning-icon {
      color: variables.$red;
      margin-right: 5px;
    }

    .status-alert-icon-container {
      background-color: variables.$brand-primary;
      border-radius: 100%;
      color: variables.$white;
      display: inline-block;
      margin-right: 5px;
      padding: 5px 8px;

      .status-alert-icon {
        font-size: 12px;
      }
    }
  }
}

@use '@thrivetrm/ui/stylesheets/variables';

.card {
  align-items: stretch;
  border: 1px solid variables.$gray-20;
  border-radius: variables.$border-radius-base;
  display: flex;
  flex-flow: row wrap;
  padding: 10px 0;
  position: relative;
  transition: all;

  + .card {
    margin-top: 1.5em;
  }

  > div + div {
    margin-left: 1em;
  }

  h3,
  p {
    margin: 0 0 2px;
  }

  &.is-inactive {
    opacity: 0.5;
  }

  &:hover .timestamp {
    .edit,
    .delete {
      opacity: 1;
    }
  }

  .content {
    flex: 1;
  }
}

.JobApplicationList {
  bottom: 0;
  left: 0;
  overflow: auto;
  // This positioning is necessary for the virtual scrolling to work.
  position: absolute;
  right: 0;
  top: 0;
}

.JobApplicationList .ReactVirtualized__Grid {
  // Prevents the blue outline from highlighting the entire grid when an item
  // is "focused".
  outline: none;
}

.form-toggle {
  & > h2,
  & > h3 {
    line-height: 2;
    padding-bottom: 0.25em;

    & > .form-toggle-button {
      margin-left: 0.5em;
    }
  }
}

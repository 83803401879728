.CandidacyListItemSectionDetails {
  display: flex;
}

.CandidacyListItemSectionDetails__primary,
.CandidacyListItemSectionDetails__secondary,
.CandidacyListItemSectionDetails__tertiary {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.CandidacyListItemSectionDetails__primary {
  justify-content: flex-start;
  text-align: left;
}

.CandidacyListItemSectionDetails__secondary {
  justify-content: flex-end;
  text-align: right;
}

.CandidacyListItemSectionDetails__secondary.CandidacyListItemSmallSectionDetails__secondary {
  padding-left: 3em;
}

.CandidacyListItemSectionDetails__tertiary {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
}

// Button variants
//
// Easily pump out default styles, as well as :hover, :focus, :active,
// and disabled options for all buttons

@mixin button-variant($color, $background, $border) {
  background-color: $background;
  border-color: $border;
  color: $color;

  &:focus,
  &.focus {
    background-color: darken($background, 10%);
    border-color: darken($border, 25%);
    color: $color;
  }
  &:hover {
    background-color: darken($background, 10%);
    border-color: darken($border, 12%);
    color: $color;
  }
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    background-color: darken($background, 10%);
    background-image: none;
    border-color: darken($border, 12%);
    color: $color;

    &:hover,
    &:focus,
    &.focus {
      background-color: darken($background, 17%);
      border-color: darken($border, 25%);
      color: $color;
    }
  }
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &:hover,
    &:focus,
    &.focus {
      background-color: $background;
      border-color: $border;
    }
  }

  .badge {
    background-color: $color;
    color: $background;
  }
}

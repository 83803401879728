@use '@thrivetrm/ui/stylesheets/variables';

.RecordIndexNoDataTable {
  background-color: variables.$white;
  border: 1px solid variables.$gray-20;
  border-bottom: none;
  box-shadow: 0 2px 4px rgba(variables.$gray-60, 0.12);
  overflow: hidden;

  width: 100%;

  &__table {
    border: none;
    box-shadow: none;
  }

  &__card {
    display: flex;
    flex-direction: column;
    margin: variables.$space-64 auto;
    text-align: center;
    width: 300px;
  }
}

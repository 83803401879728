@use '@thrivetrm/ui/stylesheets/variables';

//   Candidacy Row Buttons
// -----------------------------------------------

%candidacy-action-button {
  @include transition;
  background-color: variables.$white;
  border: 1px solid variables.$color-border;
  color: variables.$text-color;
  font-size: 1em;
  height: 36px;
  line-height: 1em;
  padding: 0.5em 0.75em;

  &:hover,
  &:focus,
  &.focus,
  &:active,
  &:active:focus,
  &.active {
    background-color: variables.$white;
    outline: none;

    .fa {
      color: variables.$brand-secondary;
    }
  }

  &.active {
    background-color: variables.$gray-5;
  }
}

.btn-priority.btn {
  @extend %candidacy-action-button;

  .fa + span {
    margin-left: 4px;
  }

  .icon {
    color: variables.$brand-secondary;
    display: inline-block;
    padding-right: 5px;
    position: relative;
    top: 2px;
  }
}

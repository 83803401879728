@use '@thrivetrm/ui/stylesheets/variables';

// Form Overrides
// ----------------------------------

// Scope the non-specific selectors below to legacy bootstrap
// forms to prevent the UI component styles from being clobbered
.simple_form,
.form {
  fieldset h2 {
    padding-top: 0.5em;
  }

  label {
    color: variables.$input-label-color;
    display: inline-block;
    font-size: variables.$input-label-font-size;
    font-weight: variables.$input-label-font-weight;
    margin-bottom: variables.$space-4;
  }
  .checkbox {
    label {
      display: inline-block;
      font-weight: variables.$font-weight-normal;
    }
  }
  .radio {
    label {
      display: block;
      font-weight: variables.$font-weight-normal;
    }
    .radio-inline {
      display: inline-block;
    }
  }
  .radio.inline,
  .checkbox.inline {
    label {
      display: inline-block;
    }
  }
}

.form-group {
  margin-bottom: variables.$space-16;
}

.form-group.user_current_password {
  margin-bottom: 4px;
}

.form-inline {
  .checkbox input[type='checkbox'],
  .radio input[type='radio'] {
    margin-left: 10px;
  }
  .form-group {
    label {
      margin-right: 5px;
    }
    margin-right: 5px;
  }
}

.form-control,
.form-control:focus {
  box-shadow: inset variables.$input-box-shadow;
}

.input-group-addon {
  border-radius: variables.$input-border-radius;
}

.help-block {
  color: variables.$gray-40;
  font-size: 0.8em;
}

.help-block-inline {
  display: inline-block;
  font-weight: variables.$font-weight-bold;
  margin: 0 1em;
}

// Form Fieldset Panel
// ----------------------------------------------------------
.panel-fieldset {
  border-radius: 0;
  box-shadow: none;

  .panel-heading {
    padding-bottom: 0;
    padding-top: 20px;
  }

  // .inline no longer seems to be defined in simple_form :-(
  // https://github.com/plataformatec/simple_form/issues/649
  .inline {
    display: inline;
    margin-left: 20px;

    &:first-child {
      margin-left: 0;
    }
  }

  .form-static-value {
    display: block;
    padding: 6px 12px;
  }
}

.form-group.date {
  label {
    display: block;
  }

  select {
    display: inline-block;
    width: 45%;
  }
}

.form-date-picker {
  position: relative;
}

// Form sections
// ----------------------------------------------------------
.form-actions,
.form-section {
  margin-bottom: 1em;
  margin-top: 1em;
}

.form-actions {
  display: flex;
  justify-content: flex-end;
}

// Simple form Nested Fields
// ----------------------------------------------------------
.remove_nested_fields {
  position: absolute;
  top: 1.6em;

  &:before {
    font-size: 1.1em;
  }
}

.fields {
  position: relative;

  + .fields {
    border-top: $default-border;
    margin-top: 0.5em;
    padding-top: 1.5em;

    .remove_nested_fields {
      top: 3.1em;
    }
  }
}

%no-fields-divider {
  .fields + .fields {
    border: 0;
    margin-top: 0;
    padding-top: 0;
  }
}

#search-team {
  label {
    display: block;
  }
  .add_nested_fields {
    display: block;
    margin-bottom: 1em;
    width: fit-content;
  }
  .fields + .fields {
    margin-top: 0px;
    padding-top: 0px;

    .remove_nested_fields {
      top: 1.65em;
    }
  }
}

.radio,
.checkbox {
  line-height: 1.75;
  label {
    cursor: pointer;
    font-weight: variables.$font-weight-normal;
  }
}

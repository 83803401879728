.formgroup-with-label-addon {
  position: relative;

  .formgroup-label-addon {
    position: absolute;
    right: 0;

    // This is to account for the "margin-bottom: 1px" we override on labels in _forms.scss
    top: -1px;

    .radio,
    .checkbox {
      margin-bottom: 0;
      margin-top: 0;
    }
  }
}

@use '@thrivetrm/ui/stylesheets/variables';

.pagination-container {
  margin: variables.$space-24 0;
  text-align: right;
}

.pagination {
  > li:first-child > a,
  > li:first-child > span > li:last-child > a,
  > li:last-child > span,
  > li > a,
  > li > span {
    // The default styles set the first-child and last-child border radii using
    // `border-<top/bottom>-<left/right>-radius` because normally the entire pagination
    // container is rendered as one with it's outer edges rounded, while we are making distinct
    // rounded buttons:
    //   bootstap:  [ Prev | 1 | 2 | Next ]
    //   us:        (Prev) (1) (2) (Next)
    // So the !important is needed to override the first-child and last-child rules for that.
    border-radius: $pager-border-radius !important;
    margin-left: 3px;
    margin-right: 3px;
    min-width: 2em;
    padding: 3px 6px;
    text-align: center;
  }
}

.go-to-page {
  display: inline;
  .form-control {
    height: 25px;
  }
}

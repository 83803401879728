@use '@thrivetrm/ui/stylesheets/variables';

.btn-active-status {
  cursor: pointer;
  &.active {
    color: rgba(variables.$gray-40, 0.5);
    &:hover {
      color: variables.$gray-40;
    }
  }
  &.inactive {
    color: variables.$gray-50;
  }
}

@use '@thrivetrm/ui/stylesheets/variables';

.editable-section {
  // Hide the toggle button until hover
  .editable-section-toggle {
    color: variables.$brand-secondary;
    margin-left: 5px;
    margin-top: -2px;
    padding: 0 5px;

    & > .fa-pencil {
      opacity: 0.5;
    }

    & + .editable-section-toggle {
      margin-left: 0;
    }
  }

  // Show the edit button when hovering or when editing
  &:hover .editable-section-toggle,
  &:focus .editable-section-toggle {
    & > .fa-pencil {
      opacity: 1;
    }
  }
}

.editable-section-list {
  & > .editable-section > .editable-section-header {
    font-size: 14px;
    margin-bottom: 0;
  }

  .formatted_description {
    margin-top: 10px;
  }

  & > .editable-section:first-child > .editable-section-header {
    // Remove the margin from the top of the first item in the list.
    margin-top: 0;
  }
}

@use '@thrivetrm/ui/stylesheets/variables';

.global-nav {
  align-items: center;
  background-color: variables.$white;
  box-shadow: 0 variables.$space-2 variables.$space-4 0
    rgba(variables.$black-shadow, 0.1);
  display: flex;
  justify-content: space-between;
  padding-left: variables.$space-24;
  padding-right: variables.$space-24;
  position: sticky;
  top: 0;
  z-index: $zindex-navbar-fixed;
  &__actions,
  &__nav-list {
    align-items: center;
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  &__logo {
    height: 36px;
    margin-bottom: 6px;
    margin-right: variables.$space-8;
    margin-top: 6px;
    object-fit: fill;
  }
  &__nav-list-item {
    border-radius: variables.$border-radius-base;
    margin-left: variables.$space-8;
    padding: variables.$space-4 variables.$space-8;
    &.active {
      background-color: variables.$gray-20;
      .global-nav__nav-link {
        color: variables.$brand-primary;
        font-weight: variables.$font-weight-bold;
      }
    }
    .global-nav__nav-link:focus {
      color: variables.$gray-50;
    }
    &:focus,
    &:active,
    &:hover {
      background-color: variables.$gray-10;
    }
  }
  &__nav-link,
  &__nav-link:hover {
    color: variables.$gray-50;
    line-height: variables.$space-16;
  }
  &__contact-reviews-badge,
  &__notifications-badge {
    align-items: center;
    background-color: variables.$brand-red;
    border-radius: 14px;
    color: variables.$white;
    display: flex;
    font-size: 10px;
    height: 14px;
    justify-content: center;
    min-width: 14px;
    padding-left: 3px;
    padding-right: 3px;
    position: absolute;
    right: -5px;
    top: -5px;
  }
  &__notifications-badge-appendage {
    margin-left: 1px;
  }
  &__search {
    align-items: center;
    border: 1px solid variables.$gray-20;
    border-radius: variables.$border-radius-base;
    box-shadow: inset 0 1px 3px 0 rgba(variables.$black-shadow, 0.2);
    display: flex;
    height: 30px;
    margin: 0;
    margin-right: variables.$space-4;
    position: relative;
    white-space: nowrap;
    word-wrap: no-wrap;
  }
  &__searchInput.form-control {
    background: transparent;
    border: none;
    border-radius: 0;
    box-shadow: none;
    height: variables.$input-height-small;
    max-width: 360px;
    min-width: 258px;
    padding-left: 26px;
    padding-right: variables.$space-8;
  }
  &__searchIcon {
    color: variables.$gray-40;
    left: 8px;
    pointer-events: none;
    position: absolute;
    top: 7px;
  }

  &__action-button {
    align-items: center;
    border: solid 1px variables.$gray-20;
    border-radius: variables.$border-radius-base;
    box-shadow: 0 1px 2px 0 rgba(variables.$black-shadow, 0.1);
    color: variables.$gray-40;
    display: flex;
    height: 30px;
    justify-content: center;
    position: relative;
    width: 30px;
    i {
      color: variables.$gray-40;
      &:hover {
        color: variables.$brand-secondary;
      }
    }
  }
}

.global-nav__withQVP {
  // updating the z-index of the global nav-bar when the quick view panel is open,
  // so the user can access the notification dropdown and user menu
  z-index: variables.$z-index-side-panel + 1;
}

// Global Search Autocomplete
.global-nav__professional-networks {
  display: none;
}

// Global Search Autocomplete
//
// Some of these CSS classes come from the (devbridge) jQuery-Autocomplete library

.autocomplete-suggestions,
.autocomplete-hint {
  background: variables.$white;
  border: solid 1px variables.$gray-20;
  border-radius: variables.$border-radius-base;
  box-shadow: 0 4px 7px 0 rgba(variables.$black-shadow, 0.12);
  left: variables.$space-12;
  overflow: auto;
  padding: variables.$space-4 variables.$space-16;
  top: calc(100% - 7px);

  .autocomplete-no-suggestion {
    margin-bottom: 6px;
  }

  .autocomplete-suggestion {
    word-break: break-word;
  }

  .autocomplete-suggestion,
  .autocomplete-no-suggestion,
  .global-nav__autocomplete-other {
    display: block;
    margin: 0 auto;
    padding: 4px 18px;
    text-align: left;
    &:hover {
      background-color: variables.$gray-10;
    }
  }

  .autocomplete-selected,
  .global-nav__autocomplete-other:hover {
    background: variables.$gray-5;
    border-radius: variables.$border-radius-base;
    color: variables.$gray-50;
    cursor: pointer;
  }

  .autocomplete-group {
    align-items: center;
    border-bottom: 1px solid variables.$gray-30;
    display: flex;
    height: 30px;
    margin-bottom: variables.$space-4;
    margin-top: variables.$space-4;
    position: relative;

    strong {
      color: variables.$gray-60;
      font-family: variables.$font-family-sans-serif;
      font-size: variables.$font-size-small;
      font-weight: variables.$font-weight-bold;
      text-transform: capitalize;
    }

    .fa {
      color: variables.$gray-40;
      margin-right: variables.$space-4;
    }
  }

  .global-nav__view-all-results {
    color: variables.$brand-primary;
    font-size: variables.$font-size-small;
    position: absolute;
    right: 0;
    text-decoration: underline;
    &:hover {
      cursor: pointer;
    }
  }

  .global-nav__professional-networks {
    display: block;
    padding: 0;

    a {
      color: variables.$gray-50;
      display: block;
      &:hover {
        background-color: variables.$gray-10;
      }
    }
  }

  .global-nav__linkedin-icon {
    color: variables.$brand-secondary;
    height: 14px;
    margin-right: 7px;
    width: 14px;
  }

  .global-nav__crunchbase-icon {
    background-color: variables.$brand-secondary;
    border-radius: 2px;
    color: variables.$white;
    font-size: 9px;
    font-style: normal;
    height: 14px;
    margin-right: 7px;
    text-align: center;
    vertical-align: middle;
    width: 14px;
  }

  .global-nav__github-icon {
    color: variables.$brand-secondary;
    height: 14px;
    margin-right: 7px;
    width: 14px;
  }
}

// The Main Nav
//

.navbar-brand {
  padding: 9px 9px 9px 0 !important;

  img {
    height: 30px;
  }
}

.nav-tabs.nav-main {
  @include transition;
  border-bottom: none;
  display: block;
  width: 100%;

  > li {
    display: inline-block;
    float: none;
    text-align: center;
    vertical-align: middle;

    &.nav-logo-item {
      padding-right: 40px;
    }

    a.navbar-link,
    a.navbar-link:hover,
    a.navbar-link:focus,
    > a,
    > a:hover,
    > a:focus {
      background-color: transparent;
      border: none;
      border-radius: 0;
      color: variables.$text-color;
      display: inline-block;
      font-size: 1em;
      height: $navbar-height;
      line-height: 1;
      margin: 0;
      outline: none;
      padding: 17px 10px;
      text-transform: none;
      transition: all 150ms ease-in;
      vertical-align: middle;

      // Logo
      &.navbar-brand,
      &.navbar-brand:hover {
        padding: 11px 10px 11px 0;
      }

      &.indicator {
        margin-right: 0.5em;
        padding-right: 2px;
        position: relative;

        .badge {
          background-color: variables.$brand-danger;
          color: variables.$white;
          font-size: 0.75em;
          font-weight: variables.$font-weight-normal;
          min-width: 1.5em;
          padding: 3px;
          position: absolute;
          right: -2px;
          text-align: center;
          top: 8px;
        }
      }
    }
  }

  > .active {
    > a,
    > a:hover,
    > a:focus {
      color: variables.$brand-primary;
    }
  }

  .dropdown-toggle {
    .icon {
      margin: 0;
    }
  }

  .global-search {
    width: 420px;
    form {
      .form-control {
        display: inline-block;
        width: 360px;
      }

      i {
        display: inline-block;
        margin-left: 10px;
        padding-left: 1px; // spinner correction hack
      }
    }
  }
}

.header-container {
  padding-top: 1px;
}

.navbar-form {
  margin-left: 0;
  margin-right: 0;
  padding-bottom: 0;
  padding-top: 0;
}

@media (max-width: $screen-md) {
  .nav-tabs.nav-main > li {
    > a,
    > a:hover,
    > a:focus {
      padding: 17px 3px;
    }

    &.nav-logo-item {
      padding-right: 0;
    }
  }
}

@use '@thrivetrm/ui/stylesheets/variables';

.notes--note-view {
  .notes--note-view-date {
    time {
      font-size: 10px;
      font-style: normal;
    }
    display: inline-block;
    width: 55%;
  }

  .notes--note-view-leadership {
    display: inline-block;
    font-size: 10px;
    text-align: right;
    width: 45%;
  }

  .notes--note-view-creator {
    font-weight: variables.$font-weight-bold;
  }

  .notes--note-view-pinned {
    color: variables.$brand-danger;
    margin-right: variables.$space-8;
  }
}

@use '@thrivetrm/ui/stylesheets/variables';

.CandidacyListItemIconStrip {
  align-items: center;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  text-align: center;

  .CandidacyHidenToggleButton__button {
    padding: 0.3em 0;
  }

  .CandidacyHiddenToggleButton__icon {
    color: variables.$brand-secondary;
    font-weight: variables.$font-weight-bold;
  }

  .CandidacyHiddenToggleButton--hidden .CandidacyHiddenToggleButton__icon {
    color: variables.$gray-50;
  }
}

.CandidacyListItemSmallSectionDetails__iconStrip {
  .CandidacyHidenToggleButton__button {
    margin-top: -0.3em;
  }

  .CandidacyTargetCompanyCount {
    margin-top: 0.4em;
  }
}

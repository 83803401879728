label + .CheckboxField.checkbox {
  margin-bottom: 15px;
  // Bootstrap adds this rule to `.checkbox` and `.radio` classes
  //
  //   margin-top: 10px;
  //   margin-bottom: 10px;
  //
  // But when we show a label above a checkbox, it adds too much vertical space
  // between the label and the checkbox. So this reduces that space,
  // but also adjusts the bottom margin in kind to maintain the same height.
  margin-top: 5px;
}

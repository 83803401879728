@use '@thrivetrm/ui/stylesheets/variables';

.EntitySelect {
  .Select-option.is-disabled {
    background: variables.$white;
    border-bottom: 1px solid variables.$gray-20;
    border-top: 1px solid variables.$gray-20;
    color: variables.$gray-60;
    font-weight: variables.$font-weight-bold;
    margin-left: 6px;
    padding-left: 4px;

    & + .is-disabled {
      border-top: none;
    }
  }
}

.EntitySelect--isNotFiltering {
  .Select-option.is-disabled {
    position: sticky;
    top: 0;
  }
}

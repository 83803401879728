@use '@thrivetrm/ui/stylesheets/variables';

.wizard-steps {
  list-style: decimal inside;
  padding-left: 0;

  li {
    color: variables.$gray-50;
    float: left;
    font-size: 0.9em;
    font-weight: variables.$font-weight-bold;
    margin: 0.6em 1em;

    &.active {
      color: variables.$brand-primary;
    }
  }

  em {
    color: variables.$gray-20;
    display: block;
    font-size: 0.7em;
    font-style: normal;
    font-weight: variables.$font-weight-normal;
    text-align: center;
  }
}

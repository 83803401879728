@use '@thrivetrm/ui/stylesheets/variables';

.SearchStageListMenu {
  list-style-type: none;
  padding: 0;
}

.SearchStageListMenu__separator {
  border-top: 1px solid variables.$gray-30;
  margin-bottom: 0.25em;
  margin-top: 0.25em;
}

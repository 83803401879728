@use '@thrivetrm/ui/stylesheets/variables';

.filters {
  margin-top: 1em;
}

.table-filter-forms {
  align-content: center;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin-bottom: 1.5em;

  .advanced-search-simple-search-form {
    margin-top: 1.5em;
    padding: 0;

    .input-group {
      display: table;
    }
  }

  .table-filter-forms__actions {
    display: flex;
    margin-top: 1.5em;
  }

  .table-filter-forms__configure-columns {
    display: flex;
    font-size: variables.$font-size-heading-large;
    margin-left: variables.$space-12;

    button {
      background: none;
      border: none;
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.panel-filter {
  @include panel-variant(
    $panel-default-border,
    $panel-default-text,
    variables.$gray-5,
    variables.$gray-20
  );
  margin-bottom: 8px;

  .panel-heading {
    background-color: variables.$gray-20;
    cursor: pointer;

    &[aria-expanded='true'] .icon {
      margin-top: -2px;
      &:before {
        @extend .icon-collapse;
      }
    }

    .icon {
      color: variables.$brand-secondary;
      margin-top: 2px;
      @include transition;
    }

    label {
      margin-bottom: 0;
    }
  }

  // Keep bootstrap collapse from expanding too much and being jumpy
  .panel-collapse[aria-expanded='false'] .chosen-drop {
    height: 0;
  }

  .panel-body {
    padding: 0 variables.$space-12;
  }

  .form-group {
    margin: 15px 0;
  }
}

#filter-buttons {
  margin-top: 1em;
}

.toolbar {
  .form-group {
    select.sort-by {
      border: 1px solid variables.$gray-20;
      color: variables.$gray-50;
      margin-left: 14px;
    }
  }
}

.panel {
  &.panel-search {
    background-color: transparent;
    box-shadow: none;
  }
}

// bootstrap friendly design for jquery-ui autocomplete
// updated version of https://gist.github.com/daz/2168334
.ui-autocomplete {
  background-clip: padding-box;
  background-color: variables.$white;
  border: 1px solid rgba(variables.$black-shadow, 0.2);
  border-radius: 5px;
  box-shadow: 0 5px 10px rgba(variables.$black-shadow, 0.2);
  display: none;
  float: left;
  left: 0;
  list-style: none;
  margin: 2px 0 0;
  min-width: 160px;
  padding: 4px 0;
  position: absolute;
  top: 100%;
  z-index: variables.$z-index-dropdown;

  .ui-menu-item {
    clear: both;
    color: #555555;
    display: block;
    font-weight: variables.$font-weight-normal;
    line-height: 18px;
    padding: 3px 15px;
    white-space: nowrap;
  }

  .ui-state-focus {
    background-color: #0088cc;
    background-image: none;
    border-radius: 0;
    color: variables.$white;
    text-decoration: none;
  }
}

.ui-helper-hidden-accessible {
  left: -9999px;
  position: absolute;
}

.ui-autocomplete-loading {
  background: variables.$white url('~assets/images/ui-anim_basic_16x16.gif')
    right center no-repeat;
}

.input-group #filters_keyword,
.input-group #contact_network_filter_keyword {
  width: 270px;
}

.current-past-filter {
  margin-top: -1 * variables.$space-12;

  .form-group {
    margin: 0;

    .checkbox {
      margin: 0;
    }
  }
}

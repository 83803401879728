.CompanyLogo--thumbnail {
  .CompanyLogo__image {
    max-height: 50px;
    max-width: 150px;

    // In the case of a missing logo, prevent the alt-text from overflowing the container.
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.CompanyLogo__placeholder {
  font-size: 4em;
}

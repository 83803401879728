@use '@thrivetrm/ui/stylesheets/variables';

.SearchPageSidebarPopover {
  font-size: 0.9em;

  h2 {
    align-items: flex-end;
    border-bottom: 1px solid variables.$gray-20;
    display: flex;
    height: $search-sidebar-header-height;
    margin-top: 0;
    padding-bottom: variables.$space-8;
  }
}

@use '@thrivetrm/ui/stylesheets/variables';

.CandidacySearchSidebarHeader {
  border-bottom: 1px solid variables.$gray-20;

  .ContactPosition {
    color: variables.$gray-50;
  }

  .PositionCompanyName {
    font-weight: variables.$font-weight-normal;
    margin-top: 0.25em;
  }

  .CandidacyActionButtons {
    display: inline-block;
    width: auto;
  }

  .CandidacyJobApplicationIndicator {
    padding-left: 1em;
  }

  .CandidacySearchSidebarHeader__titles {
    padding-left: 0.5em;
  }

  .CandidacySearchSidebarHeader__titles .companies--company-name {
    font-weight: variables.$font-weight-normal;
  }

  .CandidacySearchSidebarHeader__ContactLinks {
    float: right;
    font-size: 1.1em;

    .CandidacySearchSidebarHeader__ContactProfileLink {
      color: variables.$gray-50;
      font-size: 1.2em;
      margin-left: 0;
    }
  }

  .CandidacySearchSidebarHeader__ContactLinks > a {
    margin-left: 0.25em;
    margin-top: 0.5em;
    padding: 0.25em;
  }

  .CandidacyStageDropdown {
    justify-content: left;
  }
}

.CandidacySearchSidebarHeader__summaryDownloadButton {
  display: flex;
  margin-left: auto;
  padding-top: 0;
}

.CandidacySearchSidebarHeader .ContactPosition .TargetCompanyIndicatorIcon {
  margin-right: 0.25em;
}

.CandidacySearchSidebarHeader__top,
.CandidacySearchSidebarHeader__bottom {
  display: flex;
  margin-bottom: 0.5em;
}

.CandidacySearchSidebarHeader__top > .ContactAvatar {
  // Prevent the avatar "column" from being shrunk which will  squish it!
  flex-shrink: 0;
}

.CandidacySearchSidebarHeader__bottom {
  justify-content: space-between;
}

@use '@thrivetrm/ui/stylesheets/variables';

#current-searches-body {
  .toolbar {
    margin: 0;

    .edit-dashboard {
      width: 100%;
    }
  }
}

.searches-by-company {
  border-radius: variables.$border-radius-base;
  margin-top: variables.$space-16;

  .searches-header {
    overflow: auto;
    padding: 0 12px;

    .company-name {
      color: variables.$gray-50;
      display: block;
      float: left;
      font-size: variables.$font-size-base;
      font-weight: variables.$font-weight-bold;
      margin: 12px 8px;
    }

    .view-more-less {
      float: right;
      font-size: variables.$font-size-small;
      font-weight: variables.$font-weight-bold;
      margin: 16px 8px;
      text-transform: uppercase;
    }

    .badge-count {
      background-color: variables.$brand-dark-blue;
      border-radius: variables.$space-16;
      color: variables.$white;
      float: right;
      font-size: variables.$font-size-base;
      height: variables.$space-24;
      line-height: variables.$space-24;
      margin: 10px;
      min-width: variables.$space-24;
      padding: 0 variables.$space-4;
      text-align: center;
    }

    .avatar {
      float: left;

      img {
        max-height: 40px;
      }
    }
  }

  .table-dashboard {
    margin: 0;

    tr:nth-child(even) > td {
      background-color: variables.$gray-5;
    }

    thead {
      background-color: variables.$gray-5;
      border-top: 1px solid variables.$gray-30;
    }

    thead tr th {
      border-bottom: 1px solid variables.$gray-30;
      font-size: variables.$font-size-small;
    }

    th a {
      color: variables.$gray-60;
    }

    tbody tr td.search-firm {
      padding: 6px 12px;
    }

    tbody tr td.search-firm .recruiter {
      font-size: variables.$font-size-small;
    }

    th.job-title,
    td.job-title {
      width: 20%;
    }
    th.job-function,
    td.job-function {
      width: 15%;
    }
    th.open-duration,
    td.open-duration {
      width: 13%;
    }
    th.search-firm,
    td.search-firm {
      width: 17%;
    }
    th.lead-internal-member,
    td.lead-internal-member {
      width: 15%;
    }
    th.search-status,
    td.search-status {
      width: 10%;
    }
    th.num-candidacies,
    td.num-candidacies {
      width: 10%;
    }
  }
}

.below-fold {
  display: none;
}

.show-more .below-fold {
  display: table-row;
}

.fa-th-large {
  transform: translateY(1px);
}

.dashboard-buttons {
  font-size: variables.$space-24;
  margin-bottom: variables.$space-8;
  margin-right: 1px;

  & > .btn {
    border: 1px solid variables.$gray-30;
    color: variables.$gray-50;
    font-size: 1em;
    padding: variables.$space-4 variables.$space-8;

    &:hover:not(.active) {
      background-color: variables.$gray-5;
      border-color: variables.$gray-40;
    }

    &.active {
      background-color: variables.$white;
      color: variables.$brand-secondary;
    }
  }
}

@use '@thrivetrm/ui/stylesheets/variables';

.LastUpdatedDate {
  color: variables.$gray-40;

  .LastUpdatedDate__label {
    font-size: 11px;
  }

  #LastUpdatedDate__icon {
    font-size: variables.$font-size-base;
    margin-right: 8px;
  }
}

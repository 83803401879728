.CompanyFundingRounds > .form-toggle {
  // Float the "add funding round" button to the right of the "total funding" value
  float: right;
}

.CompanyFundingRounds__total {
  // Float to the left of the (above) form-toggle.
  float: left;

  // Match the vertical padding of the button so that they appear to be on the same line.
  padding: 0.65em 0;
}

.CompanyFundingRounds > .FundingRoundForm {
  // Since we're floating the totals, we need to clear this form so it starts a new
  // block.
  clear: both;
  // Add some below the form so it doesn't run directly into the table.
  padding-bottom: 3em;
}

.CompanyFundingRounds .TableView {
  clear: both;
}

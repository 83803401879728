@use '@thrivetrm/ui/stylesheets/variables';

.ConnectionsButtonOverlay {
  position: relative;
  &__buttonWrapper {
    align-items: center;
    border-radius: 50%;
    bottom: 0;
    display: flex;
    height: variables.$space-16;
    justify-content: center;
    overflow: hidden;
    position: absolute;
    right: 0;
    width: variables.$space-16;
  }
}

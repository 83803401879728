@use '@thrivetrm/ui/stylesheets/variables';

.CompensationField {
  &__legal-disclaimer {
    color: variables.$gray-60;
  }

  &__bonusType {
    .radio {
      margin-top: 0;
    }
  }
}

@use '@thrivetrm/ui/stylesheets/variables';

.end_date {
  display: none;
}

.search_confidential {
  margin-top: 15px;
  label,
  input {
    margin-bottom: 0;
  }
}

.data-transfer-checkbox {
  margin-bottom: 0.5em;
}

.multiple-placement-checkbox {
  margin-top: 7px;
  padding-left: 0;
}

.search-details-form-assessment-attributes {
  @extend %no-fields-divider;

  .description {
    font-size: 12px;
    padding-bottom: 1em;
  }

  #generate-assessment-criteria-descriptions {
    font-size: 17px;
    padding-left: 14px;
    position: relative;
    top: 2px;
  }

  .fa-plus-circle {
    color: variables.$brand-primary;
    font-size: 1.3em;
    left: 5px;
    position: relative;
    top: 1px;
  }

  .fa-info-circle {
    color: variables.$brand-secondary;
    padding-left: 8px;
    &:hover {
      color: variables.$brand-secondary-light;
    }
  }
}

.search-details-form-investors {
  > .row {
    margin: 1em 0;
  }
  .avatar {
    height: auto;
    img {
      overflow: hidden;
    }
  }
}

.search-details-form-documents {
  @extend %no-fields-divider;

  .form-group {
    margin-bottom: 1em;
  }

  .search_documents_use_external_url .radio {
    margin-right: 4em;
  }

  label.url {
    text-transform: uppercase;
  }
}

.notice-saved {
  color: variables.$green;
  display: none;
  padding-left: 5px;
}

.handle {
  color: variables.$gray-40;
  display: inline-block;
  padding-left: 10px;
  padding-right: 20px;
}

.search_assessment_assessment_option,
.talent_pool_assessment_assessment_option {
  display: inline-block;
  width: 400px;
}

.offer-form {
  .btn-success,
  .btn-secondary {
    // In cases where the "success" and "secondary" buttons are stacked
    // vertically, it looks strange when they are different widths, so this attempts
    // to make them the same width, if possible.
    min-width: 10em;
  }
  // Move the margin to the right of `btn-success` instead of the left of `btn-secondary` so that
  // when they stack vertically `btn-secondary` isn't indented.
  .btn-success {
    margin-right: 1em;
  }
  .btn-secondary {
    margin-left: 0;
  }
}

.client-satisfaction-label,
.best-practices-label {
  color: #5c6a6a;
  font-size: 12px;
  font-weight: variables.$font-weight-bold;
  margin-bottom: 12px;
}

.client-satisfaction-numbers {
  line-height: 2.3;
}

a#create-user-link {
  float: right;
}

#create-user-modal {
  z-index: $z-index-create-user-modal;
}

.copy-link-button {
  font-size: 12px;
  padding-top: 8px;

  i {
    font-size: 14px;
    margin-right: 5px;
  }
}

.between-buttons {
  display: inline-block;
  padding: 0 10px;
}

// This prevents content (radio buttons and labels) in the talent pool and
// search details tabs from getting cut off on the left side.

.talent_pools,
.searches {
  .tab-panel {
    padding-left: 0.1em;
  }
}

/** height for inputs on the search details page so columns align correctly */

.search-details-form-search-details,
.search-details-form-team-members,
.searchTeamForm {
  .form-group {
    min-height: 54px;
  }

  .search_confidential,
  .search_status {
    min-height: 0;
  }

  .compensation-preference-select .search_use_desired_compensation {
    margin-left: 1px;
    min-height: 24px;
  }
}

.search-payment-milestones {
  margin-bottom: variables.$space-16;
  margin-top: variables.$space-16;

  &__header {
    align-items: flex-end;
    font-size: 12px;
  }

  &__label {
    font-size: 12px;
  }

  .form-control {
    min-height: 34px;
  }
  .remove_nested_fields {
    display: flex;
    justify-content: flex-end;
    position: static;
  }

  .checkbox {
    label {
      display: flex;
      justify-content: flex-start;
      padding: 0;
      input {
        margin: 0;
      }
    }
  }
  .fields + .fields {
    margin-top: 0px;
    padding-top: 0px;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
}

@use '@thrivetrm/ui/stylesheets/variables';

.AssessmentView__added-by,
.AssessmentView__ratings {
  font-size: 14px;
}

.title.AssessmentView__date {
  time {
    font-size: 14px;
    font-style: normal;
    font-weight: variables.$font-weight-normal;
  }
}

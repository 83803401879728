@use '@thrivetrm/ui/stylesheets/variables';

.basic-title {
  margin-bottom: 0;
}

.basic-content-container {
  padding: variables.$space-16;
  &:nth-child(2n + 1) {
    padding-left: 0;
    padding-right: 0;
  }
}

.basic-content-accent-container {
  background-color: variables.$gray-5;
  border: 1px solid variables.$gray-30;
  border-radius: 10px;
  padding: variables.$space-16;
}

.link-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  &.link-label {
    display: inline-block;
    width: 82%;
    + .label {
      vertical-align: super;
    }
  }
  &.link-icon {
    display: inline-block;
    vertical-align: text-bottom;
    width: 91%;
  }

  .icon + & {
    padding-left: 5px;
  }
}

.basic-content {
  padding-top: variables.$space-16;
  &:first-child {
    padding-top: 0;
  }
  a + .label {
    padding-left: 5px;
  }
}

.radio.inline {
  display: inline;
  padding-right: 10px;
}

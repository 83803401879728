@use '@thrivetrm/ui/stylesheets/variables';

.FundingRoundTableColumn--amount {
  // Right align the "Amount" column
  text-align: right;
}

.FundingRoundTableColumn--round {
  width: 10%;
}

.FundingRoundTableColumn--edit-delete {
  min-width: variables.$space-64;
  text-align: right;
}
.FundingRoundFormModal--isEditing {
  .MultiValueInput__multi-value {
    width: 100%;
  }
}

@use '@thrivetrm/ui/stylesheets/variables';

.highlight-enter {
  background-color: lighten(variables.$brand-success, 25%) !important;
}

.highlight-enter.highlight-enter-active {
  background-color: initial !important;
  transition: all 2000ms ease-out;
}

.highlight-exit,
.highlight-leave {
  background-color: lighten(variables.$brand-danger, 25%) !important;
  opacity: 1;
}

.highlight-exit.highlight-exit-active,
.highlight-leave.highlight-leave-active {
  background-color: initial !important;
  opacity: 0.01;
  transition: all 2000ms ease-out;
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade-in-out-transition-enter {
  animation: fade-in 1000ms ease-out 2000ms both;
}

.fade-in-out-transition-leave {
  animation: fade-out 1000ms ease-out 2000ms both;
}

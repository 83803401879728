@use '@thrivetrm/ui/stylesheets/variables';

.ContactPositionListField__list {
  @include clearfix();
}

.ContactPositionListField__list-item {
  border: 2px solid variables.$gray-20;
  border-radius: variables.$border-radius-base;
  margin-bottom: variables.$space-32;
}

.ContactPositionListField__list-item:last-child {
  margin-bottom: 0;
}

.DuplicatePositions {
  border-top: 1px solid variables.$gray-20;
  margin-bottom: variables.$space-32;
}

.DuplicatePositionsList {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

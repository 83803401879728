@use '@thrivetrm/ui/stylesheets/variables';

.SidebarModal > .modal-dialog {
  bottom: 0;
  margin: 0;
  // Stick our modal to the right side of the screen (note the lack of a `left` property here)
  position: absolute;
  right: 0;
  top: 0;

  & > .modal-content {
    bottom: 0;

    // Adjust the box-shadow to offset to the left for sidebar modals.
    box-shadow: -10px 0px 20px rgba(variables.$black-shadow, 0.2);

    // Use flexbox within the dialog so that we can stick the header and footer to the top
    // and bottom, respectively, and expand the body to take up the rest of the space.
    display: flex;
    flex-direction: column;
    left: 0;
    margin: 0;
    // Position the modal content to fill the entire dialog
    position: absolute;
    right: 0;
    top: 0;
    & > .modal-body {
      flex: 1;
      overflow: auto;
    }
  }

  & > .modal-footer {
    padding: 35px;
  }
}

// Animate in from the right, instead of from the top
.SidebarModal.modal.fade > .modal-dialog {
  transform: translate(100%, 0);
}

.SidebarModal.modal.fade.in > .modal-dialog {
  transform: translate(0, 0);
}

.SidebarModal .modal-footer {
  padding: 35px;
}

@media (max-width: $screen-sm) {
  // When on a small screen, expand the modal to take up the entire viewport.
  .SidebarModal > .modal-dialog {
    width: 100%;
  }
}

.contact-header {
  display: flex;

  .contact-header-aside {
    padding-right: 0.5em;
    text-align: center;
  }

  .contact-avatar-uploader-button .contact-avatar {
    margin-bottom: 0;
  }

  .contact-avatar {
    margin-bottom: 0.25em;
  }

  .contact-title {
    flex: 1;
  }

  .PrimaryPositionModalButton {
    display: inline-block;
  }
}

@use '@thrivetrm/ui/stylesheets/variables';

body.talent_pools #content {
  margin-top: 0;
}

.talent-pools--dashboard {
  display: flex;
  min-height: 90vh;

  .talent-pools--dashboard-sidebar,
  .talent-pools--dashboard-view {
    // ugh, wtf. this madness has to stop! we gotta fix these damn styles.
    // We can't do this on `.talent-pools--dashboard` because it screws up
    // the filter toolbar because of the way bootstrap is styling the
    // .form-control using exact pixels.
    font-size: 0.9em;
  }

  .talent-pools--dashboard-sidebar {
    background-color: variables.$gray-5;
    margin-right: 1em;
    padding-left: 1em;
    padding-right: 1em;
    width: 25%;
  }

  .talent-pools--dashboard-content {
    flex: 1;
    margin-bottom: 1em;
    margin-top: 1em;
  }

  .talent-pools--dashboard-incumbent-header {
    display: flex;
    justify-content: center;
  }
}

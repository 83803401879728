@use '@thrivetrm/ui/stylesheets/variables';

.CandidacySearchTagModalRow {
  &__tableData {
    height: auto;
  }
  &__colorBox {
    border: 1px solid transparent;
    box-shadow: 1px 1px 2px 0px rgb(101 101 101);
    height: variables.$space-24;
    width: variables.$space-24;
  }
  .ColorInput__button {
    height: variables.$space-24;
    width: variables.$space-24;
  }
}

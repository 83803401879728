.TagCheckbox__TagColorSquare {
  // 4px is to match bootstrap's checkbox input margin:
  // https://github.com/twbs/bootstrap/blob/4731b239b1d2969b3e0262fb3e85ff8ce981e058/less/forms.less#L54
  // plus a right value to add a margin between the color square and label text.
  margin: 4px 4px 0 0;
}

.TagCheckbox__SearchTagColorSquare {
  height: 1em;
  width: 1em;
}

.TagCheckbox__loadingIndicator {
  // Add some padding between the loading indicator and the tag label.
  margin-left: 0.25em;
}

@use '@thrivetrm/ui/stylesheets/variables';

.RecommendationSearchPage__filter {
  display: flex;
  flex-wrap: nowrap;
}

.RecommendationSearchPage__filter .recommendations--sort-and-filter-toolbar {
  flex: 1;
}
.RecommendationSearchPage__plusIcon::before {
  color: variables.$brand-primary;
}

.RecommendationSearchPage__container .TabbedHeaderContainer__filters {
  padding-top: 10px;
}

.RecommendationSearchPage__btnMore {
  font-size: 2.3em;
  margin-left: 0.3em;
  margin-top: -2px;
  padding: 0px;
}

@use '@thrivetrm/ui/stylesheets/variables';

.MultiValueInput {
  position: relative;

  &__control {
    align-items: center;
    background-color: variables.$white;
    border-radius: variables.$input-border-radius;
    color: variables.$input-color;
    cursor: default;
    display: flex;
    flex-wrap: wrap;
    font-size: variables.$font-size-base;
    justify-content: space-between;
    min-height: variables.$input-height-base;
    outline: 0 !important;
    position: relative;
    transition: all 100ms;

    &--is-disabled {
      opacity: 0.4;
    }
  }

  &--isInvalid &__control {
    border-color: variables.$brand-red;
  }

  &--shouldRenderAsInput &__control {
    border: 1px solid variables.$input-border-color;
    box-shadow: inset variables.$input-box-shadow;
  }

  &--shouldRenderAsInput &__value-container {
    padding: variables.$space-2 variables.$space-8;
  }

  &__value-container {
    align-items: center;
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    overflow: hidden;
    padding-bottom: variables.$space-2;
    padding-left: 0;
    padding-right: variables.$space-8;
    padding-top: variables.$space-2;
    position: relative;
  }

  &__persistentPlaceholder {
    color: variables.$input-color-placeholder;
  }

  &__placeholder {
    // Although named a placeholder, this renders a bit differently from input
    // placeholders in that there is no border. When using the variables.$input-color-placeholder
    // variable it is very low contrast and inconsistent when mixed on pages that
    // don't use the modern component (e.g. contact profile page)
    color: variables.$gray-50;
    margin-left: variables.$space-2;
    margin-right: variables.$space-2;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  &__label {
    color: variables.$input-label-color;
    display: block;
    font-size: variables.$input-label-font-size;
    font-weight: variables.$input-label-font-weight;
  }

  &__description {
    font-size: variables.$font-size-small;
  }

  &__errorMessage {
    color: variables.$brand-red;
    font-size: variables.$font-size-small;
    margin-top: variables.$space-4;
  }

  &__menu {
    background-color: variables.$white;
    border: 1px solid variables.$gray-20;
    border-radius: variables.$border-radius-large;
    box-shadow: 0 4px 7px 0 rgba(variables.$black-shadow, 0.12);
    padding-bottom: variables.$space-2;
    position: absolute;
    top: calc(100% + variables.$space-4);
    width: 100%;
    z-index: variables.$z-index-dropdown;
  }

  &__menu-list {
    margin-left: variables.$space-8;
    margin-right: variables.$space-8;
    max-height: 300px;
    overflow-y: auto;
    position: relative;
  }

  &__option,
  &__menu-notice {
    align-items: center;
    background-color: transparent;
    border-radius: variables.$border-radius-base;
    color: inherit;
    display: flex;
    font-size: variables.$font-size-base;
    margin: variables.$space-4 variables.$space-2;
    padding-left: 6px;
    padding-right: 6px;
    user-select: none;
  }

  &__option {
    cursor: pointer;

    &--is-focused {
      background-color: variables.$gray-10;
    }
  }
}

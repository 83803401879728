@use '@thrivetrm/ui/stylesheets/variables';

.ContactRatingListItem {
  padding-bottom: 1em;
  padding-top: 1em;
}

.ContactRatingListItem--RatedByMe {
  background-color: variables.$gray-5;
}

.ContactRatingListItem__edit > h3 {
  // Show the "Edit Rating" header in dark gray.
  color: variables.$text-color;
}

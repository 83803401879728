@use '@thrivetrm/ui/stylesheets/variables';

.RecommendationListItemGroupHeader {
  align-items: center;
  border-radius: 0;
  border-top: 1px solid variables.$gray-20;
  color: variables.$brand-secondary;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  text-align: left;
  &:active {
    box-shadow: none;
  }
  &__Name,
  &__ItemCount,
  &__ExpandedIndicator {
    line-height: 1.1;
  }
  &__Name {
    color: variables.$gray-60;
    flex: 1;
    font-size: 1.2em;
    font-weight: variables.$font-weight-bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &__ItemCount,
  &__ExpandedIndicator {
    margin-left: 1em;
  }
  &_ExpandedIndicator {
    color: variables.$brand-secondary;
    font-size: 1.2em;
  }
  &__ItemCount {
    background-color: variables.$brand-primary;
    border-radius: 1em;
    color: variables.$white;
    font-size: 1em;
    margin-left: 0.25em;
    min-width: 2em;
    padding: 0.5em;
    text-align: center;
  }

  &__btnNotAFit {
    background: none;
    border: none;
    border-color: variables.$white;
    outline-color: variables.$white;
    padding-right: 20px;
    width: 100%;
  }
}

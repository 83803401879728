.LoadingIndicator {
  padding: 2em 1em;
  text-align: center;

  .message {
    font-size: 1.5em;
    margin-top: 1em;
  }

  .fa-spin {
    filter: blur(0);
  }
}

@use '@thrivetrm/ui/stylesheets/variables';

.TabbedHeaderTabsInline {
  display: inline-block;
  list-style: none;
  margin: 0;
  padding: 0;
  > li {
    display: inline-block;
    padding-right: 1em;
  }
  a {
    color: variables.$link-color;
    display: inline-block;
  }
  > li.active > a {
    color: variables.$brand-secondary-light;
  }
}

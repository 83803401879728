@use '@thrivetrm/ui/stylesheets/variables';

.tasks-table-col-title {
  .task-status-button {
    .fa {
      color: variables.$brand-primary;
      font-size: 1em;
    }
    &.hovered {
      .fa {
        opacity: 0.3;
      }
    }
  }
}

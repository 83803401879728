@use '@thrivetrm/ui/stylesheets/variables';

.CandidacyListItemSectionCore {
  align-items: stretch;
  display: flex;
}

.CandidacyListItemSectionCore__iconStrip {
  flex: 0 0 45px;
  overflow: hidden;
  padding: 0.5em;
  text-align: center;
}

.CandidacyListItemSectionCore__content {
  align-items: stretch;

  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
}

.CandidacyListItemSectionCore__details {
  flex: 1;
  overflow: hidden;
  padding: 0.5em 0.5em 0px;
}

.CandidacyListItemSectionCore__status {
  overflow: hidden;
  padding: 0px 0.5em 0.5em;
  white-space: nowrap;

  .CandidacyStatusContainer,
  .CandidacyStatusContainer a {
    color: variables.$gray-40;
    font-size: 0.9em;
  }

  .CandidacyStatusContainer a:hover {
    color: variables.$link-hover-color;
  }
}

// The number of bootstrap columns that the sidebar should take up when collapsed
$Sidebar-columns: 5;

// The number of bootstrap columns that the sidebar should take up when expanded
$Sidebar-columns-expanded: 8;

// The number of bootstrap columns that the sidebar's popover should take up
// when the sidebar is collapsed
$SidebarPopover-columns: 6;

// The number of bootstrap columns that the sidebar's popover should take up
// when the sidebar is expanded
$SidebarPopover-columns-expanded: 4;

/**
 * A sidebar layout calculated based on the width of the entire container,
 * and the width of the sidebar component.
 * @param {Number} $containerWidth The width of the entire container
 * @param {Number} $sidebarColumns The number of bootstrap columns the sidebar
 *   should take up
 * @param {Number} $popoverColumns The number of bootstrap columns the
 *   popover should take up..
 */
@mixin sidebar-variant($containerWidth, $sidebarColumns, $popoverColumns) {
  .sidebar-wrapper {
    width: $containerWidth * calc($sidebarColumns / $grid-columns);
  }

  .content-wrapper {
    margin-left: calc(
      $containerWidth * ($sidebarColumns / $grid-columns) -
        ($grid-gutter-width / 2)
    );
    width: $containerWidth *
      calc(($grid-columns - $sidebarColumns) / $grid-columns);
  }

  .Sidebar__popover {
    // Slightly different margin-left, because want the popover to fit right up against
    // the sidebar content without any padding/gutter.
    margin-left: $containerWidth * calc($sidebarColumns / $grid-columns) -
      $grid-gutter-width;
    width: $containerWidth * calc($popoverColumns / $grid-columns);
  }
}

.page-with-sidebar {
  .sidebar-wrapper {
    // To account for the padding. Bootstrap does this for `row` classes,
    // this is for the same reasons, but this component isn't wrapped in a row,
    // and we can't set this on the container (`.page-with-sidebar`) because
    // the margin need to be "auto" so it's centered.
    margin-left: calc($grid-gutter-width / -2);

    // In order for the underlying popover to show over top of the
    // content-wrapper, we need to bump the z-index here.
    z-index: 1;
  }

  .sidebar-wrapper,
  .content-wrapper,
  .Sidebar__popover {
    /** Render above fixed footer */
    bottom: $footer-total-height;
    position: fixed;

    /** Render below the fixed header */
    top: $navbar-height;
  }

  .Sidebar__popover {
    padding-left: calc($grid-gutter-width / 2);
    /** These should mimic the col-* classes from bootstrap */
    padding-right: calc($grid-gutter-width / 2);
  }

  .content-wrapper {
    overflow-x: hidden;
    overflow-y: auto;
  }

  /** Make sure the sidebar fills the entire height */
  .Sidebar {
    bottom: 0;
    left: calc($grid-gutter-width / 2);
    position: absolute;
    right: calc($grid-gutter-width / 2);
    top: 0;
  }

  .Sidebar__content {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  .Sidebar,
  .Sidebar__content,
  .Sidebar__popover {
    overflow-x: hidden;
    overflow-y: auto;
  }

  @include sidebar-variant(
    $container-desktop,
    $Sidebar-columns,
    $SidebarPopover-columns
  );
}

.page-with-sidebar.sidebar-expanded {
  @include sidebar-variant(
    $container-lg,
    $Sidebar-columns-expanded,
    $SidebarPopover-columns-expanded
  );
}

@media (min-width: $screen-lg) {
  /* Larger screen sizes -- allow for larger column widths */
  .page-with-sidebar {
    @include sidebar-variant(
      $container-lg,
      $Sidebar-columns,
      $SidebarPopover-columns
    );
  }

  .page-with-sidebar.sidebar-expanded {
    @include sidebar-variant(
      $container-lg,
      $Sidebar-columns-expanded,
      $SidebarPopover-columns-expanded
    );
  }
}

.JobApplicationIconLinkList {
  margin: 0;
}

.JobApplicationIconLinkList > li {
  display: inline-block;
  margin-left: 0.2em;
  margin-right: 0.2em;
  padding: 0;
}

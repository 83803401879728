.AccountDesc {
  flex-basis: 60%;
}
.AccountToken_box {
  flex-basis: 40%;
}

.TokenDesc {
  word-break: break-all;
}

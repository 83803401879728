@use '@thrivetrm/ui/stylesheets/variables';

.resource-name {
  margin: 0.2em 0 0.1em;
  .referred-by-label,
  .referred-by-name {
    font-size: variables.$font-size-small;
  }
  a.referred-by-name {
    display: inline;
  }
}

.candidacy-title,
.contact-title {
  color: variables.$gray-50;
  font-weight: variables.$font-weight-bold;
}

.candidacy-location {
  color: variables.$gray-40;
  text-align: right;
  .icon {
    color: variables.$brand-primary;
    margin-left: 5px;
  }
}

.table-title {
  + .btn-group {
    padding-bottom: 6px;
    padding-left: 10px;
  }
}

.contact-connection-link.btn {
  background-color: variables.$gray-20;
  color: variables.$gray-40;
  font-size: em(10px);
  min-width: 72px;
  padding-bottom: 5px;
  padding-top: 5px;
  text-transform: uppercase;
  &:hover {
    background-color: variables.$gray-30;
  }
  &.active {
    background-color: variables.$brand-secondary;
    color: variables.$white;
  }
}

.pagination-results {
  color: variables.$gray-50;
  font-size: em(11px);
  font-weight: variables.$font-weight-bold;
  padding-top: 7px;
  a {
    border: none;
    border-radius: 20px;
    color: variables.$gray-40;
    font-weight: variables.$font-weight-normal;
    margin-left: 0px;
    min-width: 25px;
    padding: 3px 6px;
    text-align: center;
    &:hover {
      background-color: variables.$gray-20;
    }
    &.active {
      background-color: variables.$brand-secondary;
      color: variables.$white;
    }
  }
}

.contact-table {
  margin-top: 20px;
}

.contact-row {
  .icon-candidate {
    color: variables.$brand-primary;
  }

  .icon-primary {
    color: variables.$brand-secondary;
  }

  .icon-person-filled {
    color: variables.$gray-40;
  }
}

.reference-show {
  font-size: 12px;
  margin-bottom: 1em;
  margin-top: 1em;
  .created-by {
    float: left;
    font-size: 14px;
    font-weight: variables.$font-weight-bold;
    margin-bottom: 0.5em;
    margin-right: 1em;
  }

  .edit-delete {
    .edit a {
      margin: 0px 8px;
    }

    .edit-reference-link {
      margin-left: 10px;
    }

    .delete,
    .edit {
      font-style: normal;
      font-weight: variables.$font-weight-normal;
      opacity: 0.2;
      transition: opacity 300ms;
    }

    &:hover .delete,
    &:hover .edit {
      opacity: 0.9;
    }

    @include small-text;
    clear: right;
    margin-bottom: 0.5em;
    padding-top: 5px;
  }
  .created-at {
    clear: both;
    float: left;
  }
  .content {
    clear: both;
    font-weight: variables.$font-weight-bold;
  }
}

.reference-edit {
  clear: both;
  display: none;
  padding-top: 1px;
  .btn {
    font-size: 12px;
  }
}

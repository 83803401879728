$env: production;
.CandidacyAssessmentList__htmlText {
  overflow: hidden;

  &--isShortView {
    // want to show 3 lines of summary notes when criteria or category is present
    max-height: 53px;
  }
  &--isLongView {
    // want to show 10 lines of summary notes when criteria or category is not present
    max-height: 172px;
  }
}

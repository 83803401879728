.ActivityListItem__owner {
  font-size: 10px;
  font-style: normal;
}

.ActivityListItem {
  .ActivityListItem__date {
    time {
      font-size: 10px;
      font-style: normal;
    }
  }
}

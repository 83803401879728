@use '@thrivetrm/ui/stylesheets/variables';

/* stylelint-disable sh-waqar/declaration-use-variable */
// ^ Icon fonts are technically fonts, but they are a clever hack - unrelated to
//   actual typography - so they are declared here and not exposed as variables
//   because they should not be referenced directly.

@charset "UTF-8";

@font-face {
  font-family: 'icon-font';
  font-style: normal;
  font-weight: variables.$font-weight-normal;
  src: url('~assets/fonts/icon-font.eot');
  src: url('~assets/fonts/icon-font.eot?#iefix') format('embedded-opentype'),
    url('~assets/fonts/icon-font.woff') format('woff'),
    url('~assets/fonts/icon-font.ttf') format('truetype');
}

[data-icon]:before {
  content: attr(data-icon);
  font-family: 'icon-font' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal !important;
  font-variant: normal !important;
  font-weight: variables.$font-weight-normal !important;
  line-height: 1;
  speak: none;
  text-transform: none !important;
}

[class^='icon-']:before,
[class*=' icon-']:before {
  font-family: 'icon-font' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal !important;
  font-variant: normal !important;
  font-weight: variables.$font-weight-normal !important;
  line-height: 1;
  speak: none;
  text-transform: none !important;
}

.icon-add:before {
  content: 'a';
}
.icon-candidate:before {
  content: 'b';
}
.icon-caret-down:before {
  content: 'c';
}
.icon-clock:before {
  content: 'd';
}
.icon-close:before {
  content: 'e';
}
.icon-cog:before {
  content: 'f';
}
.icon-collapse:before {
  content: 'g';
}
.icon-comment-bubble:before {
  content: 'h';
}
.icon-document:before {
  content: 'i';
}
.icon-edit:before {
  content: 'j';
}
.icon-envelope-circled:before {
  content: 'k';
}
.icon-envelope-grey:before {
  content: 'l';
}
.icon-expand:before {
  content: 'm';
}
.icon-info:before {
  content: 'n';
}
.icon-linked-in:before {
  content: 'o';
}
.icon-location-marker:before {
  content: 'p';
}
.icon-more:before {
  content: 'q';
}
.icon-nav-assessments:before {
  content: 's';
}
.icon-nav-comments:before {
  content: 't';
}
.icon-nav-companies:before {
  content: 'u';
}
.icon-nav-contacts:before {
  content: 'v';
}
.icon-nav-dashboard:before {
  content: 'w';
}
.icon-nav-experience:before {
  content: 'x';
}
.icon-nav-interviews:before {
  content: 'y';
}
.icon-nav-notes:before {
  content: 'z';
}
.icon-nav-outreach:before {
  content: 'A';
}
.icon-nav-overview:before {
  content: 'B';
}
.icon-nav-reports:before {
  content: 'C';
}
.icon-nav-searches-alt:before {
  content: 'D';
}
.icon-thumbs-down:before {
  content: 'r';
}
.icon-thumbs-up:before {
  content: 'E';
}
.icon-target:before {
  content: 'F';
}
.icon-star-empty:before {
  content: 'G';
}
.icon-skype:before {
  content: 'H';
}
.icon-phone-circled:before {
  content: 'I';
}
.icon-search:before {
  content: 'J';
}
.icon-nav-activities:before {
  content: 'K';
}
.icon-p-d-f:before {
  content: 'L';
}
.icon-person-empty:before {
  content: 'M';
}
.icon-primary:before {
  content: 'N';
}
.icon-person-filled:before {
  content: 'Q';
}
.icon-target-two:before,
.icon-nav-searches-alt:before {
  content: 'R';
}
.icon-nav-references:before {
  content: 'O';
}
.icon-nav-introductions:before {
  content: '\50';
}
.icon-icons-fast:before {
  content: '\55';
}

/** additional sizes for font-awesome icons */
.fa-sm {
  font-size: 0.75em;
}

.fa-xs {
  font-size: 0.6em;
}

.target-company-indicator.fa-bullseye {
  color: variables.$brand-primary;
  padding-right: 0.25em;
}

/**
 * Shows a map marker icon to the element's right
 */
.location-icon-affixed {
  padding-right: 1.25em;
  position: relative;

  &:after {
    color: variables.$brand-primary;
    content: $fa-var-map-marker;
    font-family: FontAwesome;
    font-size: 1.25em;
    line-height: 1;
    position: absolute;
    right: 0;
  }
}

/**
 * Shows a map marker icon to the element's left
 */
.location-icon-prefixed {
  padding-left: 1.25em;
  position: relative;

  &:before {
    color: variables.$brand-primary;
    content: $fa-var-map-marker;
    font-family: FontAwesome;
    font-size: 1.25em;
    left: 0;
    line-height: 1;
    position: absolute;
  }
}

@use '@thrivetrm/ui/stylesheets/variables';

.reference {
  font-size: variables.$font-size-small;
  margin-bottom: 2em;

  .name {
    font-size: variables.$font-size-base;
    font-weight: variables.$font-weight-bold;
  }

  .description {
    margin-top: 1em;
  }
  .edit-delete-links {
    margin-top: 1em;
  }
}

#reference-form {
  .btn-primary {
    margin-top: 1em;
  }
}

@use '@thrivetrm/ui/stylesheets/variables';

.ActivitiesList {
  &__loadMore {
    color: variables.$brand-dark-purple;
  }
  &__filterDropdown .btn {
    // want 30px height for filter dropdown
    height: variables.$input-height-small;
    padding: 0px variables.$space-8;
  }
}

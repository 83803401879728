.PendingContactsPage {
  &__actionButtons {
    width: 33.33%;
  }
  &__bottomPanel {
    justify-content: flex-start;
  }
  &__reviewButton {
    width: max-content;
  }
}

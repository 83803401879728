@use '@thrivetrm/ui/stylesheets/variables';

.CandidacySearchSidebarOverview__contactLinks {
  list-style-type: none;
  padding: 0;

  .fa {
    color: variables.$gray-50;
  }
}

.CandidacySearchSidebarOverview__summaryDownloadButton,
.CandidacySearchSidebarOverview__summaryDownloadButton:active,
.CandidacySearchSidebarOverview__summaryDownloadButton:focus,
.CandidacySearchSidebarOverview__summaryDownloadButton:hover {
  color: variables.$brand-primary;
}

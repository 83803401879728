@use '@thrivetrm/ui/stylesheets/variables';

.InvestmentsTableView > .InvestmentsTableView__table {
  .InvestmentsTableView__row {
    vertical-align: top;
  }

  .InvestmentsTableView__row > td {
    padding: 0.25em 1em 2em;
  }

  .InvestmentsTableView__row--company > td {
    // Separate each company "group" with a border
    border-top: 1px solid variables.$gray-20;

    // Slight padding between groups.
    padding-top: 2em;
  }

  .InvestmentsTableView__row--company:first-child > td {
    // No separator on the first group, though.
    border-top: none;
    padding-top: 1em;
  }

  td.InvestmentsTableView__cell--company-name {
    padding-bottom: 0;
  }

  td.InvestmentsTableView__cell--company-logo {
    min-width: 100px;
    padding-left: 0;
  }

  td.InvestmentsTableView__cell--amount {
    text-align: right;
  }

  .InvestmentsTableView__cell--investors__label {
    font-weight: variables.$font-weight-bold;
    padding-bottom: 0.5em;
  }
}

.EducationTitle {
  margin-bottom: 0;

  &__actions {
    display: inline;
    margin-left: auto;
  }

  &__actions .btn {
    margin-left: 0.5em;
  }
}

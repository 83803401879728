.TableCell .TableCell__content {
  &--boolean {
    text-align: center;
  }
  &--contact_social_media {
    width: 100px;
  }
  &--gdpr_status {
    max-width: 170px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &--percentage_range,
  &--currency_range {
    font-variant-numeric: line-nums tabular-nums;
    word-wrap: break-word;
  }

  &--email,
  &--user_email {
    max-width: 140px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &--date,
  &--phone,
  &--numeric,
  &--percentage,
  &--currency {
    font-variant-numeric: line-nums tabular-nums;
    white-space: nowrap;
  }
}

@use '@thrivetrm/ui/stylesheets/variables';

@import 'contacts/candidacy_form';
@import 'contacts/contact_form';
@import 'contacts/networks_form';

.contacts,
.candidacies,
#pdf-upload-wrapper {
  .spinner {
    display: none;
    margin-left: 10px;
  }
}

#contacts {
  .ContactAverageRatingModalButton > .btn {
    padding-bottom: 0;
    padding-top: 0;
  }
}

#contacts-index .table-index td {
  vertical-align: middle;
}

.column-social {
  .fa {
    margin-left: 5px;
  }
}

.social-media-icons {
  .fa {
    bottom: 2.5px;
    position: relative;
  }
  .fa-github {
    font-size: 19px;
  }
}

.contact-connection {
  .icon {
    padding-right: 5px;
  }
}

.btn-add-compensation-info {
  display: inline-block;
  margin-bottom: 1em;
}

#contact-groups-wrapper {
  .contact-notice {
    border: 1px solid $panel-default-border;
    border-radius: variables.$border-radius-base;
    cursor: pointer;
    display: none;
    margin-bottom: 4px;
    padding: 8px;

    .fa {
      margin-top: 4px;
    }
  }

  .contact-notice-message {
    margin-left: 4px;
  }

  .form-actions {
    padding-top: 1em;
  }
}

.positions-fields {
  .no-match-notice,
  .create-company,
  .company-subform {
    display: none;
  }
}

.contact-position-dates {
  font-size: em(12px);
  font-style: italic;
  margin-top: variables.$space-24;
  &:first-child {
    margin-top: 10px;
  }
}

.contact-position-company {
  font-size: em(12px);
  font-weight: variables.$font-weight-bold;
}

.contact-position-title {
  font-size: em(12px);
  margin-bottom: 10px;
}

.contact-position-description {
  margin-bottom: variables.$space-16;
}

#referred-by-notes {
  font-size: 0.8em;
  font-style: italic;
}

#contact_search {
  input[type='text'] {
    margin-top: 20px;
    size: 0.9em;
  }

  .actions input {
    float: right;
    margin: 4px;
    margin-bottom: 20px;
    margin-left: 4px;
  }
}

body.contacts-introductions {
  .introduction-form {
    margin-top: 2em;

    .col-sm-6 {
      margin-bottom: 1em;
      padding-left: 0;
      padding-right: 0;
    }

    label {
      font-weight: variables.$font-weight-bold;
      padding-top: 7px;
    }

    .input-group[class*='col-'] {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}

.introduction-show,
.outreach-show,
.reference-show {
  font-size: 12px;
  margin-bottom: 1em;
  margin-top: 1em;
  .created-by {
    float: left;
    font-size: 14px;
    font-weight: variables.$font-weight-bold;
    margin-bottom: 0.5em;
    margin-right: 1em;
  }
  .notes {
    display: none;
  }

  .related-search {
    margin-top: 0.5em;
  }

  .edit-delete {
    .edit a {
      margin: 0px 8px;
    }

    .delete,
    .edit {
      font-style: normal;
      opacity: 0.2;
      transition: opacity 300ms;
    }

    &:hover .delete,
    &:hover .edit {
      opacity: 0.9;
    }

    @include small-text;
    clear: right;
    margin-bottom: 0.5em;
    padding-top: 5px;
  }
  .message {
    clear: both;
  }

  .timestamp {
    margin-top: 0.5em;
  }

  .view-note-toggle,
  .note {
    clear: both;
    display: block;
    margin-top: 0.5em;
  }
}

.introduction-edit {
  clear: both;
  display: none;
  padding-top: 1px;
  .btn {
    font-size: 12px;
  }
}

.outreach-edit .select2-search,
.outreach-new .select2-search {
  width: 100% !important;
}

@use '@thrivetrm/ui/stylesheets/variables';

#companies-list {
  td {
    vertical-align: middle;
  }
}

.input-group .form-control.company-filter-name {
  /** This is targeting the "Search Companies by Name" input on the companies index page */
  width: 270px;
}

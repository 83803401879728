@use '@thrivetrm/ui/stylesheets/variables';

// Variable overrides
$select-input-height: variables.$input-height-base;
$select-input-border-radius: variables.$input-border-radius;
$select-input-border-color: variables.$input-border-color;
$select-input-border-focus: variables.$input-border-focus;
$select-input-placeholder: variables.$input-color;
$select-text-color: variables.$input-color;

$select-option-color: $dropdown-link-color;
$select-option-focused-color: $dropdown-link-hover-color;
$select-option-focused-bg: $dropdown-link-hover-bg;
$select-option-selected-color: $dropdown-link-active-color;
$select-option-selected-bg: $dropdown-link-active-bg;
$select-option-disabled-color: $dropdown-link-disabled-color;

$select-item-gutter: 2px;
$select-item-border-radius: variables.$input-border-radius;

$select-arrow-color: variables.$brand-secondary;
$select-arrow-color-hover: variables.$brand-secondary-light;

// Import variables
@import 'react-select-legacy/scss/default';

.Select-control {
  border: 1px solid variables.$input-border-color;
  border-radius: variables.$input-border-radius;
  box-shadow: variables.$input-box-shadow;

  // For some reason, the $select-input-placeholder
  // controls the values in addition to the placeholder.
  .Select-placeholder {
    color: variables.$input-color-placeholder;
  }

  // Hides the ugly "backspace to remove" message
  .Select-aria-only {
    display: none;
  }
}

.has-error .Select-control {
  border-color: variables.$brand-red;
}

.has-warning .Select > .Select-control {
  // for whatever reason this actually uses state-warning-text and not
  // state-warning-bg. Not sure why. Don't believe me? Go check the bootstrap
  // source code yerself!
  // https://github.com/twbs/bootstrap-sass/blob/5d6b2ebba0c2a5885ce2f0e01e9218db3d3b5e47/assets/stylesheets/bootstrap/_forms.scss#L430
  border-color: $state-warning-text;
}

/**
 * Styles the react-select component to look similar to the select2 dropdown.
 */
.react-select {
  .Select-value {
    align-items: center;
    background-color: variables.$white;
    border: 1px solid variables.$input-border-color;
    border-radius: variables.$input-border-radius;
    color: variables.$gray-50;
    display: inline-flex;
    margin-right: 5px;

    .Select-value-icon {
      border: none;
      font-weight: variables.$font-weight-bold;

      &:hover {
        background-color: #e4e4e4;
        color: variables.$gray-20;
      }
    }

    .Select-value-label {
      flex: 1;
    }
  }

  .Select-arrow-zone {
    display: none;
  }

  .Select-loading {
    margin-left: -2em;
  }

  .Select-option {
    line-height: 1.25;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .Select-option.is-selected {
    background-color: variables.$gray-5;
  }

  .Select-option.is-focused {
    background-color: #5897fb;
    color: variables.$white;
  }

  .Select-option.is-disabled {
    background-color: none;
    color: variables.$gray-50;
    font-weight: variables.$font-weight-bold;
    &.is-selected {
      background: none !important;
    }
  }

  .Select-input {
    padding-top: 2px;
  }
}

.networks-panel-select .Select-option {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.Select-clear {
  vertical-align: middle;
}

// Hard-code the dropdown caret for multi-select to render like the 4.0 select
// menu caret. So that we can avoid having to override every instance.
.Select-arrow {
  background-image: url('~assets/images/chevron-down-4.0.svg');
  background-position: 0 center;
  background-repeat: no-repeat;
  border: none;
  height: 100%;
  top: auto !important;
  vertical-align: middle;
  width: variables.$space-16;
}

.Select-arrow-zone {
  padding-right: variables.$space-12;
  vertical-align: middle;
}

.is-open {
  .Select-control {
    border-color: variables.$input-border-color;
  }
}

@use '@thrivetrm/ui/stylesheets/variables';

.GuestAssessmentPageHeader {
  h3 {
    font-size: em(16px);
  }

  .contact-avatar {
    display: inline-block;
    height: 70px;
    vertical-align: top;
    width: 70px;
    img {
      height: 100%;
      width: 100%;
    }
  }

  .GuestAssessmentPageHeader__candidate-info {
    color: variables.$text-color;
    display: inline-block;
    font-size: 1em;
    margin-left: 20px;
    margin-top: 10px;
    max-width: 80%;
  }
}

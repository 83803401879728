@use '@thrivetrm/ui/stylesheets/variables';

.JobApplicationActionListPopover .popover-content {
  // For some reason .popover-content was given a global padding. Annoying.
  // So remove that. At some point we should remove the global padding.
  padding: 0;

  position: relative;

  & > .LoadingIndicator {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  & > .ErrorAlert {
    margin: 1em;
  }
}

.JobApplicationActionListPopover__menu {
  list-style-type: none;
  padding: 0;
}

.JobApplicationActionListPopover__menuItem > button {
  background: none;

  border: none;
  color: variables.$gray-50;
  display: block;
  min-width: 200px;
  padding: 3px 20px;
  text-align: left;
  white-space: nowrap;
  width: 100%;

  &:active,
  &:focus,
  &:hover {
    background-color: variables.$gray-5;
    outline: none;
  }

  &.active {
    color: variables.$brand-primary;
    cursor: not-allowed;
  }
}

@use '@thrivetrm/ui/stylesheets/variables';

$talent-pools--talent-pool-card-avatar-size: 48px;

.talent-pools--talent-pool-card {
  border: 1px solid variables.$gray-20;
  display: flex;
  margin-top: variables.$space-12;

  .contact-avatar {
    font-size: $talent-pools--talent-pool-card-avatar-size;
    height: $talent-pools--talent-pool-card-avatar-size;
    margin-bottom: 10px;
    width: $talent-pools--talent-pool-card-avatar-size;

    img {
      height: $talent-pools--talent-pool-card-avatar-size;
      width: $talent-pools--talent-pool-card-avatar-size;
    }
  }

  .talent-pools--talent-pool-card-section {
    padding: 1em;
  }

  .talent-pools--job-title,
  .talent-pools--incumbent-link {
    font-weight: variables.$font-weight-bold;
  }

  .talent-pools--talent-pool-link {
    font-size: 0.9em;
  }

  .CandidaciesPerStageDonutChart {
    height: 120px;
    width: 120px;
  }
}

.talent-pools--talent-pool-card-small {
  flex-direction: row;

  .talent-pools--talent-pool-card-left {
    text-align: center;
  }

  .talent-pools--talent-pool-card-right {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.talent-pools--talent-pool-card-large {
  align-items: stretch;
  flex-direction: column;
  min-height: 350px;
  padding: variables.$space-8;
  text-align: center;

  .talent-pools--talent-pool-card-section {
    align-items: center;
    display: flex;
    flex: 1 1 0;
    flex-direction: column;
  }

  .talent-pools--talent-pool-card-top {
    justify-content: flex-start;
  }

  .talent-pools--talent-pool-card-header {
    align-items: center;
    display: flex;
    width: 100%;

    .contact-avatar {
      margin-left: auto;
      margin-right: auto;
    }
  }

  .talent-pools--talent-pool-card-header-left,
  .talent-pools--talent-pool-card-header-center,
  .talent-pools--talent-pool-card-header-right {
    flex: 1 1 33%;
  }

  .talent-pools--talent-pool-card-header-right {
    text-align: left;
  }

  .talent-pools--talent-pool-card-bottom {
    border-top: 1px solid variables.$gray-20;
    justify-content: center;
  }
}

@use '@thrivetrm/ui/stylesheets/variables';

.CandidacyRankingList {
  list-style-type: none;
  padding: 0;
}
.CandidacyRankingListItem__Button {
  background: none;

  border: none;
  color: variables.$gray-50;
  display: block;
  min-width: 200px;
  padding: 3px 20px;
  text-align: left;
  white-space: nowrap;
  width: 100%;
}

.CandidacyRankingListItem__Button:active,
.CandidacyRankingListItem__Button:focus,
.CandidacyRankingListItem__Button:hover {
  background-color: variables.$gray-5;
  outline: none;
}

.CandidacyRankingListItem__Button.active {
  color: variables.$brand-primary;
  cursor: not-allowed;
}

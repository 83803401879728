@use '@thrivetrm/ui/stylesheets/variables';
@use '~assets/stylesheets/variables/bootstrap-overrides';

.dropdown {
  .dropdown-toggle.btn {
    &,
    &:focus,
    &:active,
    &:hover {
      background-color: inherit;
      border-color: variables.$gray-20;
      color: inherit;
    }
  }
}

.dropdown-toggle {
  .dropdown-icon,
  .caret {
    color: variables.$brand-secondary;
  }
}

// Tweak dropdown menu styles
.dropdown-menu {
  .dropdown-header {
    color: variables.$gray-50;
    font-size: 1em;
    font-weight: variables.$font-weight-bold;
    padding-bottom: 5px;
    padding-top: 5px;
  }

  .divider {
    margin: 4px 0;
  }

  & > .active > a {
    background-color: inherit;
    color: variables.$brand-primary;
  }
}

.dropdown-menu .checkbox,
.dropdown-menu .radio {
  margin-bottom: 3px;
  margin-top: 3px;

  &.disabled > label,
  &[disabled] > label {
    color: variables.$gray-40;
  }
}

// Similar to bootstrap's `.btn-block` class, creates a block-level dropdown that spans the full
// width of the parent.
.dropdown-block {
  width: 100%;

  .dropdown-toggle.btn {
    border-radius: variables.$border-radius-base;
    text-align: left;
    width: 100%;
  }

  .dropdown-toggle .caret {
    float: right;
    margin-top: 0.25em;
  }

  .dropdown-menu {
    // The extra -1px allows for the bottom border in the button to overlap with the top
    // border in the menu, so that they appear to share a single 1px border. Because their widths
    // can vary, we can't just hide one of the borders.
    margin-top: -1px;

    width: 100%;
  }

  &.open {
    .dropdown-toggle.btn {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    .dropdown-menu {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
}

// When dropdown buttons have .btn-link class, make sure they look like any other .btn-link,
// which means getting rid of the border, setting the normal link colors, and the correct
// hover decoration.
.dropdown .dropdown-toggle.btn.btn-link {
  border: none;
  color: variables.$link-color;
}

.dropdown .dropdown-toggle.btn.btn-link:focus,
.dropdown .dropdown-toggle.btn.btn-link:active,
.dropdown .dropdown-toggle.btn.btn-link:hover {
  color: variables.$link-hover-color;
  text-decoration: bootstrap-overrides.$link-hover-decoration;
}

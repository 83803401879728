@use '@thrivetrm/ui/stylesheets/variables';

.advanced-search-simple-search-form {
  display: inline-block;
  padding-bottom: 15px;
  padding-top: 15px;

  a,
  span {
    margin-left: 8px;
  }

  .form-group {
    display: inline-table;
  }
}

.advanced-search-modal.modal {
  .modal-dialog .modal-content .modal-footer {
    border-top: 0;
    min-height: 0;

    > a {
      margin-right: 10px;
    }
  }

  label:not(.boolean):not(.radio_button_label) {
    font-weight: variables.$font-weight-bold;
  }

  .contact_filter_skills,
  .contact_filter_languages {
    margin: 0;
  }

  .list-group-item {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-left: 0;
    border-right: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding: 10px 5px;

    // make the whole li clickable
    > a {
      display: block;
    }

    .contact-advanced-search-input-wrapper {
      padding-top: 15px;
    }

    .badge {
      background-color: variables.$white;
      border: 1px solid variables.$brand-secondary;
      color: variables.$brand-secondary;
      padding: 2px 3.5px;

      .fa {
        margin-top: 1px;
      }
    }
  }

  .advanced-search-input-fields-wrapper {
    border-bottom: 1px solid variables.$gray-30;
    margin: 0;
    padding-bottom: variables.$space-12;
  }
}

.input-group.advanced-search {
  width: 25em;
}

.filter-header h2 {
  padding-left: 16em;
}

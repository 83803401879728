@use '@thrivetrm/ui/stylesheets/variables';

.CandidacySummaryReportModal {
  .CandidacySummaryReportModal__allCheckbox {
    display: inline-block;
    padding-left: 0.5em;
  }

  .CandidacySummaryReportModal__layoutOptions {
    margin-bottom: -1em;
  }

  .CandidacySummaryReportModal__informationHeader {
    margin-bottom: -1em;
  }

  .has-error {
    color: variables.$brand-danger;
  }
}

@use '@thrivetrm/ui/stylesheets/variables';

.RecordIndexHeader {
  // Custom PageHeader layout override
  .page-title {
    margin-right: variables.$space-24;
    width: $recordIndex-sidebar-width;
  }
  .page-actions {
    align-items: center;
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
  }

  &__searchInput {
    margin-right: variables.$space-16;
    width: 448px;
  }
}

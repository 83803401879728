.ErrorAlert {
  margin-top: 0.5em;

  .ErrorAlert__title {
    margin-right: 0.5em;
  }

  .ErrorAlert__actions {
    margin-top: 0.5em;
  }
}

@use '@thrivetrm/ui/stylesheets/variables';

.tasks-table {
  margin-top: em(15px);
  th {
    cursor: pointer; // Headers are clickable/sortable.
    white-space: nowrap;
  }
  table tbody td {
    vertical-align: middle;
  }

  .fa-pencil,
  .fa-trash {
    opacity: 0;
    text-decoration: none;
  }
}

.tasks-table .hovered {
  .fa-pencil,
  .fa-trash {
    opacity: 1;
  }
}

.tasks-table .pending {
  color: variables.$gray-40;
  a {
    color: inherit;
  }
  .tasks-table-col-due_date .tasks-due-date {
    .fa-calendar {
      color: inherit;
    }
  }
  .tasks-table-col-title .fa-sticky-note {
    color: inherit;
  }
  .contact-avatar {
    img {
      filter: grayscale(100%);
    }
  }
}

.task-item {
  .task-due-date,
  .task-due-time {
    display: inline-block;
    font-size: 85%;
    margin-right: 2em;
    .fa {
      color: variables.$brand-secondary;
      margin-right: em(5px);
    }
  }
}

.tasks-table-col-due_date {
  white-space: nowrap;
  .tasks-due-date {
    .fa.fa-calendar {
      color: variables.$brand-secondary;
    }
  }
}

.task-edit-and-delete-btn {
  white-space: nowrap;
}

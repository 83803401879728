@use '@thrivetrm/ui/stylesheets/variables';

.ContactRatingsPanel__averageRating > h3,
.ContactRatingsPanel__allRatings > h3,
.ContactRatingsPanel__addRating > h3 {
  // Show subheaders in standard gray instead of black
  color: variables.$text-color;
}

.ContactRatingsPanel__allRatings > h3 {
  align-items: center;
  // Use flexbox to display the header content so that the header text is aligned to the right
  // and the "Add rating" buttons is aligned to the left.
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;

  .dropdown-toggle.btn {
    color: variables.$link-color;
  }
}

@use '@thrivetrm/ui/stylesheets/variables';

.ContactPositionField__primaryPosition {
  margin-bottom: variables.$space-16;
  margin-top: -1 * variables.$space-12;
  // These rules reduce the spacing between the checkbox control and the warning or error message
  .checkbox {
    margin-bottom: 0;
  }

  .help-block {
    margin-top: 0;
  }
}

@use '@thrivetrm/ui/stylesheets/variables';

.contact-page-flash-success {
  color: variables.$green;
  padding-left: 1em;
}

.contact-page-table-add-body th {
  white-space: nowrap;
}

// Prevent the report button from taking the whole width when the only button element
.contact-form-basic .ContactDownloadReportButton__menu,
.contact-form-basic .ContactDownloadReportButton__button {
  max-width: calc(50% - #{variables.$space-8});
}
// Overwrite adjacent spacing for buttons rendered in Menu components.
// Unline button components in core, these do not automatically add left/right spacing.
.ContactPage__resumeButton + .ContactDownloadReportButton__menu {
  margin-left: variables.$space-16;
}

.contact-form-view-mode .contact-form-basic {
  display: flex;
  aside {
    display: flex;
    flex: 1;
    flex-direction: column;
  }
  .CommentsPanel {
    flex: 1;
  }
}

.contact-form__CompanyPreferences--isCandidateUser {
  margin-bottom: 2.5em;
}

.linkedInSkillsTooltipIcon {
  color: variables.$brand-blue;
  margin-left: variables.$space-8;
}

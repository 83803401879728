@use '@thrivetrm/ui/stylesheets/variables';

.CandidacyStageDropdown {
  display: flex;
  justify-content: flex-end;
  text-align: right;

  .CandidacyMoveStageButton {
    overflow: hidden;
    padding-top: 0;
  }

  .CandidacyStageDropdown__wrapper_div {
    display: flex;
    justify-content: flex-end;
    overflow: hidden;
    text-align: right;
    white-space: nowrap;
  }

  .SearchStageName {
    color: variables.$gray-50;
    flex: 1;
    font-size: 1.3rem;
    overflow: hidden;
    padding-right: 0.4em;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  i {
    color: variables.$brand-primary;
    flex: 0 0 auto;
  }
}

@use '@thrivetrm/ui/stylesheets/variables';

.note-form.rich-text-form {
  .note-field-checkbox {
    float: right;

    label {
      color: variables.$brand-secondary;
      display: inline;
      padding-right: 0.5em;
      &:hover,
      &:focus {
        color: variables.$brand-secondary-light;
      }
    }
  }
}

@use '@thrivetrm/ui/stylesheets/variables';

.CommentView {
  .title {
    clear: right;
    display: block;
  }

  time {
    color: variables.$gray-40;
  }

  .inline-edit-actions,
  time {
    font-size: 85%;
  }

  p {
    margin: 0;
    padding-top: 0.5em;
  }

  // This feature is legacy but we still support viewing it where data exists
  .fa-thumbs-up,
  .fa-thumbs-down {
    padding-left: 5px;
  }
}

@use '@thrivetrm/ui/stylesheets/variables';

.ConfigureColumnsModal {
  // Calculate the space remaining for modal content to render
  $overlayPadding: $modal-overlay-padding;
  $bodyPadding: variables.$space-16;
  $headerHeight: 73px;
  $footerHeight: 72px;
  $containerElementsHeight: ($overlayPadding * 2) + ($bodyPadding * 2) +
    $headerHeight + $footerHeight;

  &__body {
    overflow-y: visible;
    padding-top: variables.$space-12;
    @media (min-height: 1000px) {
      max-height: $modal-max-height;
    }
  }

  &__columns {
    display: flex;
  }

  .FilterableCheckboxList {
    border-right: 1px solid variables.$gray-20;
    flex-grow: 1;
    padding-right: 20px;
  }

  .ReorderableList {
    // By applying padding on the list rather than the modal body, we prevent the scrollbar
    // from rendering over top of the reorderable list items.
    padding-right: 20px;
  }

  &__availableColumns,
  &__selectedColumns {
    // Set the columns to expand to fill the height of the body of the modal
    display: flex;
    flex: 1 1 0;
    flex-direction: column;
    max-height: calc(#{$modal-height} - #{$containerElementsHeight});

    @media (min-height: 1000px) {
      max-height: calc(#{$modal-max-height} - #{$containerElementsHeight});
    }
  }

  &__availableColumns {
    padding-left: variables.$space-4;
  }

  &__headerContainer {
    align-items: baseline;
    display: flex;
    justify-content: space-between;
    padding-right: 20px;
  }

  &__deselectBtn {
    border-right: 1px solid variables.$gray-20;
    font-size: variables.$font-size-small;
    padding: 0 6px;
    &:hover,
    &:active,
    &:focus {
      border-right: 1px solid variables.$gray-20;
    }
  }

  &__resetBtn {
    font-size: variables.$font-size-small;
    margin-left: 0 !important;
    padding: 0 6px;
  }

  &__selectedColumns {
    padding-left: 20px;
  }

  @media only screen and (max-width: 970px) {
    min-width: 90%;
  }
}

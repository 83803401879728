@use '@thrivetrm/ui/stylesheets/variables';

.candidacy-action-buttons,
.contact-action-buttons {
  .btn {
    overflow: hidden;
    padding-left: 0;
    padding-right: 0;
    text-overflow: ellipsis;
  }

  .btn.active .fa {
    color: variables.$brand-secondary;
  }
}

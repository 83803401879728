@use '@thrivetrm/ui/stylesheets/variables';

.ContactConnections {
  &__addConnectionButton {
    color: variables.$brand-dark-purple;
  }
  &__menuButton {
    margin-left: 42px;
    // sidepanel paddingRight is 42px
    margin-right: 42px;
  }

  &__filterLabel {
    border: solid 1px variables.$gray-30 !important;
    color: variables.$brand-dark-purple !important;
    font-size: 12px;
    height: 30px;
    padding: 6px 24px;
  }

  &__filterList {
    margin-top: -1px;
    overflow-y: auto;
    padding: 4px;
    width: 100%;
    @media (max-width: $screen-xl) {
      max-height: 310px;
    }
    @media (max-width: $screen-lg) {
      max-height: 200px;
    }
    @media (max-width: $screen-md) {
      max-height: 150px;
    }
  }
}
// To remove default drop-down icon
.dropdown-toggle.connectionFilterDropDown .caret {
  display: none;
}

.loadMore {
  display: flex;
  margin: auto;
}

@use '@thrivetrm/ui/stylesheets/variables';

.Avatar > img {
  // Stretch the image to match the .Avatar dimentions, which
  // are specified on the component.
  height: 100%;

  // Bootstrap sets img's to vertical-align: middle, so reset that.
  vertical-align: top;
  width: 100%;

  &[alt] {
    color: variables.$gray-40;
    font-size: 11px;
  }
}

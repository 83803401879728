@use '@thrivetrm/ui/stylesheets/variables';

.talent-pools--sort-filter-toolbar {
  display: flex;
  margin-bottom: variables.$space-8;

  .talent-pools--filter {
    flex: 1;
  }

  .talent-pools--view-type-select,
  .talent-pools--add-btn {
    margin-left: 1em;
  }

  .talent-pools--view-type-select .btn {
    // force button group height to match input/sort button height
    padding-bottom: 5px;
    padding-top: 5px;
  }
}

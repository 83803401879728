.TagColorSquareList {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.TagColorSquareList__item {
  display: inline-block;
  padding-right: 0.2em;
}

@use '@thrivetrm/ui/stylesheets/variables';

.line-item-list {
  padding-left: 0;
}

.line-item-list li {
  display: flex;
  line-height: 1.8;
}

.line-item-list li .description {
  flex: 1 1 auto;
  overflow: hidden;
  padding-right: 4px;
  text-transform: capitalize;
  white-space: nowrap;
}

.line-item-list li .description:after {
  border-bottom: 1px dotted;
  content: ' ';
  display: inline-block;
  margin-left: 0.25em;
  margin-right: 0.25em;
  overflow: hidden;
  width: 100%;
}

.line-item-list li .value {
  flex: none;
  font-weight: variables.$font-weight-bold;
  margin-left: 0.25em;
}

/**
 * Striped lists
 */

.list-striped {
  li:nth-child(even) {
    background-color: variables.$gray-20;
  }
}

/*************************************************************************
 * Generic list
 *
 * This generic list class produces an unstyled list with some
 * predefined styling on certain field types, and margins between items.
 *************************************************************************/

.list-generic {
  list-style: none;
  padding-left: 0;

  > li {
    margin-top: variables.$space-16;

    &:last-child {
      margin-bottom: variables.$space-16;
    }

    .datetime {
      color: variables.$gray-50;
      font-size: 0.9em;
      font-style: italic;
    }

    .title {
      font-weight: variables.$font-weight-bold;
    }
  }

  // In some places we put a form inside of a card and the buttons have
  // too much space around them.  This removes that extra space
  .form-actions > .btn {
    margin-bottom: 0;
    margin-top: 0;
  }
}

/*************************************************************************
 * Comma Separated list
 *
 * Renders list items inline, except for adding a comma between each item.
 * It's smart enough not to add a comma at the end.
 *************************************************************************/

.list-csv {
  display: inline;
  list-style: none;
  padding-left: 0;

  li {
    display: inline;

    &:after {
      content: ', ';
    }

    &:last-child:after {
      content: '';
    }
  }
}

@use '@thrivetrm/ui/stylesheets/variables';

// Custom styling for the transparent background React Select. It has
// the appearance of a linked subtitle.

.title-dropdown-field .Select-control {
  background-color: inherit;
  border: 0px;
  .Select-multi-value-wrapper .Select-value,
  .Select-multi-value-wrapper .Select-placeholder {
    padding-left: 0px;
    span.Select-value-label[role='option'],
    div.placeholder {
      color: variables.$link-color;
      font-size: 11pt;
      font-weight: variables.$font-weight-bold;
    }
  }
  // Hides the default menu caret, since we render our own.
  .Select-arrow {
    display: none;
  }
}

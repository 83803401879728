@use '@thrivetrm/ui/stylesheets/variables';

// Limit styles to only apply to SelectFilter instance
.AsyncSelect {
  position: relative;
  .MultiValueInput {
    position: relative;

    &__value-container {
      flex-wrap: nowrap;
    }
    &--isInvalid &__control {
      border: 1px solid variables.$brand-red;
    }
    &__placeholder {
      color: variables.$gray-30;
    }
    &__control {
      border: 1px solid variables.$input-border-color;
      box-shadow: inset variables.$input-box-shadow;
      padding: variables.$space-2 variables.$space-8;
    }
    &__option {
      color: variables.$text-color;
      display: block;
      font-size: variables.$font-size-base;
      height: auto;
      padding: variables.$space-4 variables.$space-8;
    }
    &__indicator,
    &__indicators,
    &__multi-value,
    &__multi-value__remove {
      align-items: center;
      display: flex;
    }
    &__multi-value {
      display: flex;
      justify-content: space-between;
      padding: 0px variables.$space-4;
    }
    &__value-container--is-multi {
      flex-wrap: wrap;
      & + .MultiValueInput__indicators {
        flex-shrink: 0;
        justify-content: flex-end;
        width: 44px;
      }
    }
  }
  &__errorMessage {
    color: variables.$brand-red;
    font-size: variables.$font-size-small;
    margin-top: variables.$space-4;
  }
}

.AsyncSelect.MultiValueInput--isInvalid .MultiValueInput__control {
  border: 1px solid variables.$brand-red;
}

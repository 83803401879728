@use '@thrivetrm/ui/stylesheets/variables';

.CalendarSelectField {
  background-color: variables.$gray-20;
  border-radius: variables.$border-radius-base;
  padding: 8px;

  &__noticeToUser {
    align-items: flex-start;
    border-radius: variables.$border-radius-base;
    display: flex;
    font-size: variables.$font-size-base;
    margin-bottom: variables.$space-8;
    padding: variables.$space-16;

    &--isEditing {
      background-color: lighten(variables.$brand-orange, 25%);
    }

    &--isCreating {
      background-color: lighten(variables.$brand-blue, 38%);
    }
  }
}

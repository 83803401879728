@use '@thrivetrm/ui/stylesheets/variables';

.MultiValueSelectBooleanFilter,
.MultiValueTextBooleanFilter {
  background-color: variables.$white;
  border: 1px solid variables.$input-border-color;
  border-radius: variables.$input-border-radius;
  box-shadow: inset variables.$input-box-shadow;
  display: flex;
  min-height: variables.$input-height-small;
  padding: 4px 8px;
  position: relative;
  &__creatableContainer {
    flex-grow: 1;
    overflow: hidden;
  }
  &__label {
    color: variables.$input-label-color;
    display: block;
    font-size: variables.$input-label-font-size;
    font-weight: variables.$input-label-font-weight;
  }
  &__menu {
    background-color: variables.$white;
    border: 1px solid variables.$gray-20;
    border-radius: variables.$border-radius-large;
    box-shadow: 0 4px 7px 0 rgba(variables.$black-shadow, 0.12);
    padding-bottom: variables.$space-2;
    position: absolute;
    top: calc(100% + variables.$space-4);
    width: 100%;
    z-index: variables.$z-index-dropdown;
  }

  &__menu-list {
    margin-left: variables.$space-8;
    margin-right: variables.$space-8;
    max-height: 300px;
    overflow-y: auto;
    position: relative;
  }

  &__option,
  &__menu-notice {
    align-items: center;
    background-color: transparent;
    border-radius: variables.$border-radius-base;
    color: inherit;
    display: flex;
    font-size: variables.$font-size-base;
    margin: variables.$space-4 variables.$space-2;
    padding-left: 6px;
    padding-right: 6px;
    user-select: none;
  }

  &__option {
    cursor: pointer;

    &--is-focused {
      background-color: variables.$gray-10;
    }
  }

  &__value-container {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
  }
  &__multi-value {
    background-color: variables.$gray-20;
    border-radius: variables.$space-2;
    box-sizing: border-box;
    color: variables.$gray-50;
    cursor: default;
    display: inline-flex;
    font-size: variables.$font-size-small;
    margin: variables.$space-4 variables.$space-8 variables.$space-4 0;
    min-height: 20px;
    padding: variables.$space-2 variables.$space-8;

    &__remove {
      align-items: center;
      color: variables.$brand-blue;
      display: flex;
      &:hover,
      &:focus {
        color: variables.$gray-50;
      }
    }
  }

  &__placeholder {
    color: variables.$input-color-placeholder !important;
  }
  .SegmentedControl {
    align-items: center;
    flex-grow: 0;
    height: 20px;
    .SegmentedControl__button {
      height: 14px;
      padding: 6px;
    }
  }
}

.MultiValueSelectBooleanFilter__multi-value
  + .MultiValueSelectBooleanFilter__multi-value,
.MultiValueTextBooleanFilter__multi-value
  + .MultiValueTextBooleanFilter__multi-value {
  margin-left: variables.$space-8;
}

$env: production;
.CandidacyAssessment {
  &__htmlText {
    height: auto;
    overflow: hidden;

    &--isShortView {
      // want to show 3 lines of summary notes
      max-height: 57px;
    }
  }
}

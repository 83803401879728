@use '@thrivetrm/ui/stylesheets/variables';

.PrimaryPositionField label {
  // Expand the label the entire block so anywhere within the list-group-item can be clicked
  // to toggle a selection.
  display: block;

  // Display the entire title in bold (not just the company)
  .PositionView > .PositionTitle {
    font-weight: variables.$font-weight-bold;
  }
}

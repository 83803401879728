@use '@thrivetrm/ui/stylesheets/variables';

.ContactAverageRatingModalButton {
  border: none;
  /** Buttons reset the line-height to 1. But we basically want this thing to not even look like
    * a button at all, so reset it back to the default line-height for regular text */
  line-height: variables.$line-height-base;
  /* Buttons align center by default, we want left-alignemnt in this case */
  text-align: left;
}

.ContactAverageRatingModalButton,
.ContactAverageRatingModalButton:hover,
.ContactAverageRatingModalButton:focus,
.ContactAverageRatingModalButton:active {
  // For whatever ridiculous reason this button is not actually supposed to look or feel like
  // a button at all. Just act like one.
  color: variables.$text-color;
}

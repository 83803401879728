@use '@thrivetrm/ui/stylesheets/variables';

.CompanyRevenueListField__item {
  align-items: center;
  display: flex;

  & > .CompanyRevenueDeleteToggle {
    // compensation for the labels to center trash icon with input
    margin-top: 6px;
    padding: 0 1em;
  }
}

.CompanyRevenueListField__item--deleted {
  opacity: 0.5;
}

.CompanyRevenueListField__item--deleted .Select-value,
.CompanyRevenueListField__item--deleted .form-control {
  // need the !important to overwrite reactSelects Select-value color
  color: variables.$brand-danger !important;
  text-decoration: line-through;
}

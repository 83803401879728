.CandidacyListItemSectionDetailsSecondary {
  overflow: hidden;
}

.CandidacyListItemSmallSectionDetails__secondary {
  .CandidacyListItemSectionDetailsPrimary__location {
    display: flex;
    justify-content: flex-start;
    overflow: hidden;
    text-align: left;
    white-space: nowrap;
  }

  .LocationName {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .LocationIcon {
    flex: 0 0 auto;
  }
}

.EmptyState {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 1em 0;

  .EmptyState__message {
    font-size: 1.2em;
    padding: 1em;
    text-align: center;
  }
}

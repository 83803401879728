@use '@thrivetrm/ui/stylesheets/variables';

.CandidacyExtraDocumentsMenu {
  margin-left: variables.$space-12;

  /**
  This is a workaround for the MenuPanel not hanlding overflowing
  elements. Do not repeat this pattern, rather refactor the MenuPanel to
  handle the overflow issues within the MenuPanel itself.
*/
  .MenuPanel.Menu__panel {
    min-width: 237px;
    overflow: hidden;

    .MenuItem a {
      display: block;
      max-width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

@use '@thrivetrm/ui/stylesheets/variables';

.documents--document-uploader-uploads {
  margin-top: 1em;

  .list-group-item-success {
    // Fade out the successful items. The sum of the duratons here should match
    // the `clearDelay` prop value passed to the `DocumentUploader` component.
    animation: fade-out 1000ms ease-out 2000ms both;
  }
}

.documents--document-uploader-upload-progress {
  display: flex;

  .progress {
    margin-bottom: 0;
    width: 20%;
  }

  .filename {
    flex: 1;
    padding-left: 1em;
  }
}

.documents--document-uploader-upload-error {
  margin-top: 0.5em;
}

/**
 * Normal styles for the dropzone
 */
.documents--document-uploader-dropzone {
  border: 1px dashed variables.$gray-20;
  cursor: pointer;
  height: 200px;
  padding: 2rem;
  text-align: center;

  .contact-resume-upload-file small {
    display: inline-block;
    word-break: break-all;
  }

  .documents--document-uploader-placeholder {
    margin-top: 60px;

    span {
      vertical-align: middle;
    }
  }
}

/**
 * Active styles for the dropzone
 */
.documents--document-uploader-dropzone-active {
  background: variables.$light-blue;
  cursor: grabbing;
}

@use '@thrivetrm/ui/stylesheets/variables';

.Sidebar {
  background-color: variables.$gray-5;
  box-shadow: -1px 0 5px 0px rgba(variables.$black-shadow, 0.1),
    1px 0 5px 0px rgba(variables.$black-shadow, 0.1);
}

.Sidebar > .SidebarExpandToggle {
  position: absolute;
  right: 0.5em;
  top: 0.5em;

  // Make sure this thing gets rendered on top of any children.
  z-index: 1;
}

.Sidebar__popover {
  background-color: variables.$white;
  box-shadow: 5px 0px 10px rgba(variables.$black-shadow, 0.3),
    -1px 0px 1px 0px rgba(50, 50, 50, 0.1);
}

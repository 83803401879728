.interviews--interviews-panel-scroll-container {
  .interviews--organized-by {
    padding-bottom: 4px;
  }
  .interviews--where {
    padding-bottom: 4px;
  }
}

.InterviewView .interviews--organized-by {
  font-size: 10px;
}

@use '@thrivetrm/ui/stylesheets/variables';

body.tasks #content {
  margin-top: 0;
}
.tasks-index {
  .btn-toolbar {
    float: right;
  }
}
.tasks-dashboard {
  display: flex;
  min-height: 90vh;

  .tasks-dashboard-sidebar,
  .tasks-dashboard-view {
    font-size: 0.9em;
  }

  .tasks-dashboard-sidebar {
    background-color: variables.$gray-5;
    margin-right: 1em;
    padding-left: 1em;
    padding-right: 1em;
    width: 25%;
  }

  .tasks-dashboard-content {
    flex: 1;
    margin-bottom: 1em;
    margin-top: 1em;

    .alert {
      margin-top: 20px;
    }
  }

  .tasks-dashboard-incumbent-header {
    display: flex;
    justify-content: center;
  }
}

.tasks-sort-filter-toolbar {
  display: flex;

  .input-group-btn {
    button {
      height: 30px;
    }
  }

  .tasks-filter {
    flex: 1;
  }

  .tasks-view-type-select,
  .tasks-add-btn {
    margin-left: 1em;
  }
}

.tasks-view-by-type {
  color: variables.$brand-secondary;
  font-weight: variables.$font-weight-bold;
  margin-bottom: em(10px);
  .btn-link {
    margin-right: em(30px);
  }
  .btn-link.selected {
    color: variables.$link-hover-color;
  }
}

/*
 * Creates columns that are always the same height.
 */

@mixin fluid-height-row() {
  display: table;
  width: 100%;
}

@mixin fluid-height-column($width) {
  vertical-align: top;
  width: $width;
}

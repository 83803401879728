@use '@thrivetrm/ui/stylesheets/variables';

.OffLimitsCard {
  &__header {
    align-items: center;
    display: flex;
    // This height allows for 12px spacing around the status indicator, while
    // allowing the 30px tall menu button to render in the empty space on hover
    // without affecting the layout.
    min-height: 44px;
  }

  &__menu {
    // Push the menu button to the far right side of the flex context, regardless
    // of how many flex item siblings are rendering to the left of it.
    margin-left: auto;
    opacity: 0;
    transition: opacity 250ms variables.$ease-out-quart;
  }

  &:hover &__menu {
    opacity: 1;
  }

  &__data {
    flex-grow: 1;
  }

  &--isMuted {
    opacity: 50%;
    transition: opacity 300ms variables.$ease-out-quad;

    &:hover {
      opacity: 100%;
    }
  }

  & + .OffLimitsCard {
    margin-top: variables.$space-8;
  }
  &__menuPanel {
    overflow: visible;
  }
}

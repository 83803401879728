@use '@thrivetrm/ui/stylesheets/variables';

.TagSelect {
  &__selected {
    display: inline;
  }

  &__search {
    /* clicking anywhere will focus the input */
    cursor: text;
    display: inline-block;

    /* match tag layout */
    margin-bottom: 6px;

    /* prevent autoresize overflowing the container */
    max-width: 100%;
    position: relative;
    width: 100%;
  }

  &__searchInput {
    background: variables.$white;
    border: 1px dashed transparent;
    border-radius: variables.$border-radius-small;
    color: variables.$text-color;
    padding: 1px variables.$space-4;
  }

  &.isFocused &__searchInput {
    border-color: variables.$gray-30;
  }

  &__search input {
    border: 0;

    /* match the font styles */
    font-size: inherit;
    line-height: inherit;

    /* remove styles and layout from this element */
    margin: 0;

    // Since we use a custom font, the tag-select can sometimes measure the width needed before
    // our font gets loaded -- when our font gets loaded, it's got a slightly larger em width,
    // causing the text width to expand as a whole, but since the width was already set by the
    // component, some can get slightly cut off. So this is a fairly arbitrary margin, but we're
    // really only seeing a very slight cutoff, so this should be enough without making the
    // component look lopsided.
    margin-right: variables.$space-12;
    /* prevent autoresize overflowing the container */
    max-width: 100%;
    outline: none;
    padding: 0;
    width: 100%;

    &::placeholder {
      color: variables.$text-color;
    }
  }

  &__suggestions {
    left: 0;
    min-width: 200px;
    position: absolute;
    top: 100%;
    white-space: nowrap;
    width: auto !important;
    z-index: 2;

    ul {
      background: variables.$white;
      border: 1px solid variables.$input-border-color;
      border-radius: variables.$border-radius-base;
      box-shadow: variables.$input-box-shadow;
      list-style: none;
      margin: variables.$space-4 -1px;
      max-height: 300px;
      overflow-y: auto;
      padding: 0;
    }

    li {
      cursor: pointer;
      font-size: variables.$font-size-small;
      padding: 6px variables.$space-8;

      &:hover,
      &.isActive {
        background: variables.$gray-10;
      }

      &.isDisabled {
        cursor: default;
        opacity: 0.5;
      }
    }
  }
}

@use '@thrivetrm/ui/stylesheets/variables';

$CandidacySearchSidebar-width: 7.5em;

.CandidacySearchSidebar {
  font-size: 0.9em;
}

.CandidacySearchSidebar .AttachmentsListItem {
  background-color: variables.$white;
}

.CandidacySearchSidebar__Tabs,
.CandidacySearchSidebar__Content {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.CandidacySearchSidebar__Tabs {
  background-color: variables.$gray-20;

  // This puts the scrollbar on the left side.
  direction: rtl;
  list-style-type: none;
  margin: 0;
  max-width: $CandidacySearchSidebar-width;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0;
  right: $CandidacySearchSidebar-width;

  li {
    border: none;
    direction: ltr;
    display: block;
    float: none;
    font-size: 1.1em;
    margin: 0;
    position: relative;
  }

  li.CandidacySearchSidebar__ReturnToSearch {
    white-space: nowrap;
  }

  li > a {
    color: variables.$gray-60;
    display: block;
    font-size: 0.75em;
    font-weight: variables.$font-weight-bold;
    padding: 1em 0.25em;
    text-align: center;

    & > .icon {
      color: variables.$gray-50;
    }

    &:hover,
    &:active,
    &:focus {
      background-color: inherit;
      border-radius: 0;
      color: variables.$brand-secondary;

      .icon {
        color: variables.$brand-primary;
        display: block;
        font-size: 2.25em;
      }
    }

    &:focus {
      color: lighten(variables.$brand-primary, 5%);
    }

    .icon {
      display: block;
      font-size: 2.25em;
    }

    .icon.fa {
      // When a font-awesome icon is used we need to increase the
      // height to match that of the icon-nav-* icons.
      height: 33px;
    }
  }

  li.active {
    background-color: variables.$gray-5;

    a {
      color: variables.$gray-60;
      font-weight: variables.$font-weight-bold;
    }

    .icon {
      color: variables.$brand-primary;
    }
  }
}

.CandidacySearchSidebar__Content {
  left: $CandidacySearchSidebar-width;
  overflow: auto;
  padding: 1em;
}

.CandidacySearchSidebar__ReturnToSummary {
  padding: 1em;
}

.CandidacySearchSidebar__TabContent {
  margin-bottom: 1em;
  margin-top: 1em;

  .CandidacyCommentsPanel,
  .interviews--interviews-panel,
  .CandidacyAssessmentsGroup,
  .references--references-panel,
  .outreaches--candidacy-outreaches-panel,
  .outreaches--candidacy-outreach-email-panel,
  .notes--candidacy-notes-panel,
  .introductions--introductions-panel {
    & > .form-toggle {
      text-align: center;
    }

    & > .form-toggle > .form-toggle-button {
      width: 100%;
    }
  }
}

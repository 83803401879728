li.CommentListItem {
  margin-bottom: 0;
  // Overrides default list-item spacing of 2em.
  margin-top: 0;

  overflow-wrap: break-word;

  // Allows the Reply button (the "form toggle") to appear on the same
  // line as Edit and Delete
  .inline-edit-actions .form-toggle {
    display: inline-block;
  }

  // Show the editable content with a low opacity unless hovering over that section.
  .CommentListItem__edit,
  .CommentListItem__delete,
  .CommentListItem__reply {
    opacity: 0.5;
  }

  &:hover {
    .CommentListItem__edit,
    .CommentListItem__delete,
    .CommentListItem__reply {
      opacity: 1;
    }
  }
}

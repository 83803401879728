@use '@thrivetrm/ui/stylesheets/variables';

.ColorInput__button {
  border: 1px solid transparent;
  box-shadow: 1px 1px 2px 0px rgb(101, 101, 101);
  // Make it square
  height: variables.$space-32;
  vertical-align: middle;
  // There is no content, just a background color, so we need
  // to force a width.
  width: variables.$space-32;
}

.ColorInput__button:active {
  box-shadow: inset 1px 1px 2px 0px rgb(101, 101, 101);
}

@use '@thrivetrm/ui/stylesheets/variables';

.target-companies--target-company-card--none {
  .target-company-count-value {
    padding: 2px 2px 0 0;
  }

  .no-company-card-logo {
    color: variables.$gray-60;
    padding-top: 60px;
    text-align: center;
  }

  .no-company-card-title {
    height: 2.5em;
    margin-bottom: 70px;
    margin-top: 0;
    overflow: hidden;
    text-align: center;
  }
}

.target-companies--target-company-card {
  border: 1px solid variables.$gray-20;
  border-radius: variables.$border-radius-base;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 240px;
  padding: 0;

  &.inactive {
    opacity: 0.5;
  }

  .fa-toggle-on {
    color: variables.$brand-secondary;
  }

  .fa-toggle-off {
    color: variables.$gray-40;
  }

  .btn-icon {
    margin-left: 0.25em;
    margin-right: 0.25em;
  }

  .target-company-count {
    padding: 2px 2px 0 0;
    &:hover {
      text-decoration: none;
    }

    .target-company-count-value {
      color: variables.$brand-secondary;
      margin-right: 0.25em;
    }
  }

  .company-card-logo {
    height: 120px;
    padding-top: 35px;
    text-align: center;

    img {
      max-height: 50px;
      max-width: 150px;
    }
  }

  .company-card-title {
    margin-top: 0;
    overflow: hidden;
    padding: 0 1em;
    text-align: center;
  }

  .company-card-content {
    background-color: variables.$gray-5;
    border-radius: 0 0 5px 5px;
    border-top: 1px solid variables.$gray-20;
    padding: 0.5em;

    .company-card-vote {
      width: 99%;
      .fa-thumbs-up {
        color: variables.$brand-secondary;
      }
      .btn {
        width: 100%;
      }
    }

    .company-card-vote,
    .company-card-comments {
      display: table-cell;
      margin-left: 0.25em;
      margin-right: 0.25em;

      .btn {
        font-size: 0.75em;
        padding: 1em;
      }
    }

    .company-card-comments .btn {
      &:hover,
      &:focus {
        color: variables.$brand-primary;

        .icon-comment-bubble,
        a {
          color: variables.$brand-primary;
        }
      }

      .icon-comment-bubble {
        color: variables.$gray-40;
        position: relative;
        top: 0.15em;
      }
    }
  }
}

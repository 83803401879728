@use '@thrivetrm/ui/stylesheets/variables';

.DuplicateMergeRecordSelection__errorCard {
  bottom: 85px;
  margin-top: variables.$space-24;
  position: sticky;
  z-index: $z-index-message-container;
}

.DuplicateMergeRecordSelection__content {
  box-sizing: border-box;
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 1250px;
  min-width: 840px;
}
.DuplicateMergeRecordSelection__row {
  align-items: flex-start;
  border-bottom: 1px solid variables.$gray-30 !important;
  border-top: 1px solid variables.$gray-30 !important;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-bottom: variables.$space-64;
  padding-top: variables.$space-64;
  width: 100%;
}
.DuplicateMergeRecordSelection__col {
  display: flex;
  flex: 1;
  flex-basis: 100%;
  flex-direction: column;
  margin-left: variables.$space-8;
  margin-right: variables.$space-8;
}
.DuplicateMergeRecordSelection__icon {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-bottom: variables.$space-64;
  margin-left: variables.$space-24;
  margin-right: variables.$space-24;
  margin-top: variables.$space-64 + variables.$space-8;
  width: 55px;
}

.DuplicateMergeRecordSelection__autocompleteOption {
  display: inline-flex;
  margin-bottom: variables.$space-2;
  margin-top: variables.$space-2;
}

.DuplicateMergeRecordSelection__autocompleteId {
  color: variables.$gray-40;
  font-weight: variables.$font-weight-normal;
  margin-left: variables.$space-12;
}
.DuplicateMergeRecordSelection__autocompleteContent {
  align-self: center;
}

@use '@thrivetrm/ui/stylesheets/variables';

// NB: There are related styles for #wrap in _layout.scss to ensure proper footer alignment.
.footer {
  background: variables.$gray-10;
  border-top: $footer-border-top-width solid variables.$gray-30;
  color: variables.$gray-40;
  height: $footer-height;
  margin-top: $footer-margin-top;
}

.footer-container {
  align-items: center;
  display: flex;
  height: $footer-height;
  justify-content: space-between;

  .logo {
    height: $footer-height - 20;
  }

  .footer-content {
    flex: 1;
    font-size: 0.85em;
    line-height: 0.85em;
    margin-left: 2em;
  }

  .copyright,
  .nav {
    margin: 0.5em 0;
  }

  .nav li {
    padding-left: 0;
  }

  .nav li:before {
    color: variables.$brand-primary;
    content: '|';
    margin: 0 0.25em;
  }

  .nav li:first-child:before {
    display: none;
  }

  .nav a {
    color: variables.$brand-primary;
    display: inline;
    padding: 0;
  }

  .powered-by > img {
    height: 35px;
  }
}

@use '@thrivetrm/ui/stylesheets/variables';

.TabbedHeaderContainer__header {
  border-bottom: 1px solid variables.$gray-20;
  height: 90px;
}

.TabbedHeaderContainer__header__nav {
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
}

.TabbedHeaderContainer__actions {
  display: inline-block;
  list-style: none;
  padding: 0;

  > li {
    display: inline-block;
    padding-left: 1em;
  }
}

.TabbedHeaderContainer__content {
  bottom: 0;
  left: 0;
  overflow: auto;
  position: absolute;
  right: 0;
  top: 90px;
}

$env: production;
.PipelineIndexPage {
  &__noDataCard {
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    width: 320px;
  }

  &__titleTableSide {
    align-items: center;
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
  }
}

@use '@thrivetrm/ui/stylesheets/variables';

.CompanyEmployeeCountLegacyInput .form-control {
  width: 15em;
}

.CompanyEmployeeCountLegacyInput--deleted {
  opacity: 0.5;
}

.CompanyEmployeeCountLegacyInput--deleted .form-control {
  color: variables.$brand-danger;
  text-decoration: line-through;
}

.CompanyEmployeeCountLegacyInput input {
  display: inline;
}

.CompanyEmployeeCountLegacyInput .fa-info-circle {
  color: variables.$brand-secondary;
  padding-left: 5px;
  &:hover {
    color: variables.$brand-secondary-light;
  }
}

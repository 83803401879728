.btn.btn-round {
  border-radius: 50%;
  height: 30px;
  padding: 0.5em;
  vertical-align: text-bottom;
  width: 30px;
}

.btn.btn-round.btn-sm {
  height: 25px;
  padding: 0.25em;
  width: 25px;
}

.btn.btn-round.btn-xs {
  height: 1.7em;
  padding: 0.1em;
  width: 1.7em;
}

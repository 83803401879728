@use '@thrivetrm/ui/stylesheets/variables';

.Activity {
  &__viewDetailsButton {
    color: variables.$brand-dark-purple;
  }
  &__activityIcon {
    color: variables.$gray-40;
    font-size: variables.$space-24;
  }
  &__drawerButton {
    // used important to override primary button's scss
    background-color: variables.$white !important;
    color: variables.$gray-40 !important;
  }
  &__htmlText {
    overflow: hidden;

    &--isShortView {
      // 515px as we want to show only 25 lines
      max-height: 515px;
    }
  }
}

@use '@thrivetrm/ui/stylesheets/variables';

@mixin contact-form-breakpoint($width: $screen-sm) {
  @media only screen and (min-width: $width) {
    @content;
  }
}

/**
 * Contact View Mode
 */
.contact-form-view-mode {
  aside {
    padding: 15px;

    @include contact-form-breakpoint {
      @include fluid-height-column(50%);
      border-right: 1px solid variables.$gray-10;
    }

    .contact-header {
      .contact-avatar,
      .contact-avatar img {
        height: 70px;
        width: 70px;
      }

      .contact-information {
        margin-left: 10px;

        h3,
        h4,
        h5 {
          color: variables.$text-color;
          margin-bottom: 5px;
          margin-top: 0;
        }

        h3,
        h4 {
          font-weight: variables.$font-weight-bold;
        }

        h4 {
          font-size: 13px;
        }

        h3 {
          font-size: 14px;
        }
      }
    }

    // Hide the section header, because the contact's name will act like the header
    .editable-section-contact-header .editable-section-header {
      display: none;
    }
  }

  .contact-form-basic {
    background-color: variables.$white;
    margin-bottom: variables.$space-24;

    @include contact-form-breakpoint {
      @include fluid-height-row();
    }
  }

  .contact-form-basic-main {
    padding: variables.$space-16;

    @include contact-form-breakpoint {
      @include fluid-height-column(60%);
    }

    .contact-social-location {
      margin-right: 15px;
      i {
        color: variables.$brand-primary;
        padding-left: 4px;
      }
    }
  }

  .contact-name h2 {
    margin: 0;
  }

  .contact-icon-link-list,
  .ContactIconLinkList {
    padding-left: 0;
  }

  .contact-details .contact-detail {
    font-size: 1.5em;

    & + .contact-detail {
      margin-left: 5px;
    }
  }

  .contact-location-form .checkbox-inline {
    padding-left: 0;
  }

  .contact-table-add {
    .contact-table-add-form {
      padding-bottom: 15px;

      .Select {
        min-width: 300px;
      }
    }
  }

  .contact-activity-panel {
    background-color: variables.$gray-5;

    .contact-activity-header {
      padding-bottom: variables.$space-16;
    }

    .notifications-filter-dropdown {
      background-color: variables.$white;
    }

    .contact-activity-body .AttachmentsListItem {
      background-color: variables.$gray-5;
    }

    .contact-activity-body {
      .notification-list-item {
        border: none;
        box-shadow: 1px 1px 3px 1px rgb(211, 211, 216);
        margin-bottom: 1em;

        &:first-child {
          border-top: none;
        }

        &:last-child {
          border-bottom: none;
        }
      }
    }
  }

  .contact-positions .contact-position {
    margin-bottom: 40px;
  }
}

/**
 * Contact Edit Mode
 */

/**
 * Save/Cancel sticky buttons on review page
 */

.review-fixed-buttons {
  .review-buttons {
    left: 0;
    position: sticky;
    right: 0;
    top: $navbar-height;
    z-index: $zindex-navbar !important;

    .form-actions {
      background-color: variables.$white;
      border: 1px solid $default-border;
      border-top: 0;
      margin: 0;
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}

.contact-form-edit-mode {
  .contact-form-edit-mode-section {
    background-color: variables.$white;
    border: 1px solid $default-border;
    margin-bottom: variables.$space-16;
    padding: 15px;

    &:first-child {
      border-top: none;
      margin-top: 0;
    }
  }

  .contact-education-field {
    border-top: 1px solid $default-border;
    padding-bottom: 30px;
    padding-top: 30px;

    &:first-child {
      border-top: none;
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
    }
  }

  .contact-special-needs {
    padding-top: 10px;
  }
}

.contact-form {
  .contact-form-section-header {
    margin-bottom: variables.$space-8;
    margin-top: variables.$space-16;
  }

  .AddToSearchForm {
    margin-bottom: variables.$space-16;
    text-align: right;

    .AddToSearchForm__OffLimitsStatus {
      display: inline-block;
      margin-right: variables.$space-16;
      vertical-align: middle;
    }
  }
}

/**
* FIXME: These document uploader styles should be in
* their own file, since it is a component.
*/

/**
* Normal styles for the dropzone
*/
.contact-file-uploader-dropzone {
  border: 1px dashed variables.$gray-20;
  cursor: pointer;
  height: 200px;
  overflow-x: hidden;
  padding: 2rem;
  text-align: center;

  .contact-resume-upload-file small {
    display: inline-block;
    word-break: break-all;
  }

  .contact-document-upload-placeholder {
    margin-top: 60px;

    span {
      vertical-align: middle;
    }
  }
}

/**
* Active styles for the dropzone
*/
.contact-file-uploader-dropzone-active {
  background: variables.$light-blue;
  cursor: grabbing;
}

/**
 * Top bar on contact form
 * FIXME: Move this to it's own file
 */
.contact-status-header {
  align-items: center;
  background-color: variables.$white;
  display: flex;
  margin-bottom: 15px;

  padding: 10px 15px;

  @media only screen and (max-width: $screen-md) {
    flex-direction: column;
  }

  .contact-status-header-col {
    flex: 1;
    text-align: center;

    &:first-child {
      text-align: left;
    }

    &:last-child {
      text-align: right;
    }

    .btn-group .btn {
      border-radius: variables.$input-border-radius;
    }

    @media only screen and (max-width: $screen-md) {
      padding: 10px 0;
      text-align: center;
      width: 100%;

      &:first-child,
      &:last-child {
        text-align: center;
      }
    }
  }

  strong + span {
    color: variables.$gray-40;
    margin-left: 5px;
  }
}

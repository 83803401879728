@use '@thrivetrm/ui/stylesheets/variables';

.CandidacyListItemSectionAssessments {
  background-color: variables.$gray-5;
  border-left: 1px solid variables.$gray-20;
  cursor: pointer;
  display: flex;
  flex: 0 0 20rem;
  overflow-y: auto;
  padding-left: variables.$space-8;

  &__view {
    background-color: transparent;
    border: none;
    display: flex;
    flex-direction: column;
    font-size: variables.$font-size-heading-xxsmall;
    padding: 0;
    text-align: left;
    width: 100%;
  }

  &__viewFullButton {
    text-decoration: none;
  }
}

.CandidacyListItemSectionDetailsTertiary {
  overflow: hidden;

  .ContactIconLinkList,
  .CandidacyStageDropdown {
    justify-content: flex-end;
    margin-bottom: 0;
    text-align: right;
  }
}

@use '@thrivetrm/ui/stylesheets/variables';

.ContactEmailSelectOption > .ContactEmailSelectOption__label {
  // Add some padding between the email address and the label.
  padding-left: 0.5em;
}

// Show the label of preferred email addresses in bold with an asterisk to the right
.ContactEmailSelectOption--is-preferred > .ContactEmailSelectOption__label {
  font-weight: variables.$font-weight-bold;

  &:after {
    color: variables.$brand-primary;
    content: '*';
    font-weight: variables.$font-weight-bold;
  }
}

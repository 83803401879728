@use '@thrivetrm/ui/stylesheets/variables';

$search-sidebar-header-height: 80px;

/**
 * The header area has a white background and a fixed-height and is always
 * displayed at the top of the sidebar, regardless of the content.
 */
.SearchPageSidebar .SearchPageSidebarHeader {
  background-color: variables.$white;
  box-shadow: 0 1px 5px 0px rgba(variables.$black-shadow, 0.1),
    1px 0 5px 0px rgba(variables.$black-shadow, 0.1);
  height: $search-sidebar-header-height;
}

/**
 * The main content, under the header. Fill the entire rest of the container
 * and allow it to scroll independently.
 */
.SearchPageSidebar__content {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: $search-sidebar-header-height;
}

.SearchPageSidebar .SidebarExpandToggle {
  background: initial;
  border: none;
  color: variables.$gray-40;
  font-size: 1.5rem;
  line-height: 1;
  outline: 0;
}

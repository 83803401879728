@use '@thrivetrm/ui/stylesheets/variables';

.CandidacyListItemV2 {
  align-items: stretch;
  border: 1px solid variables.$gray-20;
  display: flex;
  min-height: 142px;
}

.CandidacyListItemV2__core {
  // The "core" data of the "card". We want this to take up all the space unless the
  // assessment is displayed, in which case it can shrink ("flex-shrink=1")
  flex: 0 1 100%;
  overflow: hidden;
}

.CandidacyListItemV2__assessment {
  border-left: 1px solid variables.$gray-20;

  // This controls the size of the assessment add-on.
  flex: 0 0 20em;
  overflow: hidden;
}

.CandidacyListItemV2__action_buttons {
  // This controls the size of the action buttons add-on.
  flex: 0 0 3em;
  overflow: hidden;
}

@use '@thrivetrm/ui/stylesheets/variables';

.user-account-section {
  padding-bottom: 20px;
  padding-top: 10px;

  p {
    margin-bottom: 20px;
  }

  .btn-social {
    margin-top: 20px;
  }

  .integration-image {
    border: 1px solid variables.$gray-30;
  }

  .revoke {
    border-radius: 0;
    color: variables.$gray-40;
    font-size: variables.$font-size-small;
    font-weight: variables.$font-weight-normal;
    margin-left: 5px;
    padding: 0;
    padding-left: 5px;

    &:hover,
    &:active,
    &:focus {
      color: variables.$brand-danger;
    }
  }

  &.notification-preferences {
    .notice {
      color: variables.$brand-success;
      padding-bottom: 10px;
    }
    .checkbox {
      margin-top: 10px;
    }
  }

  .contact-avatar {
    display: block;
  }

  .user-avatar-uneditable {
    padding-right: 1em;
    i.icon.icon-person-filled {
      font-size: 80px;
    }
  }

  .user-avatar-editable {
    img {
      height: 80px;
      width: 80px;
    }
  }

  .user-login-info {
    .help-block {
      font-size: 0.8em;
    }
  }
}

// Make the input look like a list group
.user-account-bcc-email.form-control[readonly] {
  background-color: $list-group-bg;
  border-color: $list-group-border;
  border-radius: 3px;
  box-shadow: none;
  color: variables.$text-color;
  padding: 20px;
}

.user-disabled-shadow {
  color: variables.$gray-20;
  a {
    color: variables.$gray-40;
  }
}

.page-entries {
  float: right;
  position: relative;
  top: 3px;
}

.user-search.panel {
  background-color: transparent;
  box-shadow: none;
}

.disabled-users-checkbox {
  background-color: transparent;
  box-shadow: none;
  padding: 4px 14px 10px;
}

#invited-message {
  color: variables.$gray-40;
  font-size: 0.8em;
  margin-bottom: 0.6em;
}

.reinvite-link {
  font-size: 0.8em;
}

#account-info {
  padding-top: 2em;
}

.confidential-searches-description {
  font-size: 0.8em;
  margin-left: 1.75em;
  margin-top: -1em;
}

.invite-text {
  font-size: 10px;
}

.copied-message {
  color: variables.$green;
}

@use '@thrivetrm/ui/stylesheets/variables';

.flash-success {
  height: 20px;
}

.flash-success-span {
  color: variables.$green;
  padding-left: 1em;
}

.flash-enter {
  opacity: 0;
}

.flash-enter-active {
  opacity: 1;
  transition: 300ms ease-in all;
}

.flash-exit {
  opacity: 1;
}

.flash-exit-active {
  opacity: 0;
  transition: 1000ms ease-out all;
}

@use '@thrivetrm/ui/stylesheets/variables';

.rich-text-form {
  background-color: variables.$white;
  box-shadow: 1px 1px 5px 1px rgba(variables.$black-shadow, 0.3);
  margin-bottom: 1em;
  padding: 1em;

  > h3 {
    color: variables.$text-color;
    margin-top: 0;
  }

  .form-actions {
    margin-bottom: initial;
    margin-top: 1em;
  }

  .rich-text-area .public-DraftEditor-content {
    min-height: 12em;
  }
}

.rich-text-area {
  background-color: variables.$white;
  // Creates a border around both the textarea and its buttons (and removes
  // it from the textarea) so the toolbar appears to be floating inside.
  border: 1px solid variables.$input-border-color;
  border-radius: variables.$input-border-radius;
  display: flex;
  flex-direction: column;
  .DraftEditor-root {
    border: none;
  }

  // Style the toolbar in the lower right corner of the "textarea"
  .btn-toolbar {
    align-self: flex-end;
    margin-bottom: variables.$space-8;
    margin-left: inherit;

    .btn-link {
      // Brute force override the border radius for these rich text editor toolbar icons.
      // This is gross, but less risky than altering the bowl of spaghetti that is the global styles.
      border-radius: variables.$border-radius-small !important;

      &:hover {
        background-color: variables.$gray-5;
      }

      &.active {
        background-color: variables.$gray-10;
        // There are no better classes to hook into, so unfortunately must reference the icon class
        .fa {
          font-weight: variables.$font-weight-bold;
        }
      }

      &[disabled] {
        pointer-events: none;
      }
    }
  }
}

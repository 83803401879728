@use '@thrivetrm/ui/stylesheets/variables';

.DirectReportsManagementModal {
  .DirectReportsList {
    border: 1px solid variables.$gray-50;
    max-height: 30vh;
    overflow: scroll;

    .list-group-item {
      border-radius: 0;
      padding: 0.5em;
    }
  }
}

@use '@thrivetrm/ui/stylesheets/variables';

.editor {
  border: 1px solid variables.$gray-20;
  height: 200px;
  padding: 12px 12px;
  width: 100%;
}

#toolbar {
  float: right;
  margin-right: 0;
}

.form-group.wysiwyg label {
  padding-top: 18px;
}

#toolbar:after {
  clear: both;
}

@use '@thrivetrm/ui/stylesheets/variables';

.CandidaciesSearchPage__filter {
  display: flex;
  flex-wrap: nowrap;
}

.CandidaciesSearchPage__filter .CandidacyFilterForm {
  flex: 1;
}
.CandidacyFilterForm {
  .input-group-btn > .btn,
  .CandidacyTagSelectDropdown__toggle {
    padding: variables.$space-4 variables.$space-16;
  }

  // Override default text input and button height to fit legacy view
  .form-control,
  .btn,
  .btn-group {
    height: variables.$input-height-base;
  }
}
.CandidaciesSearchPage__filter .CandidacyListModeSelect {
  margin-left: 1em;
}

.report-card {
  max-width: 200px;

  .report-card-logo {
    height: 80px;
    padding-top: 30px;

    i {
      &[data-icon] {
        bottom: 14px;
        font-size: 63px;
        position: relative;
      }
    }
  }
}

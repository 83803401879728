@use '@thrivetrm/ui/stylesheets/variables';

.ContactProfilePanel {
  &__viewProfile {
    // used important to override blue color as view profile has <a> tag
    color: variables.$brand-dark-purple !important;
  }
  &__sidePanelBody {
    overflow: hidden;
  }
  &__panelIcon {
    background-color: variables.$gray-10;
    border-radius: 50%;
    height: variables.$space-32;
    width: variables.$space-32;
  }
  &__panelHeading {
    font-size: 20px; // This size doesn't exist but is halfway between small & large
  }
  &__activityIcon {
    color: variables.$gray-40;
    font-size: variables.$space-24;
  }
  &__reportingRelationshipCount {
    background-color: variables.$brand-dark-purple;
    border-radius: variables.$space-12;
    color: variables.$white;
    font-size: variables.$font-size-small;
    padding: variables.$space-4 variables.$space-12;
  }
}

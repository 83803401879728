@use '@thrivetrm/ui/stylesheets/variables';

.ImageUploadField__uploader-dropzone-active {
  background: variables.$light-blue;
  cursor: grabbing;
}

.ImageUploadField__uploader-dropzone {
  border: 1px dashed variables.$gray-20;
  cursor: pointer;
  height: 200px;
  padding: 2rem;
  text-align: center;
}

.ImageUploadField {
  margin-bottom: 1em;

  .CompanyLogo--thumbnail {
    max-height: 80px;
  }
  .fa-building {
    font-size: 6rem;
  }
}

.ImageUploadField__text {
  height: 2.5em;
}

.ImageUploadField__DefaultEmptyComponent,
.ImageUploadField__DefaultImageComponent {
  max-height: 100%;
  max-width: 100%;
}

@use '@thrivetrm/ui/stylesheets/variables';

.search-summary {
  .react-select .Select-value {
    font-size: 1.1em;
  }
}

.candidacy-summary {
  padding: 20px 0;
}

.chart-sidebar .chart-details {
  padding-top: 20px;
}

.chart-container .view-toggle {
  color: variables.$link-color;
  cursor: pointer;
  display: block;
  font-size: 0.7em;
  font-weight: variables.$font-weight-bold;
  margin-top: 10px;
  text-align: center;
  text-transform: uppercase;
  width: 180px;
}

.chart-details {
  .btn {
    width: 100%;
  }

  h3 {
    color: variables.$gray-60;
    font-size: 0.9em;
    font-weight: variables.$font-weight-bold;
    line-height: 1.3em;
    padding: 1.4em 0 0.2em;
  }

  ul {
    margin-left: 0;
    padding-left: 0;

    li {
      display: flex;
      font-size: 0.9em;
      font-weight: variables.$font-weight-normal;
      line-height: 1.8em;

      .term {
        flex: 1 1 auto;
        overflow: hidden;
        padding-right: 4px;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .term::after {
        content: '........................................................................................';
      }

      .line {
        flex: 1 1 auto;
        overflow: hidden;
        text-align: center;
      }

      .definition {
        flex: none;
        font-weight: variables.$font-weight-bold;
      }
    }
  }

  .popover-reasons-for-rejection {
    left: -50px;
    top: 100%;
  }

  .search-summary-report {
    color: variables.$text-color;
    display: block;
    font-weight: variables.$font-weight-bold;
    margin-bottom: 20px;
    text-align: right;

    i {
      color: variables.$red;
    }
  }
}

// Rejection Reason Popover
// ------------------------------

.rejected-details {
  position: relative;

  a {
    cursor: pointer;
  }
}

.popover-reasons-for-rejection {
  left: -125px;
  top: 14px;
  width: 300px;

  ul li {
    display: list-item;
    margin-left: 18px;
  }
}

@use '@thrivetrm/ui/stylesheets/variables';

.Tab {
  &__tabs {
    display: inline-flex;
  }

  &__button {
    align-items: center;
    background: none;
    border: none;
    border-bottom: variables.$space-2 solid transparent;
    border-radius: variables.$border-radius-base;
    cursor: default;
    display: inline-flex;
    flex-grow: 1;
    font-size: variables.$font-size-base;
    font-weight: variables.$font-weight-normal;
    justify-content: center;
    line-height: normal;
    outline: none;
    padding: variables.$space-24 variables.$space-16;
    transition: all 200ms variables.$ease-in-quad;

    &:hover:not(.Tab__button--isSelected) {
      cursor: pointer;
    }
    &:focus:not(.Tab__button--isSelected) {
      background-color: variables.$gray-20;
    }

    &--isSelected {
      border-bottom-color: variables.$brand-blue;
      border-radius: 0px;
    }
  }

  &__buttonLabel {
    align-items: center;
    color: variables.$gray-60;

    &--isSelected {
      color: variables.$brand-blue;
    }
  }

  &__buttonBadge {
    background-color: variables.$gray-10;
    border-radius: variables.$space-8;
    color: variables.$gray-60;
    font-size: 11px;
    height: variables.$space-16;
    line-height: variables.$space-16;
    margin-left: variables.$space-8;
    width: variables.$space-24;

    &--isSelected {
      background-color: variables.$brand-blue;
      color: variables.$white;
    }
  }

  &__buttonIcon {
    color: variables.$gray-60;
    margin-right: variables.$space-8;
  }
  &__buttonIcon--isSelected {
    color: variables.$brand-blue;
  }
}

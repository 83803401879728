@use '@thrivetrm/ui/stylesheets/variables';

.OffLimitsStatus {
  align-items: center;
  border: none;
  border-radius: 10px;
  display: inline-flex;
  font-size: 11px;
  font-weight: variables.$font-weight-bold;
  height: 20px;
  // Compensate for the optical illusion caused by the light background rounded
  // corner plus high-contrast inset icon
  margin-left: -2px;
  padding: 0;
  padding-right: variables.$space-8;

  &:focus {
    outline: none;
  }

  &__indicator {
    margin-left: variables.$space-4;
    margin-right: variables.$space-4;
  }

  &--add {
    background-color: variables.$gray-20;
    color: variables.$gray-60;

    &.OffLimitsStatus--isHoverable:hover {
      background-color: darken(variables.$gray-20, 5%);
    }
  }

  &--warning {
    background-color: lighten(variables.$brand-yellow, 25%);
    color: variables.$gray-60;

    &.OffLimitsStatus--isHoverable:hover {
      background-color: lighten(variables.$brand-yellow, 20%);
    }
  }

  &--danger {
    background-color: lighten(variables.$brand-red, 42%);
    color: variables.$brand-red;

    &.OffLimitsStatus--isHoverable:hover {
      background-color: lighten(variables.$brand-red, 38%);
    }
  }

  &--info {
    background-color: lighten(variables.$brand-blue, 37%);
    color: variables.$gray-60;

    &.OffLimitsStatus--isHoverable:hover {
      background-color: lighten(variables.$brand-blue, 32%);
    }
  }
}

@use '@thrivetrm/ui/stylesheets/variables';

.education-fields,
.position-dates {
  .date.form-control {
    margin-right: 15px;
    width: 45%;
  }

  .boolean {
    padding-left: 12px;
    padding-top: 6px;
  }

  select:last-child {
    display: none;
  }
}

.education-fields {
  .row {
    margin-top: 10px;
  }
}

.to-date {
  margin-top: 5px;
}

#email-already-taken-error {
  display: none;
}

.document-fields .fields {
  border: 0;
  border-top: 0;
}

.name-duplicate-warning {
  width: 100%;
}

.dup-found {
  margin-bottom: 10px;
}

.contact-duplicates {
  .alert-info {
    background-color: variables.$gray-5;
    border-color: transparent;
    border-radius: 0;
    color: variables.$gray-50;
  }

  .alert-danger {
    background-color: #f2dede;
    border-color: transparent;
    border-radius: 0;
    color: variables.$gray-50;
  }
}

.name-duplicate-warning,
.email-duplicate-error,
.work-email-duplicate-error {
  .popover.bottom {
    margin: 0;
  }

  .popover {
    right: 0;
  }

  position: relative;
}

.duplicate-error input {
  border: 2px solid variables.$brand-danger;
}

.duplicate-warning input {
  border: 2px solid variables.$brand-warning;
}

.duplicate-modal {
  &.backdrop {
    background-color: rgba(variables.$black-shadow, 0.5);
  }

  .modal-body {
    max-height: 420px;
    overflow-y: auto;
  }
}

.duplicate-contacts.table {
  .duplicate-radio {
    padding-left: 0;
    width: 5%;
  }

  .duplicate-profile-link {
    padding-right: 0;
    text-align: right;
  }
}

.popover.contact-popover {
  z-index: auto;
}

.btn-primary.cancel-button {
  background-color: #337ab7;
  border-color: #2e6da4;
}

.contact-form-group-resume:first-child {
  margin-top: 20px;
}

.contact-form {
  .document_wrapper {
    margin-top: 25px;

    .contact-document {
      margin-top: 8px;
    }
  }
}

.resume-update-buttons {
  margin-top: 10px;
}

// This corrects the contact resume buttons
// which have modals between them, blocking
// sibiling btn styles
.contacts-resume-upload-actions .btn {
  margin-right: variables.$space-12;
}
.contacts-resume-upload-actions .btn-primary {
  margin-right: 10px;
}

.panel-title.no-top-margin {
  margin: 0;
}

.contact-form-add-to-search {
  .chosen-container .chosen-single {
    background: variables.$white;
    border-color: #dddddd;
    box-shadow: none;
  }
}

.experience-buttons {
  margin-bottom: 10px;
}

i.date-sort-icon {
  margin-left: 3px;
}

.SpecialNeedsCandidate {
  margin-bottom: 2.5em;
}

.ContactSpecialNeeds {
  margin-bottom: 1.5em;
}

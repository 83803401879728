.timestamp {
  @include small-text;

  .edit a {
    margin: 0px 8px;
  }

  .delete,
  .edit {
    font-style: normal;
    opacity: 0.2;
    transition: opacity 300ms;
  }

  &:hover .delete,
  &:hover .edit {
    opacity: 0.9;
  }
}

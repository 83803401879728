@use '@thrivetrm/ui/stylesheets/variables';

.AddCommentButtonLabel {
  color: variables.$brand-secondary;
  padding-right: em(10px);
  i {
    padding-right: em(5px);
  }
  &:hover {
    color: variables.$link-hover-color;
    text-decoration: none;
  }
}
